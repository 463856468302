import { css } from "@emotion/css";
import { capitalize } from "@mui/material";
import { addMinutes, differenceInMinutes, parseISO } from "date-fns";
import { isEmpty, partition } from "lodash";
import { isNewGfeStatus } from "@/components/serviceFlow/reviewVisits/filters/gfeStatusFilter/gfeStatusDialog";
import { EventColorPalette } from "@/config";
import { ERROR, GREEN, WHITE } from "@/config/mui/colorPalette";
import { ClientFormsQuery } from "@/graphql/queries/client/clientForms.graphql.types";
import { ServiceMenuItemsBasicInfoQuery } from "@/graphql/queries/serviceMenuItems.graphql.types";
import { VisitGfeTabQuery } from "@/graphql/queries/visit/gfeTab.graphql.types";
import { VisitsQueryResult } from "@/graphql/queries/visit/visits.graphql.types";
import { VisitsAndAvailabilityEventsWithDateRangeQuery } from "@/graphql/queries/visit/visitsAndAvailabilityEventsWithDateRange.graphql.types";
import { VisitSummarySubscription } from "@/graphql/subscriptions/visit/visitSummary.graphql.types";
import { ScheduleListEventType } from "@/hooks/visits/useShapedListEvents";
import { GfeStatusType } from "@/store/views/serviceFlow/reviewVisits/filters/gfeStatusFilterStore";
import {
  ALL_GFE_OPTIONS_OLD,
  NEW_GFE_OPTIONS,
  OLD_GFE_OPTIONS,
} from "@/store/views/serviceFlow/visits/filters/visitsGfeStatusFilterStore";
import { VisitStatus } from "@/types";
import { GfeStatus, ServiceMenuItemGfeStatus } from "@/types/gfe";

export const getDurationFromServices = (
  serviceIds: string[],
  availableServices: ServiceMenuItemsBasicInfoQuery["medspaServiceMenuItem"]
): number => {
  return availableServices.reduce((acc, availableService) => {
    return serviceIds.includes(availableService.id)
      ? acc + availableService.durationInMinutes
      : acc;
  }, 0);
};

export const getDurationFromAppointment = (
  appointment: VisitsQueryResult["data"]["visit"][number]["appointment"]
): number => {
  return differenceInMinutes(
    new Date(appointment.startTime),
    new Date(appointment.endTime)
  );
};

export const getServicesInfo = (
  serviceMenuItemLines: VisitsQueryResult["data"]["visit"][0]["serviceMenuItemLines"]
): string => {
  if (!serviceMenuItemLines.length) return "";
  return serviceMenuItemLines.length > 1
    ? `${serviceMenuItemLines.length} services • `
    : `${serviceMenuItemLines[0].serviceMenuItem.name} • `;
};

export const getEndTime = (
  visit: VisitsQueryResult["data"]["visit"][0],
  availableServices: ServiceMenuItemsBasicInfoQuery
): string => {
  return addMinutes(
    parseISO(visit.startTime),
    availableServices
      ? getDurationFromServices(
          visit.serviceMenuItemLines.map((line) => line.serviceMenuItem.id),
          availableServices.medspaServiceMenuItem
        )
      : 0
  ).toISOString();
};

export const getDateString = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

export type GfeForm =
  | ClientFormsQuery["clientByPk"]["formSubmissions"][number]
  | VisitSummarySubscription["visitByPk"]["client"]["formSubmissions"][number]
  | VisitGfeTabQuery["visitByPk"]["client"]["formSubmissions"][number];

export const filterByAllowedStatuses = (
  formSubmissions: GfeForm[] = [],
  allowedStatuses: GfeStatus[]
) => {
  return formSubmissions.filter(({ gfeStatus }) =>
    allowedStatuses.includes(gfeStatus as GfeStatus)
  );
};

export const FILLED_GFE_FORMS = [
  GfeStatus.CLIENT_COMPLETED,
  GfeStatus.WAITING_FOR_GFE,
];

// TODO: Get through all gfe functions and possibly remove most of them when newGfeFlowV1 is enabled for all
export const getGfeToApprove = (formSubmissions: GfeForm[]) => {
  const allowedStatuses = [...FILLED_GFE_FORMS, GfeStatus.GFE_DECLINED];

  return filterByAllowedStatuses(formSubmissions, allowedStatuses);
};

export const getGfeToDecline = (formSubmissions: GfeForm[]) => {
  const allowedStatuses = [...FILLED_GFE_FORMS, GfeStatus.GFE_APPROVED];

  return filterByAllowedStatuses(formSubmissions, allowedStatuses);
};

export const getGfeToReview = (
  formSubmissions: GfeForm[],
  isReReview?: boolean
) => {
  const allowedStatuses = isReReview
    ? [
        GfeStatus.CLIENT_COMPLETED,
        GfeStatus.GFE_DECLINED,
        GfeStatus.GFE_APPROVED,
      ]
    : [GfeStatus.CLIENT_COMPLETED];

  return filterByAllowedStatuses(formSubmissions, allowedStatuses);
};

export const hasDeclinedGfe = (
  formSubmissions?: Pick<GfeForm, "gfeStatus">[]
) => {
  if (!formSubmissions) return false;

  return formSubmissions.some(
    ({ gfeStatus }) => gfeStatus === GfeStatus.GFE_DECLINED
  );
};

const getFilteredFormGfeStatuses = (formSubmissions: GfeForm[]) =>
  formSubmissions.map(({ gfeStatus }) => gfeStatus).filter(Boolean);

export const hasSomeGfesFilled = (formSubmissions: GfeForm[]) => {
  const statuses = getFilteredFormGfeStatuses(formSubmissions);

  return (
    statuses.length === 0 ||
    statuses.some((status) => status !== GfeStatus.INCOMPLETE)
  );
};

export const hasSomeGfesApproved = (formSubmissions: GfeForm[]) => {
  const statuses = getFilteredFormGfeStatuses(formSubmissions);

  return statuses.length === 0 || statuses.includes(GfeStatus.GFE_APPROVED);
};

export const getVisitStatusLabel = (status: VisitStatus) => {
  if (status === VisitStatus.CANCELLED_LATE) {
    return "Cancelled (late notice)";
  }

  return capitalize(status.replace("_", " "));
};

export const getSortedIntakeForms = (forms: GfeForm[]) => {
  return [...forms].sort((a, b) => Number(b.id) - Number(a.id));
};

type ServiceMenuItemLines =
  VisitsAndAvailabilityEventsWithDateRangeQuery["visit"][number]["serviceMenuItemLines"];

export const getGeneralGfeVisitStatus = (
  serviceMenuItemLines: Pick<ServiceMenuItemLines[number], "gfeStatus">[]
): ServiceMenuItemGfeStatus => {
  const isAny = (status: ServiceMenuItemGfeStatus) =>
    serviceMenuItemLines.some((line) => line.gfeStatus === status);

  switch (true) {
    case isAny(ServiceMenuItemGfeStatus.CONTRAINDICATED):
      return ServiceMenuItemGfeStatus.CONTRAINDICATED;
    case isAny(ServiceMenuItemGfeStatus.NOT_INDICATED):
      return ServiceMenuItemGfeStatus.NOT_INDICATED;
    case isAny(ServiceMenuItemGfeStatus.PENDING_REVIEWER):
      return ServiceMenuItemGfeStatus.PENDING_REVIEWER;
    case isAny(ServiceMenuItemGfeStatus.FORMS_INCOMPLETE):
      return ServiceMenuItemGfeStatus.FORMS_INCOMPLETE;
    case isAny(ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS):
      return ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS;
    case isAny(ServiceMenuItemGfeStatus.INDICATED):
      return ServiceMenuItemGfeStatus.INDICATED;
    default: {
      return ServiceMenuItemGfeStatus.NOT_NEEDED;
    }
  }
};

// TODO: Remove when newGfeFlowV1 is enabled for all
export const getGeneralGfeVisitStatusOld = (
  formSubmissions: GfeForm[]
): GfeStatus => {
  const isAny = (status: GfeStatus) =>
    formSubmissions.some((form) => form.gfeStatus === status);

  switch (true) {
    case isAny(GfeStatus.INCOMPLETE):
      return GfeStatus.INCOMPLETE;
    case isAny(GfeStatus.CLIENT_COMPLETED):
      return GfeStatus.CLIENT_COMPLETED;
    case isAny(GfeStatus.WAITING_FOR_GFE):
      return GfeStatus.WAITING_FOR_GFE;
    case isAny(GfeStatus.GFE_DECLINED):
      return GfeStatus.GFE_DECLINED;
    case isAny(GfeStatus.GFE_APPROVED):
      return GfeStatus.GFE_APPROVED;
    default: {
      return GfeStatus.NOT_NEEDED;
    }
  }
};

// The function is used temporarily to handle the gap period between GFE v1 and GFE v2.
export const getGeneralGfeVisitStatusTemp = (
  client: VisitsAndAvailabilityEventsWithDateRangeQuery["visit"][number]["client"]
): GfeStatus => {
  const formSubmissions = client.formSubmissions as GfeForm[];
  const hasPendingReview = client.pendingGfeReview?.length > 0;

  const isAny = (status: GfeStatus) =>
    formSubmissions.some((form) => form.gfeStatus === status);

  switch (true) {
    case isAny(GfeStatus.INCOMPLETE):
      return GfeStatus.INCOMPLETE;
    case hasPendingReview:
      return GfeStatus.WAITING_FOR_GFE;
    default: {
      return GfeStatus.NOT_NEEDED;
    }
  }
};

const GFE_STATUS_LEVEL = {
  [GfeStatus.INCOMPLETE]: 0,
  [GfeStatus.CLIENT_COMPLETED]: 1,
  [GfeStatus.WAITING_FOR_GFE]: 2,
  [GfeStatus.GFE_DECLINED]: 3,
  [GfeStatus.GFE_APPROVED]: 3,
};

const isOnlyApproved = (statuses: GfeStatus[]) =>
  statuses.length === 1 && statuses[0] === GfeStatus.GFE_APPROVED;

const isOnlyDeclined = (statuses: GfeStatus[]) =>
  statuses.length === 1 && statuses[0] === GfeStatus.GFE_DECLINED;

const getStatusesToExclude = (statuses: GfeStatus[]) => {
  const toExclude = [
    GfeStatus.INCOMPLETE,
    GfeStatus.CLIENT_COMPLETED,
    GfeStatus.WAITING_FOR_GFE,
  ];

  if (isOnlyDeclined(statuses)) {
    return [];
  }

  if (isOnlyApproved(statuses)) {
    return [...toExclude, GfeStatus.GFE_DECLINED];
  }

  const sorted = [...statuses].sort(
    (a, b) => GFE_STATUS_LEVEL[a] - GFE_STATUS_LEVEL[b]
  );
  const lowestStatus = sorted[0];

  return toExclude.slice(0, GFE_STATUS_LEVEL[lowestStatus]);
};

const getStatusesToExcludeNew = (statuses: GfeStatus[]) => {
  const toExclude = [
    GfeStatus.INCOMPLETE,
    GfeStatus.CLIENT_COMPLETED,
    GfeStatus.WAITING_FOR_GFE,
  ];

  if (isEmpty(statuses)) {
    return [GfeStatus.GFE_APPROVED, GfeStatus.GFE_DECLINED];
  }

  if (isOnlyDeclined(statuses)) {
    return [];
  }

  if (isOnlyApproved(statuses)) {
    return [...toExclude, GfeStatus.GFE_DECLINED];
  }
};

const getGfeStatusFilterExpNew = (statuses: GfeStatusType[]) => {
  const [newGfeOptions, oldGfeOptions] = partition(statuses, isNewGfeStatus);

  const allOldChecked = oldGfeOptions.length === OLD_GFE_OPTIONS.length;
  const allNewChecked = newGfeOptions.length === NEW_GFE_OPTIONS.length;

  if (allOldChecked && allNewChecked) return {};

  const newGfeExp = {
    serviceMenuItemLines: { gfeStatus: { _in: newGfeOptions } },
  };

  if (allOldChecked) return newGfeExp;

  const oldGfesToGet = isOnlyApproved(oldGfeOptions)
    ? ["", ...oldGfeOptions]
    : oldGfeOptions;

  const oldGfeExp = {
    _and: [
      { formSubmissions: { gfeStatus: { _in: oldGfesToGet } } },
      {
        _not: {
          formSubmissions: {
            gfeStatus: { _in: getStatusesToExcludeNew(oldGfeOptions) },
          },
        },
      },
    ],
  };

  return {
    _or: [
      {
        client: oldGfeExp,
      },
      newGfeExp,
    ],
  };
};

// TODO: Adjust when removing the filter that supports old and new gfe flow (we won't need old statuses logic anymore)
export const getGfeStatusFilterExp = (
  statuses: GfeStatusType[],
  newGfeFlowV1: boolean,
  newGfeFlowV2: boolean
) => {
  if (newGfeFlowV2) {
    return getGfeStatusFilterExpNew(statuses);
  }

  const getAll = statuses.length === ALL_GFE_OPTIONS_OLD.length;

  if (getAll) return {};

  const gfesToGet = isOnlyApproved(statuses as GfeStatus[])
    ? ["", ...statuses]
    : statuses;

  const isPendingReviewerSelected = statuses.includes(
    GfeStatus.WAITING_FOR_GFE
  );

  if (newGfeFlowV1)
    return {
      client: {
        _or: [
          {
            _and: [
              { formSubmissions: { gfeStatus: { _in: gfesToGet } } },
              {
                _not: {
                  formSubmissions: {
                    gfeStatus: {
                      _in: getStatusesToExclude(statuses as GfeStatus[]),
                    },
                  },
                },
              },
            ],
          },
          isPendingReviewerSelected
            ? { pendingGfeReview: {} }
            : { _not: { pendingGfeReview: {} } },
        ],
      },
    };

  return {
    client: {
      _and: [
        { formSubmissions: { gfeStatus: { _in: gfesToGet } } },
        {
          _not: {
            formSubmissions: {
              gfeStatus: { _in: getStatusesToExclude(statuses as GfeStatus[]) },
            },
          },
        },
      ],
    },
  };
};

export const getServiceDevicesFilterExp = (serviceDeviceIds: string[]) => {
  if (isEmpty(serviceDeviceIds)) return {};

  return {
    appointment: {
      serviceDevicesAppointments: {
        serviceDevice: {
          id: { _in: serviceDeviceIds },
        },
      },
    },
  };
};

export const clientHasWalletItems = (
  client?: Pick<ScheduleListEventType["client"], "hasItemsInWallet">
) => {
  if (!client) return false;

  const walletSum = client.hasItemsInWallet?.aggregate?.sum?.amount ?? "0";

  return Number(walletSum) > 0;
};

export const getClientMembershipsList = (
  client?: Pick<ScheduleListEventType["client"], "clientMemberships">
) => {
  if (!client) return "";

  if (!client.clientMemberships || client.clientMemberships.length === 0)
    return "";

  return client.clientMemberships
    .map((clientMembership) => clientMembership.membership.title)
    .join(", ");
};

export const getEventColorsByState = (
  status: VisitStatus,
  eventColorPalette?: EventColorPalette
) => {
  const colorObjectByIndex = eventColorPalette ?? GREEN;

  const isCancelledOrNoShow =
    status === VisitStatus.CANCELLED ||
    status === VisitStatus.CANCELLED_LATE ||
    status === VisitStatus.NO_SHOW;

  let stripedCancelledEventClassName = undefined;
  let textStyleClassName = undefined;
  if (isCancelledOrNoShow) {
    stripedCancelledEventClassName = css`
      background: repeating-linear-gradient(
        -45deg,
        ${colorObjectByIndex[20]} 0 8px,
        ${colorObjectByIndex[40]} 8px 16px
      );
    `;

    textStyleClassName = css`
      color: ${colorObjectByIndex[80]};
      text-decoration: line-through !important;
    `;
  }

  const textColorsByStatus = {
    [VisitStatus.SCHEDULED]: colorObjectByIndex[100],
    [VisitStatus.CONFIRMED]: colorObjectByIndex[100],
    [VisitStatus.COMPLETED]: colorObjectByIndex[20],
    [VisitStatus.CANCELLED]: textStyleClassName,
    [VisitStatus.CANCELLED_LATE]: textStyleClassName,
    [VisitStatus.NO_SHOW]: textStyleClassName,
  }[status];

  const backgroundColorsByStatus = {
    [VisitStatus.SCHEDULED]: WHITE,
    [VisitStatus.CONFIRMED]: colorObjectByIndex[40],
    [VisitStatus.COMPLETED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED]: stripedCancelledEventClassName,
    [VisitStatus.CANCELLED_LATE]: stripedCancelledEventClassName,
    [VisitStatus.NO_SHOW]: stripedCancelledEventClassName,
  }[status];

  const borderColorByStatus = {
    [VisitStatus.SCHEDULED]: colorObjectByIndex[80],
    [VisitStatus.CONFIRMED]: colorObjectByIndex[80],
    [VisitStatus.COMPLETED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED]: colorObjectByIndex[80],
    [VisitStatus.CANCELLED_LATE]: ERROR,
    [VisitStatus.NO_SHOW]: ERROR,
  }[status];

  return {
    borderColor: borderColorByStatus,
    textColor: !isCancelledOrNoShow ? textColorsByStatus : undefined,
    backgroundColor: !isCancelledOrNoShow
      ? backgroundColorsByStatus
      : undefined,
    classNames: isCancelledOrNoShow
      ? [backgroundColorsByStatus, textColorsByStatus]
      : undefined,
  };
};
