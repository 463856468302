import { useMedspaMedicalDirectorsQuery } from "@/graphql/queries/medspaMedicalDirectors.graphql.types";
import { useMedspaId } from "@/hooks/common/useMedspaId";

export const useMedspaMedicalDirectors = () => {
  const medspaId = useMedspaId();

  const { data: medicalDirectorData, error } = useMedspaMedicalDirectorsQuery({
    variables: {
      id: medspaId,
    },
    skip: !medspaId,
  });

  const loading = medicalDirectorData === undefined;

  const medspaMedicalDirectors =
    medicalDirectorData?.medspaByPk?.userMedspas ?? [];

  return {
    medspaMedicalDirectors,
    loading,
    error,
  };
};
