import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables =
  Types.Exact<{
    token: Types.Scalars["String"]["input"];
  }>;

export type MedspaFeatureFlagsByAppointmentAccessTokenQuery = {
  __typename?: "query_root";
  appointmentPublicInfo?: {
    __typename?: "AppointmentPublicInfoType";
    medspa?: {
      __typename?: "MedspaPublicInfoType";
      id: string;
      enabledFeatures: Array<string | null>;
    } | null;
  } | null;
};

export const MedspaFeatureFlagsByAppointmentAccessTokenDocument = gql`
  query MedspaFeatureFlagsByAppointmentAccessToken($token: String!) {
    appointmentPublicInfo(token: $token) {
      medspa {
        id
        enabledFeatures
      }
    }
  }
`;

/**
 * __useMedspaFeatureFlagsByAppointmentAccessTokenQuery__
 *
 * To run a query within a React component, call `useMedspaFeatureFlagsByAppointmentAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaFeatureFlagsByAppointmentAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaFeatureFlagsByAppointmentAccessTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMedspaFeatureFlagsByAppointmentAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >(MedspaFeatureFlagsByAppointmentAccessTokenDocument, options);
}
export function useMedspaFeatureFlagsByAppointmentAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >(MedspaFeatureFlagsByAppointmentAccessTokenDocument, options);
}
export function useMedspaFeatureFlagsByAppointmentAccessTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >(MedspaFeatureFlagsByAppointmentAccessTokenDocument, options);
}
export type MedspaFeatureFlagsByAppointmentAccessTokenQueryHookResult =
  ReturnType<typeof useMedspaFeatureFlagsByAppointmentAccessTokenQuery>;
export type MedspaFeatureFlagsByAppointmentAccessTokenLazyQueryHookResult =
  ReturnType<typeof useMedspaFeatureFlagsByAppointmentAccessTokenLazyQuery>;
export type MedspaFeatureFlagsByAppointmentAccessTokenSuspenseQueryHookResult =
  ReturnType<typeof useMedspaFeatureFlagsByAppointmentAccessTokenSuspenseQuery>;
export type MedspaFeatureFlagsByAppointmentAccessTokenQueryResult =
  Apollo.QueryResult<
    MedspaFeatureFlagsByAppointmentAccessTokenQuery,
    MedspaFeatureFlagsByAppointmentAccessTokenQueryVariables
  >;
