import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";

export const interactiveCalendarProps: CalendarOptions = {
  snapDuration: "00:05:00",
  eventResizableFromStart: true,
  plugins: [timeGridPlugin, interactionPlugin],
  editable: true,
};

export const interactiveResourceCalendarProps: CalendarOptions = {
  snapDuration: "00:05:00",
  eventResizableFromStart: true,
  plugins: [resourceTimeGridPlugin, interactionPlugin, dayGridPlugin],
  editable: true,
};

export const interactiveTimeFormatProps: CalendarOptions = {
  eventTimeFormat: {
    hour: "numeric",
    minute: "2-digit",
    meridiem: "short",
  },
};
