import { useUser } from "@/auth/useUser";
import { MEDICAL_DIRECTOR } from "@/types";

export const useIsGfeReviewer = () => {
  const { additionalUserDetails } = useUser();

  return additionalUserDetails?.userMedspa.find(
    (userMedspa) => userMedspa.role === MEDICAL_DIRECTOR
  )?.isGfeReviewer;
};
