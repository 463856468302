import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { GREY } from "@/config/mui/colorPalette";
import { MenuHorizontalIcon, WarningCircleIcon } from "../icons";
import MessagesNotificationsDot from "./messagesNotificationsDot";

type Props = {
  medspa: string;
  enableMedspaSwitch: boolean;
  enableMessages: boolean;
  onClickSwitchAccount: (switchAccount: boolean) => void;
};

const TopBarMobileMenu = ({
  medspa,
  enableMedspaSwitch,
  enableMessages,
  onClickSwitchAccount,
}: Props) => {
  // menu dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const open = Boolean(anchorEl);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const showDropdownMenu = enableMedspaSwitch || enableMessages;

  return (
    <>
      {!showDropdownMenu && (
        <IconButton
          onClick={() => {
            window.birdeatsbug.trigger();
          }}
        >
          <WarningCircleIcon color={GREY[70]} size="24" />
        </IconButton>
      )}

      {showDropdownMenu && (
        <IconButton
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            minWidth: "fit-content",
          }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <MenuHorizontalIcon color={GREY[70]} />
            {enableMessages && <MessagesNotificationsDot />}
          </Box>
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "12px",
              boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
            },
          },
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "account-dropdown",
        }}
      >
        {enableMedspaSwitch && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              onClickSwitchAccount(true);
            }}
          >
            Switch Account
          </MenuItem>
        )}
        {enableMessages && (
          <MenuItem>
            <Link href={`/${medspa}/messages`}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  gap: 1,
                }}
              >
                <Typography color="text.primary">Messages</Typography>
                {enableMessages && (
                  <MessagesNotificationsDot floating={false} />
                )}
              </Box>
            </Link>
          </MenuItem>
        )}

        {window?.birdeatsbug && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              window.birdeatsbug.trigger();
            }}
          >
            Report Bug
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default TopBarMobileMenu;
