import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type RefreshClientAccessTokenMutationVariables = Types.Exact<{
  clientAccessToken: Types.Scalars["String"]["input"];
  medspaSlug: Types.Scalars["String"]["input"];
}>;

export type RefreshClientAccessTokenMutation = {
  __typename?: "mutation_root";
  refreshClientAccessToken?: {
    __typename?: "RefreshClientAccessToken";
    clientAccessToken?: string | null;
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const RefreshClientAccessTokenDocument = gql`
  mutation RefreshClientAccessToken(
    $clientAccessToken: String!
    $medspaSlug: String!
  ) {
    refreshClientAccessToken(
      clientAccessToken: $clientAccessToken
      medspaSlug: $medspaSlug
    ) {
      clientAccessToken
      message
      ok
    }
  }
`;
export type RefreshClientAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshClientAccessTokenMutation,
  RefreshClientAccessTokenMutationVariables
>;

/**
 * __useRefreshClientAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshClientAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshClientAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshClientAccessTokenMutation, { data, loading, error }] = useRefreshClientAccessTokenMutation({
 *   variables: {
 *      clientAccessToken: // value for 'clientAccessToken'
 *      medspaSlug: // value for 'medspaSlug'
 *   },
 * });
 */
export function useRefreshClientAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshClientAccessTokenMutation,
    RefreshClientAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshClientAccessTokenMutation,
    RefreshClientAccessTokenMutationVariables
  >(RefreshClientAccessTokenDocument, options);
}
export type RefreshClientAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshClientAccessTokenMutation
>;
export type RefreshClientAccessTokenMutationResult =
  Apollo.MutationResult<RefreshClientAccessTokenMutation>;
export type RefreshClientAccessTokenMutationOptions =
  Apollo.BaseMutationOptions<
    RefreshClientAccessTokenMutation,
    RefreshClientAccessTokenMutationVariables
  >;
