import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ClientListFieldsFragment = {
  __typename?: "client";
  id: string;
  birthdate?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  twilioConversationId: string;
  medspaId: string;
  twilioOptedOutOfSms: boolean;
  note: string;
  twilioLastMessageTimestamp?: string | null;
  twilioLastMessage?: string | null;
  created: string;
  hadAdverseReaction: boolean;
  clientMemberships: Array<{
    __typename?: "ClientMembership";
    id: string;
    membership: { __typename?: "Membership"; id: string; title: string };
  }>;
};

export type ClientsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  orderBy: Array<Types.ClientOrderBy> | Types.ClientOrderBy;
  searchString?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type ClientsQuery = {
  __typename?: "query_root";
  client: Array<{
    __typename?: "client";
    id: string;
    birthdate?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    twilioConversationId: string;
    medspaId: string;
    twilioOptedOutOfSms: boolean;
    note: string;
    twilioLastMessageTimestamp?: string | null;
    twilioLastMessage?: string | null;
    created: string;
    hadAdverseReaction: boolean;
    clientMemberships: Array<{
      __typename?: "ClientMembership";
      id: string;
      membership: { __typename?: "Membership"; id: string; title: string };
    }>;
  }>;
};

export type RecentVisitClientsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type RecentVisitClientsQuery = {
  __typename?: "query_root";
  client: Array<{
    __typename?: "client";
    id: string;
    birthdate?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    twilioConversationId: string;
    medspaId: string;
    twilioOptedOutOfSms: boolean;
    note: string;
    twilioLastMessageTimestamp?: string | null;
    twilioLastMessage?: string | null;
    created: string;
    hadAdverseReaction: boolean;
    mostRecentVisit?: Array<{
      __typename?: "visit";
      id: string;
      startTime: string;
    }> | null;
    clientMemberships: Array<{
      __typename?: "ClientMembership";
      id: string;
      membership: { __typename?: "Membership"; id: string; title: string };
    }>;
  }>;
};

export type NoShowVisitClientsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["bigint"]["input"];
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type NoShowVisitClientsQuery = {
  __typename?: "query_root";
  client: Array<{
    __typename?: "client";
    id: string;
    birthdate?: string | null;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    twilioConversationId: string;
    medspaId: string;
    twilioOptedOutOfSms: boolean;
    note: string;
    twilioLastMessageTimestamp?: string | null;
    twilioLastMessage?: string | null;
    created: string;
    hadAdverseReaction: boolean;
    lastNoShowVisit?: Array<{
      __typename?: "visit";
      id: string;
      startTime: string;
    }> | null;
    clientMemberships: Array<{
      __typename?: "ClientMembership";
      id: string;
      membership: { __typename?: "Membership"; id: string; title: string };
    }>;
  }>;
};

export type ClientForVisitCreationQueryVariables = Types.Exact<{
  clientId: Types.Scalars["bigint"]["input"];
}>;

export type ClientForVisitCreationQuery = {
  __typename?: "query_root";
  clientByPk?: {
    __typename?: "client";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  } | null;
};

export const ClientListFieldsFragmentDoc = gql`
  fragment clientListFields on client {
    id
    birthdate
    email
    firstName
    lastName
    phone
    twilioConversationId
    medspaId
    twilioOptedOutOfSms
    note
    twilioLastMessageTimestamp
    twilioLastMessage
    created
    hadAdverseReaction
    clientMemberships(where: { status: { _neq: "canceled" } }) {
      id
      membership {
        id
        title
      }
    }
  }
`;
export const ClientsDocument = gql`
  query Clients(
    $medspaId: bigint!
    $orderBy: [clientOrderBy!]!
    $searchString: String = "%%"
    $limit: Int
    $offset: Int = 0
  ) {
    client(
      where: {
        _or: [
          { fullName: { _ilike: $searchString } }
          { email: { _ilike: $searchString } }
          { phone: { _ilike: $searchString } }
        ]
        medspaId: { _eq: $medspaId }
        active: { _eq: true }
      }
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      ...clientListFields
    }
  }
  ${ClientListFieldsFragmentDoc}
`;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      orderBy: // value for 'orderBy'
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useClientsQuery(
  baseOptions: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(
    ClientsDocument,
    options
  );
}
export function useClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(
    ClientsDocument,
    options
  );
}
export function useClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClientsQuery,
    ClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ClientsQuery, ClientsQueryVariables>(
    ClientsDocument,
    options
  );
}
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsSuspenseQueryHookResult = ReturnType<
  typeof useClientsSuspenseQuery
>;
export type ClientsQueryResult = Apollo.QueryResult<
  ClientsQuery,
  ClientsQueryVariables
>;
export const RecentVisitClientsDocument = gql`
  query RecentVisitClients($medspaId: bigint!, $limit: Int, $offset: Int = 0) {
    client(
      where: {
        medspaId: { _eq: $medspaId }
        mostRecentVisit: { id: { _isNull: false } }
        active: { _eq: true }
      }
      orderBy: [
        { mostRecentVisitAggregate: { max: { startTime: DESC_NULLS_LAST } } }
        { id: ASC }
      ]
      limit: $limit
      offset: $offset
    ) {
      ...clientListFields
      mostRecentVisit {
        id
        startTime
      }
    }
  }
  ${ClientListFieldsFragmentDoc}
`;

/**
 * __useRecentVisitClientsQuery__
 *
 * To run a query within a React component, call `useRecentVisitClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentVisitClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentVisitClientsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRecentVisitClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >(RecentVisitClientsDocument, options);
}
export function useRecentVisitClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >(RecentVisitClientsDocument, options);
}
export function useRecentVisitClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RecentVisitClientsQuery,
    RecentVisitClientsQueryVariables
  >(RecentVisitClientsDocument, options);
}
export type RecentVisitClientsQueryHookResult = ReturnType<
  typeof useRecentVisitClientsQuery
>;
export type RecentVisitClientsLazyQueryHookResult = ReturnType<
  typeof useRecentVisitClientsLazyQuery
>;
export type RecentVisitClientsSuspenseQueryHookResult = ReturnType<
  typeof useRecentVisitClientsSuspenseQuery
>;
export type RecentVisitClientsQueryResult = Apollo.QueryResult<
  RecentVisitClientsQuery,
  RecentVisitClientsQueryVariables
>;
export const NoShowVisitClientsDocument = gql`
  query NoShowVisitClients($medspaId: bigint!, $limit: Int, $offset: Int = 0) {
    client(
      where: {
        medspaId: { _eq: $medspaId }
        lastNoShowVisit: { id: { _isNull: false } }
        active: { _eq: true }
      }
      orderBy: [
        { mostRecentVisitAggregate: { max: { startTime: DESC_NULLS_LAST } } }
        { id: ASC }
      ]
      limit: $limit
      offset: $offset
    ) {
      ...clientListFields
      lastNoShowVisit {
        id
        startTime
      }
    }
  }
  ${ClientListFieldsFragmentDoc}
`;

/**
 * __useNoShowVisitClientsQuery__
 *
 * To run a query within a React component, call `useNoShowVisitClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoShowVisitClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoShowVisitClientsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useNoShowVisitClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >(NoShowVisitClientsDocument, options);
}
export function useNoShowVisitClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >(NoShowVisitClientsDocument, options);
}
export function useNoShowVisitClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    NoShowVisitClientsQuery,
    NoShowVisitClientsQueryVariables
  >(NoShowVisitClientsDocument, options);
}
export type NoShowVisitClientsQueryHookResult = ReturnType<
  typeof useNoShowVisitClientsQuery
>;
export type NoShowVisitClientsLazyQueryHookResult = ReturnType<
  typeof useNoShowVisitClientsLazyQuery
>;
export type NoShowVisitClientsSuspenseQueryHookResult = ReturnType<
  typeof useNoShowVisitClientsSuspenseQuery
>;
export type NoShowVisitClientsQueryResult = Apollo.QueryResult<
  NoShowVisitClientsQuery,
  NoShowVisitClientsQueryVariables
>;
export const ClientForVisitCreationDocument = gql`
  query ClientForVisitCreation($clientId: bigint!) {
    clientByPk(id: $clientId) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

/**
 * __useClientForVisitCreationQuery__
 *
 * To run a query within a React component, call `useClientForVisitCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientForVisitCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientForVisitCreationQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientForVisitCreationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >(ClientForVisitCreationDocument, options);
}
export function useClientForVisitCreationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >(ClientForVisitCreationDocument, options);
}
export function useClientForVisitCreationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientForVisitCreationQuery,
    ClientForVisitCreationQueryVariables
  >(ClientForVisitCreationDocument, options);
}
export type ClientForVisitCreationQueryHookResult = ReturnType<
  typeof useClientForVisitCreationQuery
>;
export type ClientForVisitCreationLazyQueryHookResult = ReturnType<
  typeof useClientForVisitCreationLazyQuery
>;
export type ClientForVisitCreationSuspenseQueryHookResult = ReturnType<
  typeof useClientForVisitCreationSuspenseQuery
>;
export type ClientForVisitCreationQueryResult = Apollo.QueryResult<
  ClientForVisitCreationQuery,
  ClientForVisitCreationQueryVariables
>;
