import { useMedspaProvidersQuery } from "@/graphql/queries/medspaProviders.graphql.types";
import { useMedspaId } from "@/hooks/common/useMedspaId";

export const useMedspaProviders = () => {
  const medspaId = useMedspaId();

  const { data: providersData, error } = useMedspaProvidersQuery({
    variables: {
      id: medspaId,
    },
    skip: !medspaId,
  });

  const loading = providersData === undefined;

  const medspaProviders = providersData?.medspaByPk?.userMedspas ?? [];

  return {
    medspaProviders,
    loading,
    error,
  };
};
