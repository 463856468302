import { Checkbox, CheckboxProps } from "@mui/material";

export const MoxieCheckbox = ({ sx = {}, ...otherProps }: CheckboxProps) => (
  <Checkbox
    sx={{
      m: "-8px -2px -8px -8px !important",
      ...sx,
    }}
    {...otherProps}
  />
);
