import { Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import { GREY, PRIMARY } from "@/config/mui/colorPalette";
import { MenuHorizontalIcon } from "../common/icons";
import PreventOpenLink from "../common/preventOpenLink/preventOpenLink";

export type DropdownAction = {
  title: string;
  action: () => void;
};

export type DropdownLink = {
  title: string;
  link: string;
};

export default function Dropdown({
  onDelete,
  editLabel = "Edit",
  editLink,
  additionalActions,
  additionalLinks,
  iconColor,
  sx = {},
}: {
  onDelete?: () => void;
  editLabel?: string;
  editLink?: string;
  // TODO: Refactor - combine these 2 lists to be 1 with either action or link
  additionalActions?: DropdownAction[];
  additionalLinks?: DropdownLink[];
  iconColor?: string;
  sx?: SxProps<Theme>;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action: () => void) => {
    action();
    handleDropdownClose();
  };

  return (
    <PreventOpenLink>
      <Button
        sx={{
          shrink: 1,
          mt: -1,
          mr: -1,
          justifyContent: "center",
          minWidth: "fit-content",
          ...sx,
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <MenuHorizontalIcon color={iconColor || PRIMARY} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            boxShadow: "0px 4px 15px rgba(56, 19, 60, 0.25)",
            border: "1px solid",
            borderColor: GREY[30],
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {editLink && (
          <MenuItem href={editLink} component={Link}>
            {editLabel}
          </MenuItem>
        )}

        {additionalActions?.map((item) => (
          <MenuItem key={item.title} onClick={() => handleAction(item.action)}>
            {item.title}
          </MenuItem>
        ))}
        {additionalLinks?.map((item) => (
          <MenuItem key={item.title} href={item.link} component={Link}>
            {item.title}
          </MenuItem>
        ))}
        {onDelete && (
          <MenuItem onClick={() => handleAction(onDelete)}>Delete</MenuItem>
        )}
      </Menu>
    </PreventOpenLink>
  );
}
