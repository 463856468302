import { Box, Typography } from "@mui/material";
import { differenceInMinutes, format } from "date-fns";
import { TimeBlockCustomTile } from "@/components/serviceFlow/events/customTimeBlockTile";
import { VisitCustomTile } from "@/components/serviceFlow/events/customVisitTile";
import { TIME_FORMATS } from "@/config";
import {
  MoxieEventContentArg,
  SpecialShiftExtendedEventProps,
  TimeBlockExtendedEventProps,
  VisitExtendedEventProps,
} from "@/types/calendarEventType";
import SpecialShiftCustomTile from "./customSpecialShiftTile";

export function CustomEventTile(props: MoxieEventContentArg) {
  if (props.event.extendedProps.isTimeBlock) {
    return (
      <TimeBlockCustomTile
        {...(props as MoxieEventContentArg<TimeBlockExtendedEventProps>)}
      />
    );
  }

  if (props.event.extendedProps.isSpecialShift) {
    return (
      <SpecialShiftCustomTile
        {...(props as MoxieEventContentArg<SpecialShiftExtendedEventProps>)}
      />
    );
  }

  return (
    <VisitCustomTile
      {...(props as MoxieEventContentArg<VisitExtendedEventProps>)}
    />
  );
}

// Used when multiple providers view is enabled
export function SimpleCustomEventTile({
  event,
  timeText,
}: MoxieEventContentArg<VisitExtendedEventProps>) {
  const {
    start,
    end,
    title,
    extendedProps: { allDay, services, isSpecialShift },
  } = event;
  const duration = differenceInMinutes(end, start);
  const time =
    timeText ||
    `${format(start, TIME_FORMATS.HOUR_MINUTE_PICKER)} - ${format(
      end,
      TIME_FORMATS.HOUR_MINUTE_PICKER
    )}`;

  const textColor = isSpecialShift ? "text.secondary" : "inherit";

  return (
    <Box sx={{ cursor: isSpecialShift ? "default" : "pointer" }}>
      {allDay ? (
        <Typography variant="labelTiny" color={textColor}>
          <b>All day</b>
          <br />
        </Typography>
      ) : (
        time && (
          <Typography variant="labelTiny" color={textColor}>
            {time}
            {duration > 30 && <br />}
          </Typography>
        )
      )}
      <Typography
        variant="labelTiny"
        color={textColor}
        sx={{ marginLeft: duration > 30 ? "0" : "4px" }}
      >
        {title}
      </Typography>
      <Typography variant="labelTiny" color={textColor}>
        {[services].filter(Boolean).join(" • ")}
      </Typography>
    </Box>
  );
}
