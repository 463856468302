import { useUser } from "@/auth/useUser";
import { useMedspaId } from "@/hooks/common/useMedspaId";

export const useUserMedspaId = () => {
  // MDs/GFE Reviewers can be assigned to a multiple medspas,
  // so they can navigate through different pages where the medspaId is not the same as the one they are currently logged in.
  // This hook is used to get the userMedspaId for the medspa they are currently viewing.
  const { additionalUserDetails } = useUser();
  const medspaId = useMedspaId();

  return additionalUserDetails?.userMedspa.find(
    (userMedspa) => userMedspa.medspa.id === medspaId
  )?.id;
};

export const useUserMedspaIds = () => {
  const { additionalUserDetails } = useUser();

  return additionalUserDetails?.userMedspa.map(
    (userMedspa) => userMedspa.medspa.id
  );
};

export const useUserMedspaFilters = () => {
  const { additionalUserDetails } = useUser();

  return additionalUserDetails?.userMedspa.map((userMedspa) => ({
    id: userMedspa.medspa.id,
    label: userMedspa.medspa.name,
  }));
};
