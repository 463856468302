import toast from "react-hot-toast";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { useProviderSendFormsToGfeReviewMutation } from "@/graphql/mutations/providerSendFormsToGFEReview.graphql.types";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import { PROVIDER } from "@/types";
import { GfeStatus } from "@/types/gfe";
import useErrorLogger from "@/utils/useErrorLogger";
import { getGfeToReview, GfeForm } from "@/utils/visit";

const GFE_STATUS_TO_RESEND = [GfeStatus.GFE_APPROVED, GfeStatus.GFE_DECLINED];

const useSendGfeToReviewAction = (formsToSubmit: GfeForm[]) => {
  const logError = useErrorLogger();
  const [updateStatus] = useProviderSendFormsToGfeReviewMutation();

  return async () => {
    try {
      await toast.promise(
        updateStatus({
          variables: {
            formSubmissions: formsToSubmit.map(({ id }) => id),
          },
        }),
        {
          loading: "Sending GFE(s) for review...",
          success: "GFE(s) have been sent for review!",
          error: "Couldn't send GFE(s) for review, please try again later.",
        }
      );
    } catch (e) {
      logError(e);
    }
  };
};

export const useSendGfeToReviewItem = (forms: GfeForm[]): DropdownMenuItem => {
  const isProvider = useUserHasRoles([PROVIDER]);

  const isReReview = forms.some(({ gfeStatus }) =>
    GFE_STATUS_TO_RESEND.includes(gfeStatus as GfeStatus)
  );

  const formsToSubmit = getGfeToReview(forms, isReReview);
  const label = isReReview ? "Re-send" : "Send";
  const handleSend = useSendGfeToReviewAction(formsToSubmit);

  return {
    component: `${label} GFE for review`,
    disabled: !(formsToSubmit.length > 0 && isProvider),
    onClick: handleSend,
  };
};
