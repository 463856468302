import { AnalyticsBrowser } from "@segment/analytics-next";
import { createContext, useContext, useMemo } from "react";
import { useUser } from "@/auth/useUser";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import RouterListener from "./RouterListener";

export const AnalyticsContext = createContext(undefined);

export const AnalyticsProvider = ({
  children,
  writeKey,
  disablePageTracking,
}: {
  children: React.ReactNode;
  writeKey: string;
  disablePageTracking?: boolean;
}) => {
  const { medspa } = useUser();
  const analytics = useMemo(
    () => AnalyticsBrowser.load({ writeKey }),
    [writeKey]
  );

  useEffectOnce(!!analytics, () => {
    // Middleware to add groupId to every track call - required for Mixpanel group tracking
    // docs: https://www.notion.so/moxie-docs/Segment-Group-Analytics-27cd6beea06e4d8bac26696870108844
    analytics.addSourceMiddleware(({ payload, next }) => {
      const event = payload.obj;
      if (medspa && (event.type === "track" || event.type === "page")) {
        event.context = event.context || {};
        event.context.groupId = medspa;
      }
      next(payload);
    });
  });

  return (
    <AnalyticsContext.Provider value={analytics}>
      <RouterListener disablePageTracking={disablePageTracking} />
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
