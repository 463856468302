import { useRouter } from "next/router";

export const useIsOTPClientPage = () => {
  const { pathname } = useRouter();
  return (
    pathname.includes("/client/[medspaSlug]") &&
    !pathname.includes("/affirm-payment")
  );
};

export default useIsOTPClientPage;
