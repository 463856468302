import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

type Props = {
  loading?: boolean;
  loadingIconProps?: CircularProgressProps;
} & ButtonProps;

export default function LoadingButton({
  loading = false,
  loadingIconProps = {},
  disabled,
  ...props
}: Props) {
  return (
    <Button
      disabled={disabled || loading}
      endIcon={
        loading && (
          <CircularProgress size={20} color="secondary" {...loadingIconProps} />
        )
      }
      {...props}
    />
  );
}
