import { Button, SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";
import SlideBottomDialog, {
  SlideBottomDialogBaseProps,
} from "@/components/common/modals/slideBottomDialog";

export type FilterDialogWithSelectAllProps = SlideBottomDialogBaseProps & {
  allOptionsSelected: boolean;
  onDeselectAll: () => void;
  onSelectAll: () => void;
};

type FilterDialogProps = FilterDialogWithSelectAllProps & {
  title: string;
  content: ReactNode;
  height?: number | string;
};

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 72;

// This is a new version of the FilterDialog component that have select/deselect all button
// We should consider to replace the old FilterDialog component with this new version
function FilterDialogWithSelectAll({
  content,
  height = "auto",
  allOptionsSelected,
  onSelectAll,
  onDeselectAll,
  ...props
}: FilterDialogProps) {
  const handleButtonClick = () => {
    if (allOptionsSelected) {
      onDeselectAll();
    } else {
      onSelectAll();
    }
  };

  return (
    <SlideBottomDialog
      PaperProps={{ sx: { height } }}
      headerLeftButton={
        <SelectAllButton
          onClick={handleButtonClick}
          allOptionsSelected={allOptionsSelected}
        />
      }
      submitButtonText="Show results"
      {...props}
    >
      {content}
    </SlideBottomDialog>
  );
}

export const SelectAllButton = ({
  onClick,
  allOptionsSelected,
  sx,
}: {
  onClick: () => void;
  allOptionsSelected: boolean;
  sx?: SxProps<Theme>;
}) => (
  <Button variant="text" onClick={onClick} sx={{ p: 0, ...sx }}>
    <Typography variant="subtitleSmall">
      {allOptionsSelected ? "Deselect all" : "Select all"}
    </Typography>
  </Button>
);

export default FilterDialogWithSelectAll;
