import { Box, Tooltip } from "@mui/material";
import { WalletIcon } from "@/components/common/icons";

type Props = {
  color: string;
  iconSpacing?: boolean;
};

export default function TooltipWalletIcon({
  color,
  iconSpacing = false,
}: Props) {
  return (
    <Tooltip title="Credits available" arrow placement="right">
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          ml: iconSpacing ? 1 : undefined,
        }}
      >
        <WalletIcon strokeWidth={1.5} set="duotone" color={color} size="20" />
      </Box>
    </Tooltip>
  );
}
