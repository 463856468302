import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

type Props = {
  label: string;
  value: boolean;
  fieldName: string;
  withDivider?: boolean;
  setValue: (checked: boolean) => void;
  sx?: SxProps<Theme>;
};

export default function AdditionalCheckbox({
  label,
  value,
  fieldName,
  withDivider = true,
  setValue,
  sx,
}: Props) {
  return (
    <Box sx={{ mt: -2, mb: 4, ...sx }}>
      {withDivider && <Divider />}
      <FormControlLabel
        sx={{ alignItems: "flex-start", mt: 0.75 }}
        control={<Checkbox checked={value} value={fieldName} />}
        onChange={(e: React.SyntheticEvent) => {
          const { checked } = e.target as HTMLInputElement;

          setValue(checked);
        }}
        label={
          <Typography color="secondary" textAlign="left" mt={1.25}>
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
