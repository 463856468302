import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export default function useMoxieMediaQuery(
  size: "mobile" | "ipad-ver" | "ipad-hor" | "desktop",
  variant?: "up" | "down"
) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints[variant || "up"](size));
}
