import { IS_DEVELOPMENT } from "@/config";

// Medspa scoped feature flags (TODO, migrate the remaining global flags to this const)
export const FeatureFlags = {
  PAYMENTS_V1: "payments-v1",
  BALANCE_V1: "balance-v1",
  CHERRY_PAYMENTS_V1: "cherry-payments-v1",
  PACKAGES_ALLOW_RETAIL_PRODUCTS: "packages-allow-retail-products",
  MESSAGING_MAINTENANCE: "messaging-maintenance-v1",
  XPERIENCE_WALLET_TYPE: "xperience-wallet-type",
  STANDING_ORDERS_V1: "standing-orders-v1",
  AFFIRM_PAYMENT_METHOD: "affirm-payment-method",
  NEW_GFE_FLOW_V1: "new-gfe-flow-v1",
  NEW_GFE_FLOW_V2: "new-gfe-flow-v2",
  VENMO_PAYMENT_METHOD: "venmo-payment-method",
  ZELLE_PAYMENT_METHOD: "zelle-payment-method",
  TIMEKIT_SUNSET_V1: "timekit-sunset-v1",
  RESOURCES_MANAGEMENT_V2: "resources-management-v2",
  RESOURCES_MANAGEMENT_V3: "resources-management-v3",
  IS_IN_MAINTENANCE: "maintenance-banner-v1",
  PROVIDER_LATE_CANCELLATION: "provider-late-cancellation",
  CONSENT_FORM_IN_EVERY_APPT: "consent-form-in-every-appt",
  CLIENT_RESCHEDULING: "client-rescheduling",
  INVOICE_SUMMARY_UPDATE_SERVICES: "invoice-summary-update-services",
  PROVIDER_PERMISSIONS_V1: "provider-permissions-v1",
  LAYER_BOOKKEEPING_v1: "layer-bookkeeping-v1",
  CHARTS_OVERDUE_V1: "charts-overdue-v1",
  PROTOCOLS_V2: "protocols-v2",
  CALENDAR_EVENT_CREATION: "calendar-event-creation",
  NON_FUNGIBLE_WALLET_V0_1: "non-fungible-wallet-v0.1",
  IMPROVED_STATUS_CHIPS_V1: "improved-status-chips-v1",
  CHARTING_REVAMP_V1: "charting-revamp-v1",
  COMPLIANCE_HUB_V1: "compliance-hub-v1",
  COMPLIANCE_HUB_V2: "compliance-hub-v2",
  COMPLIANCE_HUB_V3: "compliance-hub-v3",
  COMPLIANCE_MD_MEETINGS_V1: "compliance-md-meetings-v1",
  ADVERSE_REACTIONS_V1: "adverse-reactions-v1",
  SYNC_GFE_V1: "sync-gfe-v1",
  MD_CHARTS_V1: "md-charts-v1",
  MARKETING_MESSAGES_V1: "marketing-messages-v1",
  REBOOKING_V2: "rebooking-v2",
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];

// Global feature flags
const FLAGS = {
  googleCalendarSync: IS_DEVELOPMENT,
} as const;

type Feature = keyof typeof FLAGS;

const isFeatureEnabled = (feature: Feature) => FLAGS[feature];
export const googleCalendarSyncEnabled = isFeatureEnabled("googleCalendarSync");
