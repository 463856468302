import { PRIMARY, VIOLET } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
};

export default function LoaderIconSvg({ size = "64" }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 65 64"
    >
      <path
        fill="#DAB3E6"
        d="M56.532 10.335a3.429 3.429 0 0 0-4.848 0l-8.312 8.312a3.429 3.429 0 0 0 4.848 4.848l8.313-8.312a3.429 3.429 0 0 0 0-4.848Zm8.04 22.195a3.429 3.429 0 0 0-3.43-3.428H49.389a3.429 3.429 0 1 0 0 6.857h11.755a3.429 3.429 0 0 0 3.428-3.428Z"
      />
      <path
        fill={PRIMARY}
        d="M34.327 60.571a3.429 3.429 0 0 1-6.857 0V48.816a3.429 3.429 0 0 1 6.857 0v11.755Z"
      />
      <path
        fill={VIOLET[20]}
        d="M3.429 34.898a3.429 3.429 0 1 1 0-6.857h11.755a3.429 3.429 0 0 1 0 6.857H3.429Z"
      />
      <path
        fill={VIOLET[80]}
        d="M54.237 50.375a3.428 3.428 0 1 1-4.849 4.849l-8.312-8.312a3.429 3.429 0 1 1 4.849-4.849l8.312 8.312Z"
      />
      <path
        fill={VIOLET[40]}
        d="M30.245 3.429a3.429 3.429 0 1 1 6.857 0v11.755a3.429 3.429 0 0 1-6.857 0V3.429Zm-19.91 10.196a3.429 3.429 0 1 1 4.848-4.849l8.313 8.312a3.429 3.429 0 0 1-4.85 4.849l-8.311-8.312Zm2.553 40.04a3.428 3.428 0 1 1-4.849-4.848l8.313-8.312a3.428 3.428 0 1 1 4.848 4.848l-8.312 8.312Z"
      />
    </svg>
  );
}
