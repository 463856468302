import { hasRole, useUser } from "@/auth/useUser";
import { useProviderInfoQuery } from "@/graphql/queries/user.graphql.types";
import { PROVIDER } from "@/types";

export default function useCurrentProviderInfo() {
  const { user } = useUser();
  const { data, ...rest } = useProviderInfoQuery({
    variables: {
      id: user?.id,
    },
    skip: !user || !hasRole(user, [PROVIDER]),
  });

  return {
    providerInfo: data?.userMedspa[0]?.providerInfo,
    data,
    ...rest,
  };
}
