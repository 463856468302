import toast from "react-hot-toast";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useUpdateVisitReviewStatusMutation } from "@/graphql/mutations/updateVisitReviewStatus.graphql.types";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import useCompleteVisit from "@/hooks/visits/useCompleteVisit";
import { PROVIDER, ReviewStatus, VisitStatus } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";
import { useSendVisitToMdAction } from "../reviewVisitDropdown/useReviewSendChartsToMdItem";

// TODO: Remove the file when chartsOverdueV1Enabled is enabled for all
export const PROMISE_MESSAGES = {
  loading: "Sending Charts to MD...",
  success: "Charts were sent to MD!",
  error: "Couldn't sent Charts to MD, please try again later.",
};

export const CHECKOUT_WARNING_OPTIONS = {
  title: "Unable to mark chart as complete",
  description:
    "You must first complete checkout for the appointment before you can mark the chart complete.",
  discardButtonText: "Close",
  confirmButtonText: "Go to Checkout",
};

const useSendChartsToReviewAction = (
  visitId: string,
  visitStatus: VisitStatus,
  invoiceId: string
) => {
  const logError = useErrorLogger();
  const [updateReview] = useUpdateVisitReviewStatusMutation();
  const { getConfirm } = useConfirm();
  const completeVisit = useCompleteVisit({
    id: visitId,
    status: visitStatus,
    invoice: invoiceId ? { id: invoiceId } : undefined,
  });

  return async () => {
    try {
      if (visitStatus === VisitStatus.COMPLETED) {
        await toast.promise(
          updateReview({
            variables: {
              visitId,
              reviewStatus: ReviewStatus.COMPLETE,
            },
          }),
          PROMISE_MESSAGES
        );
      } else {
        const shouldRedirectToCheckout = await getConfirm(
          CHECKOUT_WARNING_OPTIONS
        );
        if (shouldRedirectToCheckout) {
          completeVisit();
        }
      }
    } catch (e) {
      logError(e);
    }
  };
};

export const useSendChartToReviewItem = (
  visitStatus: VisitStatus,
  reviewStatus: ReviewStatus,
  visitId: string,
  label: string,
  invoiceId?: string
): DropdownMenuItem => {
  const isProvider = useUserHasRoles([PROVIDER]);
  const handleSend = useSendChartsToReviewAction(
    visitId,
    visitStatus,
    invoiceId
  );
  const handleResend = useSendVisitToMdAction(visitId);

  return {
    component: label,
    disabled: !isProvider,
    onClick:
      reviewStatus === ReviewStatus.INCOMPLETE ? handleSend : handleResend,
  };
};
