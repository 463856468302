import { useUser } from "@/auth/useUser";

export default function useMoxieBalancePermissions() {
  const { user, userMedspa } = useUser();
  // you must be the provider owner or PC owner to see balance page
  const isPCOwner =
    userMedspa?.medspa.professionalCorporation?.ownerId === user?.id &&
    user?.id !== undefined;

  let isLoadingPermissions = true;
  const isLoadingPermissionsMso =
    !user?.id ||
    userMedspa?.isProviderOwner === undefined ||
    !userMedspa?.medspa.professionalCorporation?.ownerId;

  const isLoadingPermissionsNonMso =
    !user?.id || userMedspa?.isProviderOwner === undefined;

  if (userMedspa) {
    isLoadingPermissions = userMedspa.medspa.isMso
      ? isLoadingPermissionsMso
      : isLoadingPermissionsNonMso;
  }

  const showBalance = userMedspa?.isProviderOwner || isPCOwner;

  return {
    showBalance,
    isLoadingPermissions,
  };
}
