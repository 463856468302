import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useUpdateFormSubmissionsGfeStatusMutation } from "@/graphql/mutations/updateFormSubmissionGfeStatus.graphql.types";
import { useIsEligibleForSelfReviewGfe } from "@/hooks/user/useIsEligibleForSelfReviewGfe";
import {
  getGfeSetStrategy,
  getSetGfeFormsToSubmit,
  GfeStrategy,
  useGfeStrategyStore,
} from "@/store/views/serviceFlow/visitDetails/gfeStrategyStore";
import { GfeStatus } from "@/types/gfe";
import useErrorLogger from "@/utils/useErrorLogger";
import { getGfeToApprove, getGfeToDecline, GfeForm } from "@/utils/visit";

export const GFE_NOTE_MODAL_CONFIG = {
  discardButtonText: "Go back",
  confirmButtonText: "Continue",
  title: "Add Diagnosis and Indicated Treatment",
  description:
    "All GFEs must include a note with Diagnosis and Indicated Treatment. Your progress will not be saved until you complete this note.",
};

export const useSelfReviewGfeItem = (
  visitId: string,
  formSubmissions: GfeForm[]
): {
  selfApproveGfeItem: DropdownMenuItem;
  selfDeclineGfeItem: DropdownMenuItem;
} => {
  const { medspa } = useUser();
  const { getConfirm } = useConfirm();
  const { push } = useRouter();
  const setGfeStrategy = useGfeStrategyStore(getGfeSetStrategy);
  const setFormsToSubmit = useGfeStrategyStore(getSetGfeFormsToSubmit);

  const isEligible = useIsEligibleForSelfReviewGfe();

  const formsToApprove = getGfeToApprove(formSubmissions);
  const formsToDecline = getGfeToDecline(formSubmissions);

  const selfReviewGfe = async (strategy: GfeStrategy) => {
    const confirmed = await getConfirm(GFE_NOTE_MODAL_CONFIG);
    if (!confirmed) return;

    const formsToSubmit =
      strategy === GfeStrategy.APPROVE ? formsToApprove : formsToDecline;

    setGfeStrategy(strategy);
    setFormsToSubmit(formsToSubmit);

    push(`/${medspa}/visits/${visitId}/notes/new/gfe-note`);
  };

  return {
    selfApproveGfeItem: {
      component: "Self approve GFE",
      disabled: !(isEligible && formsToApprove.length > 0),
      onClick: () => selfReviewGfe(GfeStrategy.APPROVE),
    },
    selfDeclineGfeItem: {
      component: "Self decline GFE",
      disabled: !(isEligible && formsToDecline.length > 0),
      onClick: () => selfReviewGfe(GfeStrategy.DECLINE),
    },
  };
};

export const useReviewGfeAction = (
  strategy: GfeStrategy,
  formsToSubmit: GfeForm[]
) => {
  const logError = useErrorLogger();
  const { user } = useUser();
  // This will not send any email notifications since it's not a graphene custom mutation
  // use only for self review, since  no email notifications are needed
  const [updateStatus] = useUpdateFormSubmissionsGfeStatusMutation();

  const isApprove = strategy === GfeStrategy.APPROVE;

  return async () => {
    try {
      await toast.promise(
        updateStatus({
          variables: {
            formSubmissionIds: formsToSubmit.map(({ id }) => id),
            gfeStatus: isApprove
              ? GfeStatus.GFE_APPROVED
              : GfeStatus.GFE_DECLINED,
            gfeCompletedByUserId: user.id,
          },
        }),
        {
          loading: `${isApprove ? "Approving" : "Declining"} GFE(s)...`,
          success: `GFE(s) were successfully ${
            isApprove ? "approved" : "declined"
          }!`,
          error: `Couldn't ${
            isApprove ? "approve" : "decline"
          } GFE(s), please try again later.`,
        }
      );
    } catch (e) {
      logError(e);
    }
  };
};
