import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedSpaFeatureFlagsQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["String"]["input"];
}>;

export type MedSpaFeatureFlagsQuery = {
  __typename?: "query_root";
  medspaPublicAdditionalInfo?: {
    __typename?: "MedspaPublicInfoType";
    id: string;
    enabledFeatures: Array<string | null>;
  } | null;
};

export const MedSpaFeatureFlagsDocument = gql`
  query MedSpaFeatureFlags($medspaId: String!) {
    medspaPublicAdditionalInfo(id: $medspaId) {
      id
      enabledFeatures
    }
  }
`;

/**
 * __useMedSpaFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useMedSpaFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedSpaFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedSpaFeatureFlagsQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useMedSpaFeatureFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >(MedSpaFeatureFlagsDocument, options);
}
export function useMedSpaFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >(MedSpaFeatureFlagsDocument, options);
}
export function useMedSpaFeatureFlagsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedSpaFeatureFlagsQuery,
    MedSpaFeatureFlagsQueryVariables
  >(MedSpaFeatureFlagsDocument, options);
}
export type MedSpaFeatureFlagsQueryHookResult = ReturnType<
  typeof useMedSpaFeatureFlagsQuery
>;
export type MedSpaFeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useMedSpaFeatureFlagsLazyQuery
>;
export type MedSpaFeatureFlagsSuspenseQueryHookResult = ReturnType<
  typeof useMedSpaFeatureFlagsSuspenseQuery
>;
export type MedSpaFeatureFlagsQueryResult = Apollo.QueryResult<
  MedSpaFeatureFlagsQuery,
  MedSpaFeatureFlagsQueryVariables
>;
