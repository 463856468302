import { Radio, RadioProps } from "@mui/material";

export const MoxieRadio = ({ sx = {}, ...otherProps }: RadioProps) => (
  <Radio
    sx={{
      m: "-8px -2px -8px -8px !important",
      ...sx,
    }}
    {...otherProps}
  />
);
