import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

// A class dedicated to store message returned from django custom mutation
// the message inside is descriptive enough to show it to the user with
// `toast.error()`
export class DjangoMutationError extends GraphQLError {
  constructor(message: string) {
    super(message);
    this.name = "DjangoMutationError";
  }
}

export const hasDjangoMutationError = (error: unknown) => {
  return (
    error instanceof ApolloError &&
    error?.graphQLErrors?.[0] instanceof DjangoMutationError
  );
};
