import useCurrentProviderInfo from "./useCurrentProviderInfo";

export const useIsEligibleWeightLossPharmacies = (): {
  empower: boolean;
  strive: boolean;
} => {
  const { data } = useCurrentProviderInfo();

  // check if provider is eligible for specific weight loss pharmacies - think of this as a userMedspa level feature flag
  return {
    empower: !!data?.userMedspa[0]?.isEligibleForEmpowerWeightLoss,
    strive: !!data?.userMedspa[0]?.isEligibleForStriveWeightLoss,
  };
};
