import { Close } from "@mui/icons-material";
import Box, { type BoxProps } from "@mui/material/Box";
import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import Stack, { type StackProps } from "@mui/material/Stack";
import Typography, { type TypographyProps } from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import React, { forwardRef } from "react";
import { useDrawerContext } from "./_drawerContext";

export type DrawerHeaderLeadingActionProps =
  | {
      leadingAction?: React.ReactElement;
      leadingIcon?: never;
      onLeadingAction?: never;
      LeadingIconButtonProps?: never;
    }
  | {
      leadingIcon?: React.ReactNode;
      leadingAction?: never;
      onLeadingAction?: VoidFunction;
      LeadingIconButtonProps?: Partial<IconButtonProps>;
    };

export type DrawerHeaderCloseActionProps =
  | {
      closeAction?: React.ReactElement;
      closeIcon?: never;
      onCloseAction?: never;
      CloseIconButtonProps?: never;
    }
  | {
      closeIcon?: React.ReactNode;
      closeAction?: never;
      onCloseAction?: VoidFunction;
      CloseIconButtonProps?: Partial<IconButtonProps>;
    };

export type DrawerHeaderProps = {
  title?: React.ReactNode;
  /** @default false */
  hideCloseAction?: boolean;
  HeaderProps?: Partial<StackProps>;
  TitleProps?: Partial<TypographyProps>;
  LeadingActionBoxProps?: Partial<BoxProps>;
  CloseActionBoxProps?: Partial<BoxProps>;
} & DrawerHeaderLeadingActionProps &
  DrawerHeaderCloseActionProps;

const ActionBox = styled(
  Box,
  {}
)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: 0,
  justifyContent: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

const DrawerHeaderImpl: React.ForwardRefRenderFunction<
  HTMLDivElement,
  DrawerHeaderProps
> = (
  {
    title,
    hideCloseAction,
    closeAction,
    closeIcon,
    leadingAction,
    leadingIcon,
    onCloseAction,
    onLeadingAction,
    HeaderProps,
    TitleProps,
    LeadingActionBoxProps,
    LeadingIconButtonProps,
    CloseActionBoxProps,
    CloseIconButtonProps,
  },
  ref
) => {
  const theme = useTheme();

  const { close } = useDrawerContext();

  return (
    <Stack
      flex={0}
      gap={0.5}
      ref={ref}
      component="header"
      direction="row"
      alignItems="center"
      px={theme.spacing(2)}
      py={theme.spacing(1)}
      {...HeaderProps}
    >
      {(leadingAction || leadingIcon) && (
        <ActionBox {...LeadingActionBoxProps}>
          {leadingAction != null ? (
            leadingAction
          ) : (
            <IconButton
              onClick={onLeadingAction}
              color="primary"
              {...LeadingIconButtonProps}
            >
              {leadingIcon}
            </IconButton>
          )}
        </ActionBox>
      )}
      {"string" === typeof title ? (
        <Typography
          variant="h2"
          fontWeight="bold"
          flex={1}
          {...TitleProps}
          noWrap
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {!hideCloseAction && (
        <ActionBox {...CloseActionBoxProps}>
          {closeAction != null ? (
            closeAction
          ) : (
            <IconButton
              onClick={onCloseAction ?? close}
              color="primary"
              {...CloseIconButtonProps}
            >
              {closeIcon ?? <Close height={24} color="primary" />}
            </IconButton>
          )}
        </ActionBox>
      )}
    </Stack>
  );
};

const DrawerHeader = forwardRef(DrawerHeaderImpl);

export default DrawerHeader;
