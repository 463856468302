import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type ProviderSendFormsToGfeReviewMutationVariables = Types.Exact<{
  formSubmissions:
    | Array<Types.Scalars["BigInt"]["input"]>
    | Types.Scalars["BigInt"]["input"];
}>;

export type ProviderSendFormsToGfeReviewMutation = {
  __typename?: "mutation_root";
  sendFormsToGfeReview?: {
    __typename?: "SendFormsToGFEReview";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const ProviderSendFormsToGfeReviewDocument = gql`
  mutation ProviderSendFormsToGFEReview($formSubmissions: [BigInt!]!) {
    sendFormsToGfeReview(formSubmissions: $formSubmissions) {
      ok
      message
    }
  }
`;
export type ProviderSendFormsToGfeReviewMutationFn = Apollo.MutationFunction<
  ProviderSendFormsToGfeReviewMutation,
  ProviderSendFormsToGfeReviewMutationVariables
>;

/**
 * __useProviderSendFormsToGfeReviewMutation__
 *
 * To run a mutation, you first call `useProviderSendFormsToGfeReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderSendFormsToGfeReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerSendFormsToGfeReviewMutation, { data, loading, error }] = useProviderSendFormsToGfeReviewMutation({
 *   variables: {
 *      formSubmissions: // value for 'formSubmissions'
 *   },
 * });
 */
export function useProviderSendFormsToGfeReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProviderSendFormsToGfeReviewMutation,
    ProviderSendFormsToGfeReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProviderSendFormsToGfeReviewMutation,
    ProviderSendFormsToGfeReviewMutationVariables
  >(ProviderSendFormsToGfeReviewDocument, options);
}
export type ProviderSendFormsToGfeReviewMutationHookResult = ReturnType<
  typeof useProviderSendFormsToGfeReviewMutation
>;
export type ProviderSendFormsToGfeReviewMutationResult =
  Apollo.MutationResult<ProviderSendFormsToGfeReviewMutation>;
export type ProviderSendFormsToGfeReviewMutationOptions =
  Apollo.BaseMutationOptions<
    ProviderSendFormsToGfeReviewMutation,
    ProviderSendFormsToGfeReviewMutationVariables
  >;
