import { AppBar, Toolbar, Typography, Stack } from "@mui/material";
import { useUser } from "@/auth/useUser";
import { NEXT_PUBLIC_MAINTENANCE_MESSAGE } from "@/config";
import { VIOLET, GREY } from "@/config/mui/colorPalette";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";

export const MAINTENANCE_BANNER_HEIGHT = "50px";

const MaintenanceBanner = () => {
  const { user } = useUser();
  const { isMaintenanceScheduled } = useFeatureFlags();

  const { impersonatedId } = user;

  if (!isMaintenanceScheduled || impersonatedId) {
    return null;
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: VIOLET[100] }}>
      <Toolbar
        variant="dense"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: MAINTENANCE_BANNER_HEIGHT,
        }}
      >
        <Stack direction="row" justifyContent="center" spacing={1} width="100%">
          <Typography variant="paragraphSmall" fontWeight="bold">
            {NEXT_PUBLIC_MAINTENANCE_MESSAGE}
            <Typography variant="paragraphSmall" color={GREY[20]}>
              During this time, Moxie suite will be unavailable for use. Please
              reach out to your PSM if you have any questions
            </Typography>
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default MaintenanceBanner;
