import { useFragment } from "@apollo/client";
import {
  VisitDropdownFieldsFragment,
  VisitDropdownFieldsFragmentDoc,
} from "@/graphql/queries/visit/visitDropdown.graphql.types";

export default function useCurrentVisitDropdownFragment(visitId?: string) {
  return useFragment<VisitDropdownFieldsFragment>({
    fragment: VisitDropdownFieldsFragmentDoc,
    fragmentName: "VisitDropdownFields",
    from: {
      __typename: "visit",
      id: visitId,
    },
  });
}
