import { GREY } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
  strokeWidth?: string;
  color?: string;
};

export default function SwitchAccountIconSvg({
  size = "24",
  strokeWidth = "1.5",
  color = GREY[70],
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18.0011 19.1802C18.0011 17.5604 16.8294 15.4718 15.0011 15.4719H9.00112C7.17284 15.4718 6.00112 17.5604 6.00112 19.1802M20.4771 9.4614C19.2271 5.9914 15.9071 3.5014 12.0071 3.5014C7.55707 3.5014 3.86707 6.7314 3.13707 10.9714C3.13707 10.9714 2.98707 11.7914 3.03707 12.7114M5.22707 10.9314L3.02707 12.7114L1.24707 10.5114M3.52309 15.5386C4.77309 19.0086 8.09309 21.4986 11.9931 21.4986C16.4431 21.4986 20.1331 18.2686 20.8631 14.0286C20.8631 14.0286 21.0131 13.2086 20.9631 12.2886M18.7731 14.0686L20.9731 12.2886L22.7531 14.4886M15.0011 9.47185C15.0011 11.1287 13.658 12.4719 12.0011 12.4719C10.3443 12.4719 9.00112 11.1287 9.00112 9.47185C9.00112 7.815 10.3443 6.47185 12.0011 6.47185C13.658 6.47185 15.0011 7.815 15.0011 9.47185Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
