import { useState } from "react";
import toast from "react-hot-toast";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { useConfirm } from "@/components/providers/confirmProvider";
import ChartSignatureDialog from "@/components/serviceFlow/charts/chartSignatureDialog";
import ChartSignaturePreview, {
  ChartSignaturePreviewWithData,
} from "@/components/serviceFlow/charts/chartSignaturePreview";
import { SignatureData } from "@/components/serviceFlow/visitDetails/confirmations/chartHistory";
import { useUpdateVisitReviewStatusMutation } from "@/graphql/mutations/updateVisitReviewStatus.graphql.types";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import useCompleteVisit from "@/hooks/visits/useCompleteVisit";
import { PROVIDER, ReviewStatus, VisitStatus } from "@/types";
import useErrorLogger from "@/utils/useErrorLogger";

export enum ChartDialogType {
  SIGN_AND_SEND_CHARTS = "sign_and_send_charts",
  VIEW_SIGNATURE = "view_signature",
}

const UPDATE_REVIEW_STATUS_MESSAGES = {
  loading: "Updating chart status...",
  success: "Chart status updated!",
  error: "Couldn't update chart status, please try again later.",
};

const CHECKOUT_WARNING_OPTIONS = {
  title: "Unable to mark chart as complete",
  description:
    "You must first complete checkout for the appointment before you can mark the chart complete.",
  discardButtonText: "Close",
  confirmButtonText: "Go to Checkout",
};

const MARK_AS_NOT_REQUIRED_OPTIONS = {
  title: "Mark chart as Not Required?",
  description:
    "This should be used for appointments that do not perform medical services. Do you want to continue?",
  discardButtonText: "Cancel",
  confirmButtonText: "Yes",
};

const useSendChartsToReview = (
  visitId: string,
  visitStatus: VisitStatus,
  invoiceId: string,
  openDialog: (dialog: ChartDialogType) => void
) => {
  const logError = useErrorLogger();
  const { getConfirm } = useConfirm();

  const completeVisit = useCompleteVisit({
    id: visitId,
    status: visitStatus,
    invoice: invoiceId ? { id: invoiceId } : undefined,
  });

  return async () => {
    try {
      if (visitStatus === VisitStatus.COMPLETED) {
        openDialog(ChartDialogType.SIGN_AND_SEND_CHARTS);
      } else {
        const shouldRedirectToCheckout = await getConfirm(
          CHECKOUT_WARNING_OPTIONS
        );
        if (shouldRedirectToCheckout) {
          completeVisit();
        }
      }
    } catch (e) {
      logError(e);
    }
  };
};

const useMarkChartAsNotRequired = (visitId: string) => {
  const logError = useErrorLogger();
  const { getConfirm } = useConfirm();

  const [updateReview] = useUpdateVisitReviewStatusMutation();

  return async () => {
    try {
      const shouldMarkAsNotRequired = await getConfirm(
        MARK_AS_NOT_REQUIRED_OPTIONS
      );
      if (shouldMarkAsNotRequired) {
        await toast.promise(
          updateReview({
            variables: {
              visitId,
              reviewStatus: ReviewStatus.NOT_REQUIRED,
            },
            update(cache) {
              cache.modify({
                id: `visit:${visitId}`,
                fields: {
                  reviewStatus() {
                    return ReviewStatus.NOT_REQUIRED;
                  },
                  reviewStatusChangeLogs() {
                    return [
                      {
                        id: "temp-id",
                        created: new Date().toISOString(),
                      },
                    ];
                  },
                },
              });
            },
          }),
          UPDATE_REVIEW_STATUS_MESSAGES
        );
      }
    } catch (e) {
      logError(e);
    }
  };
};

const useMarkChartAsRequired = (visitId: string) => {
  const logError = useErrorLogger();

  const [updateReview] = useUpdateVisitReviewStatusMutation();

  return async () => {
    try {
      await toast.promise(
        updateReview({
          variables: {
            visitId,
            reviewStatus: ReviewStatus.INCOMPLETE,
          },
        }),
        UPDATE_REVIEW_STATUS_MESSAGES
      );
    } catch (e) {
      logError(e);
    }
  };
};

const isChartAwaitingProviderAction = (reviewStatus: ReviewStatus) =>
  [ReviewStatus.INCOMPLETE, ReviewStatus.OVERDUE].includes(reviewStatus);

const isProviderActionComplete = (reviewStatus: ReviewStatus) =>
  [ReviewStatus.COMPLETE, ReviewStatus.REVIEWED].includes(reviewStatus);

export const useChartReviewActions = (
  visitId: string,
  visitStatus: VisitStatus,
  reviewStatus: ReviewStatus,
  isChartSigned: boolean,
  invoiceId?: string
): {
  actions: DropdownMenuItem[];
  dialogComponent: JSX.Element;
  signChart: () => void;
  handleViewSignature: (signature: SignatureData) => void;
} => {
  const [currentDialogComponent, setCurrentDialogComponent] =
    useState<ChartDialogType>(null);
  const [signatureData, setSignatureData] = useState<SignatureData | null>(
    null
  );

  const onDialogOpen = (dialogComponent: ChartDialogType) =>
    setCurrentDialogComponent(dialogComponent);
  const onDialogClose = () => {
    setCurrentDialogComponent(null);
    if (signatureData) setSignatureData(null);
  };

  const handleMarkAsNotRequired = useMarkChartAsNotRequired(visitId);
  const handleMarkAsRequired = useMarkChartAsRequired(visitId);
  const handleSend = useSendChartsToReview(
    visitId,
    visitStatus,
    invoiceId,
    () => onDialogOpen(ChartDialogType.SIGN_AND_SEND_CHARTS)
  );

  const handleViewSignature = (signature: SignatureData) => {
    setSignatureData(signature);
    onDialogOpen(ChartDialogType.VIEW_SIGNATURE);
  };

  if (!useUserHasRoles([PROVIDER])) {
    const dialogComponentWithViewSignature = {
      [ChartDialogType.VIEW_SIGNATURE]: (
        <ChartSignaturePreviewWithData
          signature={signatureData}
          onClose={onDialogClose}
        />
      ),
    }[currentDialogComponent];

    // MD can see signatures
    return {
      actions: [],
      dialogComponent: dialogComponentWithViewSignature,
      signChart: () => {},
      handleViewSignature,
    };
  }

  const markAsCompleteAndSendToReview = {
    component: isProviderActionComplete(reviewStatus)
      ? "Re-send chart to MD for review"
      : "Mark chart as complete & send to MD",
    disabled: !(reviewStatus !== ReviewStatus.NOT_REQUIRED),
    onClick: handleSend,
    divider: true,
  };

  const markAsNotRequired = {
    component: "Mark chart as not required",
    disabled: !isChartAwaitingProviderAction(reviewStatus),
    onClick: handleMarkAsNotRequired,
    divider: true,
  };

  const markAsRequired = {
    component: "Mark chart as required",
    disabled: !(reviewStatus === ReviewStatus.NOT_REQUIRED),
    onClick: handleMarkAsRequired,
    divider: true,
  };

  const viewProviderSignature = {
    component: "View provider chart signature",
    disabled: !(isChartSigned && isProviderActionComplete(reviewStatus)),
    onClick: () => onDialogOpen(ChartDialogType.VIEW_SIGNATURE),
    divider: true,
  };

  const actions = [
    markAsCompleteAndSendToReview,
    markAsNotRequired,
    markAsRequired,
    viewProviderSignature,
  ].filter(({ disabled }) => !disabled);

  const dialogComponent = {
    [ChartDialogType.SIGN_AND_SEND_CHARTS]: (
      <ChartSignatureDialog visitId={visitId} onClose={onDialogClose} />
    ),
    [ChartDialogType.VIEW_SIGNATURE]: signatureData ? (
      <ChartSignaturePreviewWithData
        signature={signatureData}
        onClose={onDialogClose}
      />
    ) : (
      <ChartSignaturePreview visitId={visitId} onClose={onDialogClose} />
    ),
  }[currentDialogComponent];

  return {
    actions,
    dialogComponent,
    signChart: handleSend,
    handleViewSignature,
  };
};
