import Box, { type BoxProps } from "@mui/material/Box";
import Divider from "@mui/material/Divider/Divider";
import React from "react";

export type DrawerActionsProps = Partial<BoxProps & { hideDivider: boolean }>;

const DrawerActions: React.FC<React.PropsWithChildren<DrawerActionsProps>> = ({
  hideDivider = false,
  children,
  ...props
}) => (
  <>
    {!hideDivider && (
      <Divider
        flexItem
        light
        orientation="horizontal"
        variant="fullWidth"
        sx={{ flex: 0 }}
      />
    )}
    <Box
      flex={0}
      display="flex"
      alignItems="center"
      {...props}
      sx={[
        (theme) => ({
          gap: theme.spacing(1),
          padding: theme.spacing(2),
        }),
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {children}
    </Box>
  </>
);

export default DrawerActions;
