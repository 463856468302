import { PRIMARY } from "@/config/mui/colorPalette";

type Props = {
  size?: string;
  strokeWidth?: string;
  color?: string;
};

export default function MoneyIcon({
  size = "24",
  strokeWidth = "1.5",
  color = PRIMARY,
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9375 6.5H3.0625C2.6828 6.5 2.375 6.8078 2.375 7.1875V16.8125C2.375 17.1922 2.6828 17.5 3.0625 17.5H20.9375C21.3172 17.5 21.625 17.1922 21.625 16.8125V7.1875C21.625 6.8078 21.3172 6.5 20.9375 6.5Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 6.5L21.625 11.3125"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.125 17.5L21.625 12.6875"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 6.5L2.375 11.3125"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 17.5L2.375 12.6875"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
