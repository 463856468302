import { useState, useEffect } from "react";

type ExpandedState = {
  [id: string]: boolean;
};

const getInitialExpandedState = <T extends { id: string }>(
  items: T[],
  defaultExpanded = true
): ExpandedState => {
  return items.reduce(
    (acc, item) => ({ ...acc, [item.id]: defaultExpanded }),
    {}
  );
};

export const useExpandedState = <T extends { id: string }>(
  items: T[],
  defaultExpanded?: boolean
) => {
  const [itemExpanded, setItemExpanded] = useState<ExpandedState>(
    getInitialExpandedState(items, defaultExpanded)
  );

  // Add expand id to object if new items come in
  useEffect(() => {
    items.forEach((item) => {
      if (!(item.id in itemExpanded)) {
        setItemExpanded((prevVal) => ({
          ...prevVal,
          [item.id]: defaultExpanded,
        }));
      }
    });
  }, [items]);

  const handleItemExpand = (id: string) => {
    setItemExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return { itemExpanded, onItemExpand: handleItemExpand };
};
