import { useUser } from "@/auth/useUser";
import SignatureDialog from "@/components/serviceFlow/forms/signatureDialog";
import { useSignAndSendChartToReview } from "./useSignAndSendChartToReview";

export default function ChartSignatureDialog({
  visitId,
  onClose,
}: {
  visitId: string;
  onClose: () => void;
}) {
  const { user } = useUser();
  const { sendChartToMd } = useSignAndSendChartToReview(visitId, onClose);

  return (
    <SignatureDialog
      open
      title="Sign & send to MD"
      buttonText="Submit"
      onClose={onClose}
      displayName={user?.displayName}
      onSignSubmit={sendChartToMd}
    />
  );
}
