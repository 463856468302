import { Button, Typography, useTheme } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { ReactNode } from "react";
import { Icon } from "@/components/common/icons";
import { VIOLET, GREY } from "@/config/mui/colorPalette";

export type PillButtonProps = ButtonProps & {
  children: ReactNode;
  iconLeft?: (props: Icon) => ReactNode;
  iconRight?: (props: Icon) => ReactNode;
  isActive?: boolean;
};

function PillButton({
  iconLeft,
  iconRight,
  isActive,
  sx = {},
  children,
  ...props
}: PillButtonProps) {
  const theme = useTheme();
  const white = theme.palette.third.main;

  const iconProps: Icon = {
    color: isActive ? VIOLET[60] : GREY[60],
    size: "16px",
  };

  return (
    <Button
      size="small"
      color={isActive ? "violet" : "lightGray"}
      variant="contained"
      startIcon={iconLeft && iconLeft(iconProps)}
      endIcon={iconRight && iconRight(iconProps)}
      sx={{
        height: 36,
        borderRadius: 40,
        px: 1.5,
        py: 1,
        color: isActive ? white : theme.palette.text.primary,
        whiteSpace: "nowrap",
        ...sx,
      }}
      {...props}
    >
      <Typography variant="labelTiny">{children}</Typography>
    </Button>
  );
}

export default PillButton;
