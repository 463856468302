import { useEffect, MutableRefObject } from "react";

export default function useResizeObserver<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  callback: (rect: DOMRectReadOnly) => void
) {
  useEffect(() => {
    if (!ref || !ref.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries[0].contentRect);
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, [ref, callback]);
}
