import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type TwilioTokenQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type TwilioTokenQuery = {
  __typename?: "query_root";
  medspaByPk?: {
    __typename?: "medspa";
    id: string;
    additionalInfo?: {
      __typename?: "MedSpaType";
      id: string;
      twilioConversationsToken?: string | null;
    } | null;
  } | null;
};

export const TwilioTokenDocument = gql`
  query TwilioToken($id: bigint!) {
    medspaByPk(id: $id) {
      id
      additionalInfo {
        id
        twilioConversationsToken
      }
    }
  }
`;

/**
 * __useTwilioTokenQuery__
 *
 * To run a query within a React component, call `useTwilioTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwilioTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwilioTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTwilioTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    TwilioTokenQuery,
    TwilioTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TwilioTokenQuery, TwilioTokenQueryVariables>(
    TwilioTokenDocument,
    options
  );
}
export function useTwilioTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TwilioTokenQuery,
    TwilioTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TwilioTokenQuery, TwilioTokenQueryVariables>(
    TwilioTokenDocument,
    options
  );
}
export function useTwilioTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TwilioTokenQuery,
    TwilioTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TwilioTokenQuery, TwilioTokenQueryVariables>(
    TwilioTokenDocument,
    options
  );
}
export type TwilioTokenQueryHookResult = ReturnType<typeof useTwilioTokenQuery>;
export type TwilioTokenLazyQueryHookResult = ReturnType<
  typeof useTwilioTokenLazyQuery
>;
export type TwilioTokenSuspenseQueryHookResult = ReturnType<
  typeof useTwilioTokenSuspenseQuery
>;
export type TwilioTokenQueryResult = Apollo.QueryResult<
  TwilioTokenQuery,
  TwilioTokenQueryVariables
>;
