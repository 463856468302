import { MutableRefObject, useEffect, useRef } from "react";
import useSyncCalendarTimeWithTimezone from "@/hooks/calendar/useSyncCalendarTimeWithTimezone";

const INDICATOR_LINE_CLASS_SELECTOR = ".fc-timegrid-now-indicator-line";
const FIRST_NOW_INDICATOR_LINE_CLASS = "first-now-indicator-line";

export default function useCalendarNowIndicator(
  calendarWrapperRef: MutableRefObject<HTMLDivElement>
) {
  const { now, medspaReadableTimezone } = useSyncCalendarTimeWithTimezone();

  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const nowIndicator = calendarWrapperRef.current.querySelector(
            INDICATOR_LINE_CLASS_SELECTOR
          );

          if (
            nowIndicator &&
            !nowIndicator.classList.contains(FIRST_NOW_INDICATOR_LINE_CLASS)
          ) {
            // Afaik there is no way to grab first occurrence of the element in pure CSS when they have different parents
            // so we need this hacky solution
            nowIndicator.classList.add(FIRST_NOW_INDICATOR_LINE_CLASS);
          }
        }
      }
    });

    observer.observe(calendarWrapperRef.current, {
      childList: true,
      subtree: true,
    });

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [calendarWrapperRef]);

  return { now, medspaReadableTimezone };
}
