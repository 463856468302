import {
  MobileTimePicker,
  renderMultiSectionDigitalClockTimeView,
} from "@mui/x-date-pickers";
import { forwardRef } from "react";

type TimePickerProps = React.ComponentProps<typeof MobileTimePicker>;

// issue with TimeViewWithMeridiem not being used in the MobileTimePickerProps still persists in the library,
// this is a temporary solution to satisfy the compiler
type ViewRenderers = {
  [key: string]: (props: unknown) => JSX.Element;
};

const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(
  function TimePickerA(props, ref) {
    return (
      <MobileTimePicker
        {...props}
        ref={ref}
        slots={{ ...props.slots, toolbar: null }}
        closeOnSelect
        slotProps={{
          ...props.slotProps,
          dialog: {
            onClose: () => void undefined,
          },
          mobilePaper: {
            sx: {
              minWidth: "150px !important",
              "& .MuiMultiSectionDigitalClock-root": {
                borderLeft: "none !important",
              },
              '& .MuiMultiSectionDigitalClock-root  [aria-label="Select meridiem"]::after':
                {
                  content: "none",
                },
              "& .MuiButtonBase-root": {
                borderRadius: "3px",
                width: "60px",
              },
              "& .MuiList-root": {
                width: "70px",
                overflowY: "auto !important",
              },
            },
          },
          layout: {
            sx: {
              padding: 1,
            },
          },
        }}
        viewRenderers={
          {
            hours: renderMultiSectionDigitalClockTimeView,
            minutes: renderMultiSectionDigitalClockTimeView,
            seconds: renderMultiSectionDigitalClockTimeView,
          } as ViewRenderers
        }
      />
    );
  }
);

export default TimePicker;
