import FullCalendar from "@fullcalendar/react";
import { format } from "date-fns";
import { MutableRefObject, useLayoutEffect } from "react";
import { TIME_FORMATS } from "@/config";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";

export default function useCalendarInitialScroll(
  ref: MutableRefObject<FullCalendar>
) {
  const { getMedspaCurrentTime } = useMedspaTimezone();

  useLayoutEffect(() => {
    ref.current
      .getApi()
      .scrollToTime(
        format(getMedspaCurrentTime(), TIME_FORMATS.HOUR_MINUTE_PICKER)
      );
  }, [getMedspaCurrentTime]);
}
