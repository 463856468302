import { useState, useCallback } from "react";

export const useSessionStorage = <T>(
  keyName: string,
  defaultValue?: T
): [T, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = useCallback(
    (newValue: T) => {
      try {
        window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
      } finally {
        setStoredValue(newValue);
      }
    },
    [keyName]
  );

  return [storedValue, setValue];
};
