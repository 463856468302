import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ERROR, ERROR_LIGHT } from "@/config/mui/colorPalette";

export const NotificationsDot = ({ count }: { count: number }) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: "24px",
        height: "24px",
        bgcolor: ERROR_LIGHT,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="labelTiny" color={ERROR}>
        {count}
      </Typography>
    </Box>
  );
};
