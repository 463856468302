import type { ModalProps } from "@mui/material/Modal";
import type { PaperProps } from "@mui/material/Paper";
import SwipeableDrawer, {
  type SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import { useTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import React, { useRef, useMemo, forwardRef } from "react";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";
import DrawerProvider, {
  useDrawerContext,
  type DrawerContextType,
} from "./_drawerContext";

export type DrawerProps = {
  open?: boolean;
  DrawerProps?: Partial<
    Omit<SwipeableDrawerProps, "ModalProps" | "PaperProps" | "anchor">
  >;
  anchor?: SwipeableDrawerProps["anchor"];
  PaperProps?: Partial<PaperProps>;
  ModalProps?: Partial<ModalProps>;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
};

export type DrawerRef = DrawerContextType;

type DrawerInnerProps = Omit<DrawerProps, "onClose" | "onOpen" | "open">;

const DrawerInner: React.FC<React.PropsWithChildren<DrawerInnerProps>> = ({
  DrawerProps,
  ModalProps,
  PaperProps,
  anchor,
  children,
}) => {
  const theme = useTheme();

  const { close, open, isOpen } = useDrawerContext();

  const swipeableDrawerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMoxieMediaQuery("ipad-ver", "down");

  const _anchor = useMemo(
    () => (anchor ?? isMobile ? "bottom" : "right"),
    [isMobile, anchor]
  );

  const maxSize = useMemo(
    () =>
      isMobile
        ? { maxHeight: "90dvh", minHeight: "30dvh" }
        : { maxWidth: "65dvw", minWidth: "280px" },
    [isMobile]
  );

  return (
    <SwipeableDrawer
      ref={swipeableDrawerRef}
      open={isOpen}
      anchor={_anchor}
      onClose={close}
      onOpen={open}
      disableSwipeToOpen
      disableDiscovery
      {...DrawerProps}
      sx={[
        { zIndex: theme.zIndex.drawer + 2 }, // the top bar is at zIndex.drawer + 1
        ...(Array.isArray(DrawerProps?.sx)
          ? DrawerProps.sx
          : [DrawerProps?.sx]),
      ]}
      PaperProps={{
        ...PaperProps,
        sx: [
          {
            ...maxSize,
            boxShadow: shadows[8],
            borderTopLeftRadius: {
              xs: theme.spacing(3),
              sm: 0,
            },
            borderTopRightRadius: {
              xs: theme.spacing(3),
              sm: 0,
            },
          },
          ...(Array.isArray(PaperProps?.sx) ? PaperProps.sx : [PaperProps?.sx]),
        ],
      }}
      ModalProps={{
        keepMounted: false,
        ...ModalProps,
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};

const DrawerImpl: React.ForwardRefRenderFunction<
  DrawerContextType,
  React.PropsWithChildren<DrawerProps>
> = ({ onClose, onOpen, open, ...props }, ref) => (
  <DrawerProvider onClose={onClose} onOpen={onOpen} open={open} ref={ref}>
    <DrawerInner {...props} />
  </DrawerProvider>
);

const Drawer = forwardRef(DrawerImpl);

export default Drawer;
