import FullCalendar from "@fullcalendar/react";
import { MutableRefObject, useState } from "react";
import { getDateString } from "@/utils/visit";

export default function useCalendarGoToDate(
  calendarRef: MutableRefObject<FullCalendar | null>
) {
  const [currentDate, setCurrentDate] = useState<Date>();

  const goToDate = (date: Date) => {
    if (!calendarRef.current) return;

    calendarRef.current.getApi().gotoDate(getDateString(date));
    setCurrentDate(date);
  };

  return {
    goToDate,
    currentDate,
  };
}
