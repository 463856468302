import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type DeleteAvailabilityEventMutationVariables = Types.Exact<{
  eventId: Types.Scalars["BigInt"]["input"];
}>;

export type DeleteAvailabilityEventMutation = {
  __typename?: "mutation_root";
  removeAvailabilityEvent?: {
    __typename?: "RemoveAvailabilityEvent";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const DeleteAvailabilityEventDocument = gql`
  mutation DeleteAvailabilityEvent($eventId: BigInt!) {
    removeAvailabilityEvent(eventId: $eventId) {
      ok
      message
    }
  }
`;
export type DeleteAvailabilityEventMutationFn = Apollo.MutationFunction<
  DeleteAvailabilityEventMutation,
  DeleteAvailabilityEventMutationVariables
>;

/**
 * __useDeleteAvailabilityEventMutation__
 *
 * To run a mutation, you first call `useDeleteAvailabilityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAvailabilityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAvailabilityEventMutation, { data, loading, error }] = useDeleteAvailabilityEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteAvailabilityEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAvailabilityEventMutation,
    DeleteAvailabilityEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAvailabilityEventMutation,
    DeleteAvailabilityEventMutationVariables
  >(DeleteAvailabilityEventDocument, options);
}
export type DeleteAvailabilityEventMutationHookResult = ReturnType<
  typeof useDeleteAvailabilityEventMutation
>;
export type DeleteAvailabilityEventMutationResult =
  Apollo.MutationResult<DeleteAvailabilityEventMutation>;
export type DeleteAvailabilityEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteAvailabilityEventMutation,
  DeleteAvailabilityEventMutationVariables
>;
