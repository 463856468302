import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type LastPendingGfeSubmissionQueryVariables = Types.Exact<{
  gfeRequestId: Types.Scalars["bigint"]["input"];
  requestedById: Types.Scalars["bigint"]["input"];
}>;

export type LastPendingGfeSubmissionQuery = {
  __typename?: "query_root";
  gfeReviewSubmission: Array<{
    __typename?: "GfeReviewSubmission";
    id: string;
  }>;
};

export const LastPendingGfeSubmissionDocument = gql`
  query LastPendingGfeSubmission(
    $gfeRequestId: bigint!
    $requestedById: bigint!
  ) {
    gfeReviewSubmission(
      where: {
        gfeReviewRequestId: { _eq: $gfeRequestId }
        reviewFinishedAt: { _isNull: true }
        reviewedById: { _eq: $requestedById }
      }
      orderBy: { created: DESC }
      limit: 1
    ) {
      id
    }
  }
`;

/**
 * __useLastPendingGfeSubmissionQuery__
 *
 * To run a query within a React component, call `useLastPendingGfeSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastPendingGfeSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastPendingGfeSubmissionQuery({
 *   variables: {
 *      gfeRequestId: // value for 'gfeRequestId'
 *      requestedById: // value for 'requestedById'
 *   },
 * });
 */
export function useLastPendingGfeSubmissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >(LastPendingGfeSubmissionDocument, options);
}
export function useLastPendingGfeSubmissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >(LastPendingGfeSubmissionDocument, options);
}
export function useLastPendingGfeSubmissionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LastPendingGfeSubmissionQuery,
    LastPendingGfeSubmissionQueryVariables
  >(LastPendingGfeSubmissionDocument, options);
}
export type LastPendingGfeSubmissionQueryHookResult = ReturnType<
  typeof useLastPendingGfeSubmissionQuery
>;
export type LastPendingGfeSubmissionLazyQueryHookResult = ReturnType<
  typeof useLastPendingGfeSubmissionLazyQuery
>;
export type LastPendingGfeSubmissionSuspenseQueryHookResult = ReturnType<
  typeof useLastPendingGfeSubmissionSuspenseQuery
>;
export type LastPendingGfeSubmissionQueryResult = Apollo.QueryResult<
  LastPendingGfeSubmissionQuery,
  LastPendingGfeSubmissionQueryVariables
>;
