import { Chip, useTheme } from "@mui/material";
import { ComponentType } from "react";
import {
  Icon,
  WarningCircleIcon,
  CheckIcon,
  PinpaperCrossIcon,
  CrossIcon,
  ClockIcon,
} from "@/components/common/icons";
import {
  DeprecatedFinalGfeVisitStatus,
  GfeStatus,
  GfeStatusColors,
} from "@/types/gfe";

export const GFE_STATUS_LABEL: Record<GfeStatus, string> = {
  [GfeStatus.INCOMPLETE]: "Client forms incomplete",
  [GfeStatus.CLIENT_COMPLETED]: "Provider action required",
  [GfeStatus.WAITING_FOR_GFE]: "Pending Reviewer",
  [GfeStatus.GFE_APPROVED]: "Approved",
  [GfeStatus.GFE_DECLINED]: "Declined",
  [GfeStatus.EXPIRED]: "Expired",
  [GfeStatus.NOT_NEEDED]: "Not needed",
};

export const GFE_STATUS_ICON: Record<GfeStatus, ComponentType<Icon>> = {
  [GfeStatus.INCOMPLETE]: PinpaperCrossIcon,
  [GfeStatus.CLIENT_COMPLETED]: WarningCircleIcon,
  [GfeStatus.WAITING_FOR_GFE]: ClockIcon,
  [GfeStatus.GFE_APPROVED]: CheckIcon,
  [GfeStatus.GFE_DECLINED]: CrossIcon,
  [GfeStatus.EXPIRED]: CrossIcon,
  [GfeStatus.NOT_NEEDED]: CheckIcon,
};

export const mapDeprecatedToGfeStatus = (
  deprecatedStatus: DeprecatedFinalGfeVisitStatus
) => {
  return {
    [DeprecatedFinalGfeVisitStatus.FORMS_INCOMPLETE]: GfeStatus.INCOMPLETE,
    [DeprecatedFinalGfeVisitStatus.FORMS_COMPLETE]: GfeStatus.CLIENT_COMPLETED,
    [DeprecatedFinalGfeVisitStatus.FORMS_EXPIRED]: GfeStatus.EXPIRED,
    [DeprecatedFinalGfeVisitStatus.PENDING_REVIEWER]: GfeStatus.WAITING_FOR_GFE,
    [DeprecatedFinalGfeVisitStatus.APPROVED]: GfeStatus.GFE_APPROVED,
    [DeprecatedFinalGfeVisitStatus.DECLINED]: GfeStatus.GFE_DECLINED,
    [DeprecatedFinalGfeVisitStatus.NOT_NEEDED]: GfeStatus.NOT_NEEDED,
  }[deprecatedStatus];
};

// TODO: When newGfeFlowV1 is enabled for all, we will stop using GfeStatus, so we should adjust that component to use DeprecatedVisitGfeStatus
// this is an old, deprecated GFE Status (form) component
export default function GfeStatusChip({
  status,
  withGFEPrefix = true,
  withIcon = true,
}: {
  status: GfeStatus;
  withIcon?: boolean;
  withGFEPrefix?: boolean;
}) {
  const theme = useTheme();
  const Icon = GFE_STATUS_ICON[status];
  const color = GfeStatusColors[status];
  const textColor = theme.palette[color]?.contrastText;
  const prefix = withGFEPrefix ? "GFE: " : "";

  return (
    <Chip
      sx={{ minWidth: 0, mt: 0 }}
      icon={
        withIcon ? (
          <Icon size={16} color={textColor} strokeWidth={2} />
        ) : undefined
      }
      label={`${prefix}${GFE_STATUS_LABEL[status]}`}
      color={color}
    ></Chip>
  );
}
