import { Box } from "@mui/material";
import { ReactNode } from "react";

export default function IconContainer({
  size,
  icon,
}: {
  size: string;
  icon: ReactNode;
}) {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon}
    </Box>
  );
}
