import { Button } from "@mui/material";
import { ReactNode } from "react";
import SlideBottomDialog, {
  SlideBottomDialogBaseProps,
} from "@/components/common/modals/slideBottomDialog";

export type FilterDialogBaseProps = SlideBottomDialogBaseProps & {
  isEmpty: boolean;
  onReset?: () => void;
};

type FilterDialogProps = FilterDialogBaseProps & {
  title: string;
  content: ReactNode;
  height?: number | string;
};

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 72;

function FilterDialog({
  content,
  height = "auto",
  isEmpty,
  onReset,
  ...props
}: FilterDialogProps) {
  return (
    <SlideBottomDialog
      PaperProps={{ sx: { height } }}
      headerLeftButton={
        onReset && (
          <Button
            disabled={isEmpty}
            variant="text"
            onClick={onReset}
            size="small"
          >
            Deselect all
          </Button>
        )
      }
      submitButtonText="Show results"
      {...props}
    >
      {content}
    </SlideBottomDialog>
  );
}

export default FilterDialog;
