import { Box } from "@mui/material";
import LoaderIconSvg from "@/components/common/icons/loaderIconSvg";

export default function RotatingLoaderIcon() {
  return (
    <Box
      sx={{
        "@keyframes rotate": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },
        animation: "rotate 2s linear infinite",
      }}
    >
      <LoaderIconSvg />
    </Box>
  );
}
