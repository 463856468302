import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type PendingAdverseReactionsCountSubscriptionVariables = Types.Exact<{
  medspaIds:
    | Array<Types.Scalars["bigint"]["input"]>
    | Types.Scalars["bigint"]["input"];
}>;

export type PendingAdverseReactionsCountSubscription = {
  __typename?: "subscription_root";
  adverseReactionAggregate: {
    __typename?: "AdverseReactionAggregate";
    aggregate?: {
      __typename?: "AdverseReactionAggregateFields";
      count: number;
    } | null;
  };
};

export const PendingAdverseReactionsCountDocument = gql`
  subscription PendingAdverseReactionsCount($medspaIds: [bigint!]!) {
    adverseReactionAggregate(
      where: { medspaId: { _in: $medspaIds }, status: { _eq: "reported" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __usePendingAdverseReactionsCountSubscription__
 *
 * To run a query within a React component, call `usePendingAdverseReactionsCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePendingAdverseReactionsCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingAdverseReactionsCountSubscription({
 *   variables: {
 *      medspaIds: // value for 'medspaIds'
 *   },
 * });
 */
export function usePendingAdverseReactionsCountSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PendingAdverseReactionsCountSubscription,
    PendingAdverseReactionsCountSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    PendingAdverseReactionsCountSubscription,
    PendingAdverseReactionsCountSubscriptionVariables
  >(PendingAdverseReactionsCountDocument, options);
}
export type PendingAdverseReactionsCountSubscriptionHookResult = ReturnType<
  typeof usePendingAdverseReactionsCountSubscription
>;
export type PendingAdverseReactionsCountSubscriptionResult =
  Apollo.SubscriptionResult<PendingAdverseReactionsCountSubscription>;
