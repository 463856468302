import { ApolloError } from "@apollo/client";
import Cookies from "js-cookie";
import { isEqual } from "lodash";
import { floor } from "lodash";
import { basename } from "path";
import { AVATAR_COLORS, CALENDAR_COLORS_MAPPING, HYPHEN } from "@/config";
import { MedspaInfoQuery } from "@/graphql/queries/medspaInfo.graphql.types";
import { FormValidationError, Roles, isNewUnitOfMeasurement } from "@/types";
import { pluralizeUnit } from "./pluralizeUnit";

export function urlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export const defaultMarkIfEmpty = (text: string | undefined) => {
  return text || text?.length ? text : HYPHEN;
};

export const parseUnit = (unit: string, amount: string | number) => {
  if (Math.abs(+amount) === 1) {
    if (unit.toLowerCase() === "syringes") return unit.slice(0, -1);
    if (unit.slice(-2) === "es") return unit.slice(0, -2);
    if (unit.slice(-1) === "s") return unit.slice(0, -1);
  }
  if (isNewUnitOfMeasurement(unit) && Math.abs(+amount) > 1)
    return pluralizeUnit(unit);

  return unit;
};

export const handleApolloError = (
  apolloError: ApolloError
): { error: FormValidationError; message: string } => {
  if (apolloError.graphQLErrors) {
    for (const error of apolloError.graphQLErrors) {
      if (error.extensions?.code === "constraint-violation") {
        return {
          error: FormValidationError.CONSTRAINT_VIOLATION,
          message: error.message,
        };
      }
    }
  }
};

export const getAvatarColor = (clientInfo: { firstName: string }) =>
  AVATAR_COLORS[clientInfo.firstName.charCodeAt(0) % AVATAR_COLORS.length];

export const getUserCalendarColor = ({
  id,
  firstName,
  lastName,
  userIndex,
}: {
  id?: string;
  firstName: string;
  lastName: string;
  userIndex?: number;
}) => {
  // Get a hash of the client's name
  const digest = `${firstName}${lastName}${id ? id : ""}`
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const colorObjectKeys = Object.keys(CALENDAR_COLORS_MAPPING);

  // Get the color array based on the hash
  const colorObjectByDigest =
    CALENDAR_COLORS_MAPPING[digest % colorObjectKeys.length];

  // This is based on the order of the medspa providers in MedspaProviders
  // which is ordered by first name, provider will always have same color
  const colorObjectByIndex =
    userIndex !== undefined
      ? CALENDAR_COLORS_MAPPING[userIndex % colorObjectKeys.length]
      : undefined;

  return { colorObjectByDigest, colorObjectByIndex };
};

export const formattedPrice = (
  price: string | number,
  priceVaries?: boolean
) => {
  if (+price > 0 && priceVaries) return `Price varies (starting at $${price})`;
  if (priceVaries) return "Price varies";

  const priceAsNumber = +price;
  return `$${priceAsNumber.toFixed(2)}`;
};

export const calculateTax = (price: number, tax: number) => {
  if (tax === 0) return "$0";
  const calculatedValue = (price * tax) / 100;
  return `$${calculatedValue.toFixed(2)} (${tax}%)`;
};

export const representsPositiveNumber = (value: string): boolean =>
  !isNaN(+value) && +value >= 0 && isFinite(+value);

export const numberIsGreaterThanZero = (value: string): boolean =>
  !isNaN(+value) && +value > 0 && isFinite(+value);

export const numberIsNatural = (value: string): boolean =>
  representsPositiveNumber(value) && +value === Math.floor(+value);

export const numberIsNaturalGreaterThanZero = (value: string): boolean =>
  numberIsGreaterThanZero(value) && numberIsNatural(value);

export const numberIsPercentValue = (value: string): boolean =>
  numberIsGreaterThanZero(value) && +value <= 100;

const units = {
  kb: 1000,
  mb: 1000 * 1000,
} as const;

export const getFileSize = (file: File, unit: keyof typeof units = "mb") => {
  const sizeInBytes = file.size;
  return +(sizeInBytes / units[unit]).toFixed(2);
};

export const fileToUrl = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      resolve(String(e.target.result));
    };
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

const mimeTypeMap = {
  ".jpg": "image/jpeg",
  ".jpeg": "image/jpeg",
  ".png": "image/png",
  ".gif": "image/gif",
  ".bmp": "image/bmp",
  ".webp": "image/webp",
  ".svg": "image/svg+xml",
} as const;

type MimeType = (typeof mimeTypeMap)[keyof typeof mimeTypeMap];

export const getMimeTypeFromExtension = (extension: string): MimeType | "" =>
  mimeTypeMap[extension.toLowerCase()] || "";

const removeLastUnderscoreSequence = (str: string) =>
  str.replace(/(_[^_]+)(\.[^.]+)$/, "$2");

export const getFileNameFromGS = (fileUrl: string) => {
  const fileName = basename(fileUrl).split("?")[0];
  return removeLastUnderscoreSequence(fileName);
};

export const getAddressString = (
  address: MedspaInfoQuery["medspa"][number]["address"]
) => {
  const { addressLine1, addressLine2, city, state, zipCode } = address || {};
  if (!addressLine1) return "";

  return [addressLine1, addressLine2, city, `${state} ${zipCode}`]
    .filter(Boolean)
    .join(", ");
};

export const getDefaultRoute = (userMedspa: {
  medspa: { id: string };
  role: string;
}) =>
  `/${userMedspa.medspa.id}/${
    // TODO: change review-visits/gfes to review/gfes once new-gfe-flow-v1 is enabled for all
    userMedspa.role === Roles.MEDICAL_DIRECTOR ? "review-visits/gfes" : "visits"
  }`;

export const DEFAULT_CALENDAR_HEADER_HEIGHT = 88;
export const DEFAULT_PAGE_PADDING_BOTTOM = 88;
export const getCalendarHeight = (
  pageHeaderHeight = 0,
  calendarHeaderHeight = DEFAULT_CALENDAR_HEADER_HEIGHT,
  pagePaddingBottom = DEFAULT_PAGE_PADDING_BOTTOM,
  infoBoxHeight = 0
) =>
  `calc(100vh - ${
    pageHeaderHeight + calendarHeaderHeight + pagePaddingBottom + infoBoxHeight
  }px)`;

// example usage: visits.sort(byDateKeyAsc('modified'))
type DateInput = string | number | Date;
export const byDateKeyAsc =
  <T extends string>(sortBy: T) =>
  (a: { [key in T]: DateInput }, b: { [key in T]: DateInput }) =>
    new Date(a[sortBy]).getTime() - new Date(b[sortBy]).getTime();

type Primitive = string | number;

export const isTheSameArray = (
  arr1: Primitive[],
  arr2: Primitive[]
): boolean => {
  if (arr1.length !== arr2.length) return false;

  const sorted1 = [...arr1].sort();
  const sorted2 = [...arr2].sort();

  return isEqual(sorted1, sorted2);
};

export const isObj = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === "object"; // typescript helper for type narrowing

export const isPartOfAString = (searchValue: string, str: string) =>
  str.toLowerCase().includes(searchValue.trim().toLowerCase());

export const roundDown = (number: number, decimals: number): string => {
  const roundedNumber = floor(number, decimals);
  return roundedNumber.toFixed(decimals);
};

export const camelCaseToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const isCardExpired = (expYear: number, expMonth: number): boolean => {
  // Construct the expiration date as the first day of the month *after* the card's expiration month.
  // This way, the card is considered valid through the entire expiration month.
  const expirationDate = new Date(expYear, expMonth, 1);

  // Get the current date with the time set to midnight for a fair comparison
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // The card is expired if the current date is at least the expiration date
  return currentDate >= expirationDate;
};

export const removeUntilNthSlash = (url: string, n: number) => {
  return n >= 0 ? url.split("/").slice(n).join("/") : url;
};

export const formatTimeShort = (timeStr: string) => {
  // 08:30:10 -> 8:30
  const timeWithoutSeconds = timeStr.split(":").slice(0, 2).join(":");
  if (timeWithoutSeconds.startsWith("0")) {
    return timeWithoutSeconds.slice(1);
  }

  return timeWithoutSeconds;
};

export const setCookieWithHoursOfExpiration = (
  name: string,
  value: string,
  hours: number
) => {
  const currentTime = Date.now();
  const expirationInMilliseconds = hours * 60 * 60 * 1000;
  const expirationTime = new Date(currentTime + expirationInMilliseconds);
  Cookies.set(name, value, { expires: expirationTime });
};

// Temporal util to calculate if the booking customization feature is enabled
// This will be removed once the feature is fully enabled
export const calculateBookingCustomizationEnabled = (medspaData) => {
  return (
    medspaData?.configuration?.customizationEnabled ||
    medspaData?.customizationEnabled
  );
};

export function pluralize(word: string, count: number): string {
  return count === 1 ? word : `${word}s`;
}
