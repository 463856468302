import { Box } from "@mui/material";

export default function PreventOpenLink({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      onClick={(e) => {
        // prevent opening another page when dropdown is within a link
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </Box>
  );
}
