import { AuthUser } from "@/auth/useUser";
import {
  HASURA_IMPERSONATED_ID,
  HASURA_IMPERSONATOR_ID,
  HasuraClaimsType,
} from "@/types";

export default function liveSessionIdentify(
  user: AuthUser,
  hasuraClaims: HasuraClaimsType
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ls = (window as any)?.__ls;
  if (!ls) return;

  const isImpersonation = !!hasuraClaims[HASURA_IMPERSONATED_ID];
  const additionalParams = {
    user_id: user.id,
    role: user.role,
    is_impersonation_session: isImpersonation,
    ...(isImpersonation
      ? {
          impersonated_id: hasuraClaims[HASURA_IMPERSONATED_ID],
          impersonator_id: hasuraClaims[HASURA_IMPERSONATOR_ID],
        }
      : {}),
  };
  ls("identify", {
    email: user.role === "client" ? "PATIENT_EMAIL_REDACTED" : user.email,
    params: additionalParams,
  });
}
