// Convert a stripe flow type to camel case:
// example: "card_issuing_authorization_request" -> "cardIssuingAuthorizationRequest"
import { UserQueryResult } from "@/graphql/queries/user.graphql.types";
import { formatCurrency } from "./formatters";

// needed because graphql/graphene formats the flow types as camel case
const camelCaseStripeFlowType = (flowType: string) => {
  return flowType
    .split("_")
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

// Stripe amounts are in cents, so divide by 100 to get dollars
// If negative, add a negative sign in front with dollar sign after negative
const formatStripeAmount = (stripeAmountCents: number, currency?: string) => {
  const amount = stripeAmountCents / 100;

  return formatCurrency({ amount, currency });
};

// Reusable util function to get account and financial account ids from userMedspas (useUser() hook)
const getStripeIdsFromUserMedspa = (
  userMedspas: UserQueryResult["data"]["userMedspa"],
  medspaId: string
) => {
  if (!userMedspas || !medspaId) return;

  const fullMedspa = userMedspas.find(
    (userMedspa) => userMedspa.medspa.id === medspaId
  );

  if (!fullMedspa) {
    // this should never happen
    console.error("Medspa not found");
    return;
  }

  const { stripeAccountId, stripeFinancialAccountId } = fullMedspa.medspa;

  return { stripeAccountId, stripeFinancialAccountId };
};

// format credit card number with spaces every 4 digits
// example: "1234567890123456" -> "1234 5678 9012 3456"
const formatCreditCardNumber = (cardNumber: string) => {
  const formattedCardNumber = cardNumber
    .split("")
    .map((char, index) => {
      if (index % 4 === 0 && index !== 0) {
        return ` ${char}`;
      }
      return char;
    })
    .join("");

  return formattedCardNumber;
};

const formatSpendingLimit = (spendingLimit) => {
  const formatedLimit = spendingLimit
    .map(
      (limit) =>
        `${formatStripeAmount(limit.amount, "USD")} ${limit.interval.replace(
          "_",
          " "
        )}`
    )
    .join(", ");

  return formatedLimit;
};

export {
  camelCaseStripeFlowType,
  formatStripeAmount,
  getStripeIdsFromUserMedspa,
  formatCreditCardNumber,
  formatSpendingLimit,
};
