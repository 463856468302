import {
  AMBER,
  BLOODY,
  BLUE,
  CYAN,
  DARK_PURPLE,
  DEEP_ORANGE,
  GOLD,
  GREEN,
  INDIGO,
  LIGHT_BLUE,
  MINT,
  OCEAN_BLUE,
  PINK,
  PURPLE,
  SALMON,
  TEAL,
  YELLOW,
} from "@/config/mui/colorPalette";

export type EventColorPalette = {
  [100]: string;
  [80]: string;
  [40]: string;
  [20]: string;
};

type CalendarColorsMapping = {
  [key: number]: EventColorPalette;
};
export const CALENDAR_COLORS_MAPPING: CalendarColorsMapping = {
  0: BLUE,
  1: SALMON,
  2: MINT,
  3: YELLOW,
  4: GREEN,
  5: PINK,
  6: OCEAN_BLUE,
  7: PURPLE,
  8: CYAN,
  9: BLOODY,
  10: TEAL,
  11: AMBER,
  12: DARK_PURPLE,
  13: GOLD,
  14: LIGHT_BLUE,
  15: DEEP_ORANGE,
  16: INDIGO,
} as const;
