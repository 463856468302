export const isValidSignature = (pointGroups: SignaturePad.Point[][]) => {
  // Signatures are created by grouping strokes into groups
  // One group constitutes an uninterrupted stroke of points

  // If just one stroke, make it long enough to be meaningful
  if (pointGroups.length === 1) {
    return pointGroups.some((group) => group.length > 15);
  }

  // If multiple strokes, at least one group should be long enough
  return pointGroups.some((group) => group.length >= 10);
};
