import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Dialog, Typography } from "@mui/material";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  default as ReactSignatureCanvas,
  default as SignaturePad,
} from "react-signature-canvas";
import AdditionalCheckbox from "@/components/common/modals/additionalCheckbox";
import SlideUpTransition from "@/components/common/modals/slideUpTransition";
import PageHeader from "@/components/common/pageHeader/pageHeader";
import useAction from "@/hooks/misc/useAction";
import { urlToFile } from "@/utils";
import { isValidSignature } from "@/utils/signature";

type AdditionalConfirmation = {
  label: string;
  defaultValue: boolean;
};
type Props = {
  open: boolean;
  title?: string;
  buttonText?: string;
  displayName?: string;
  additionalConfirmation?: AdditionalConfirmation;
  onClose: () => void;
  onSignSubmit: (signature: File) => Promise<unknown>;
};

// Beware - This component is shared between consent forms, intake forms, memberships and gfe form
export default function SignatureDialog({
  open,
  title = "Accept & Sign",
  buttonText = "Submit Signature",
  displayName,
  additionalConfirmation,
  onClose,
  onSignSubmit,
}: Props) {
  const [checkboxValue, setCheckboxValue] = useState<boolean>(
    additionalConfirmation?.defaultValue || false
  );

  const padRef = useRef<ReactSignatureCanvas>(null);

  const handleClearSignature = () => {
    padRef.current?.clear();
  };

  const handleSubmitSignature = async () => {
    const pointGroups = padRef.current?.toData();
    if (padRef.current?.isEmpty() || !isValidSignature(pointGroups)) {
      toast.error("Your signature is unreadable or invalid. Please try again");
      return;
    }

    const data = padRef.current?.getTrimmedCanvas().toDataURL();

    const signatureFile = await urlToFile(data, "signature.png", "image/png");

    return onSignSubmit(signatureFile);
  };

  const { handleAction, isLoading } = useAction(handleSubmitSignature);

  return (
    <Dialog fullScreen open={open} TransitionComponent={SlideUpTransition}>
      <Container
        sx={{
          pb: "10px",
          bgcolor: "background.paper",
          maxHeight: "-webkit-fill-available",
        }}
      >
        <PageHeader
          title={title}
          goBackStrategy="onClick"
          goBackOnClick={onClose}
        />
        <Box sx={{ width: "100%", height: "45vh", position: "relative" }}>
          <SignaturePad ref={padRef} canvasProps={{ className: "sigCanvas" }} />
        </Box>
        <Button
          size="small"
          aria-label="Clear signature"
          sx={{ alignSelf: "end", mt: 1, mb: 0.5, pl: 0 }}
          onClick={handleClearSignature}
        >
          Clear Signature
        </Button>
        {displayName && <Typography>{displayName}</Typography>}
        {additionalConfirmation && (
          <AdditionalCheckbox
            withDivider={false}
            value={checkboxValue}
            label={additionalConfirmation.label}
            fieldName="additional-confirmation"
            setValue={setCheckboxValue}
            sx={{ mt: 1 }}
          />
        )}
        <LoadingButton
          aria-label="Submit"
          size="large"
          variant="contained"
          fullWidth
          onClick={handleAction}
          loading={isLoading}
          sx={{ display: "flex", mt: 3 }}
          disabled={additionalConfirmation && !checkboxValue}
        >
          {buttonText}
        </LoadingButton>
      </Container>
    </Dialog>
  );
}
