import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaMedicalDirectorsQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type MedspaMedicalDirectorsQuery = {
  __typename?: "query_root";
  medspaByPk?: {
    __typename?: "medspa";
    id: string;
    userMedspas: Array<{
      __typename?: "userMedspa";
      id: string;
      user: {
        __typename?: "user";
        id: string;
        phone: string;
        firstName: string;
        lastName: string;
        fullName?: string | null;
      };
    }>;
  } | null;
};

export const MedspaMedicalDirectorsDocument = gql`
  query MedspaMedicalDirectors($id: bigint!) {
    medspaByPk(id: $id) {
      id
      userMedspas(
        where: {
          active: { _eq: true }
          role: { _eq: "medical_director" }
          isGfeReviewer: { _eq: false }
        }
        orderBy: { user: { firstName: ASC } }
      ) {
        id
        user {
          id
          phone
          firstName
          lastName
          fullName
        }
      }
    }
  }
`;

/**
 * __useMedspaMedicalDirectorsQuery__
 *
 * To run a query within a React component, call `useMedspaMedicalDirectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaMedicalDirectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaMedicalDirectorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedspaMedicalDirectorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >(MedspaMedicalDirectorsDocument, options);
}
export function useMedspaMedicalDirectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >(MedspaMedicalDirectorsDocument, options);
}
export function useMedspaMedicalDirectorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaMedicalDirectorsQuery,
    MedspaMedicalDirectorsQueryVariables
  >(MedspaMedicalDirectorsDocument, options);
}
export type MedspaMedicalDirectorsQueryHookResult = ReturnType<
  typeof useMedspaMedicalDirectorsQuery
>;
export type MedspaMedicalDirectorsLazyQueryHookResult = ReturnType<
  typeof useMedspaMedicalDirectorsLazyQuery
>;
export type MedspaMedicalDirectorsSuspenseQueryHookResult = ReturnType<
  typeof useMedspaMedicalDirectorsSuspenseQuery
>;
export type MedspaMedicalDirectorsQueryResult = Apollo.QueryResult<
  MedspaMedicalDirectorsQuery,
  MedspaMedicalDirectorsQueryVariables
>;
