import { useRef } from "react";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useRecordLongLoading(onLongLoading?: () => void) {
  const logError = useErrorLogger();
  const longLoadTimeout = useRef<NodeJS.Timeout | undefined>();

  const registerStartLoading = () => {
    clearTimeout(longLoadTimeout.current);

    longLoadTimeout.current = setTimeout(() => {
      logError(
        new Error(
          `Twilio client load time exceeded ${TWILIO_LONG_LOAD_TIMEOUT_SECONDS} seconds`
        )
      );
      onLongLoading?.();

      longLoadTimeout.current = setTimeout(
        () => {
          logError(
            new Error(
              `Twilio client load time exceeded ${TWILIO_ABSURDLY_LONG_LOAD_TIMEOUT_SECONDS} seconds`
            )
          );
        },
        (TWILIO_ABSURDLY_LONG_LOAD_TIMEOUT_SECONDS -
          TWILIO_LONG_LOAD_TIMEOUT_SECONDS) *
          1000
      );
    }, TWILIO_LONG_LOAD_TIMEOUT_SECONDS * 1000);
  };

  const registerFinishLoading = () => {
    clearTimeout(longLoadTimeout.current);
  };

  return {
    registerStartLoading,
    registerFinishLoading,
  };
}

const TWILIO_LONG_LOAD_TIMEOUT_SECONDS = 20;
const TWILIO_ABSURDLY_LONG_LOAD_TIMEOUT_SECONDS = 60;
