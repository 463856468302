import { GetToken } from "@clerk/types";
import { PhotoType } from "@/components/serviceFlow/visitDetails/photos/addPhotoButton";
import { EditMetadataType } from "@/hooks/media/photos/usePhotoUpdate";
import { getClientAuthorizationHeader, postRequest, putRequest } from "./fetch";

export type UploadPhotoResponseData = {
  id: number;
  photo: string;
  edit_metadata: string;
  label: PhotoType;
};

export const getPresignedUrl = async (
  filename: string,
  visitId: string,
  getToken: GetToken,
  additionalData: { label?: PhotoType; photo_id?: string }
) => {
  const url = `${process.env.NEXT_PUBLIC_DJANGO_URI}/visits/generate_upload_url/`;
  const response = await postRequest<{
    upload_url: string;
    photo_id: number;
    blob_name: string;
  }>(
    url,
    {
      filename,
      visit_id: visitId,
      ...additionalData,
    },
    {
      withAuth: true,
      getToken,
    }
  );

  const genericErrorMsg = "Generating signed url failed";
  if (!response) {
    throw Error(genericErrorMsg);
  }
  if (!response.upload_url) {
    throw Error(genericErrorMsg + " - no upload url");
  }
  if (!response.photo_id) {
    throw Error(genericErrorMsg + " - no photo id");
  }

  return response;
};

export const getPresignedFormsUrl = async (
  filename: string,
  getToken: GetToken,
  clientAccessToken?: string
) => {
  const url = `${process.env.NEXT_PUBLIC_DJANGO_URI}/forms/signed_urls/generate_upload_url/`;
  const isClient = !!clientAccessToken;
  const response = await postRequest<{
    upload_url: string;
    blob_name: string;
  }>(
    url,
    {
      filename,
      image_type: "intake_form_photos",
    },
    {
      withAuth: !isClient,
      getToken,
      headers: {
        ...(isClient ? getClientAuthorizationHeader(clientAccessToken) : {}),
      },
    }
  );

  const genericErrorMsg = "Generating signed url failed";
  if (!response) {
    throw Error(genericErrorMsg);
  }
  if (!response.upload_url) {
    throw Error(genericErrorMsg + " - no upload url");
  }

  return response;
};

export const getPresignedSignatureUploadUrl = async (
  filename: string,
  uploadTo:
    | "intake_form_signatures"
    | "client_membership_signatures"
    | "gfe_review_submission_signatures"
    | "chart_signatures"
    | "documents"
    | "medspas/logos",
  getToken: GetToken,
  clientAccessToken?: string
) => {
  const url = `${process.env.NEXT_PUBLIC_DJANGO_URI}/utils/signed_urls/generate_signature_upload_url/`;
  const isClient = !!clientAccessToken;
  const response = await postRequest<{
    upload_url: string;
    blob_name: string;
  }>(
    url,
    {
      filename,
      upload_to: uploadTo,
    },
    {
      withAuth: !isClient,
      getToken,
      headers: {
        ...(isClient ? getClientAuthorizationHeader(clientAccessToken) : {}),
      },
    }
  );

  const genericErrorMsg = "Generating signed url failed";
  if (!response) {
    throw Error(genericErrorMsg);
  }
  if (!response.upload_url) {
    throw Error(genericErrorMsg + " - no upload url");
  }

  return response;
};

export const uploadFileToPresignedUrl = async (
  presignedUrl: string,
  file: File,
  headers?: Record<string, string>
) =>
  putRequest(presignedUrl, file, {
    headers: {
      "Content-Type": "application/octet-stream",
      ...headers,
    },
    isAuth: false,
  });

export const notifyPresignedUrlUploaded = async (
  photoId: number,
  blobName: string,
  getToken: GetToken,
  additionalData: { edit_metadata?: EditMetadataType } = {}
) => {
  const url = `${process.env.NEXT_PUBLIC_DJANGO_URI}/visits/upload_complete/`;
  const response = await postRequest<UploadPhotoResponseData>(
    url,
    {
      photo_id: photoId,
      blob_name: blobName,
      ...additionalData,
    },
    {
      withAuth: true,
      getToken,
    }
  );

  if (!response) {
    throw Error("Notifying that upload is complete failed");
  }

  return response;
};

export const MAX_LOGO_SIZE = 3; // MB
