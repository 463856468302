import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Divider } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import type { ExtendButtonBase } from "@mui/material/ButtonBase";
import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import Menu, { type MenuProps } from "@mui/material/Menu";
import MenuItem, {
  type MenuItemProps,
  type MenuItemTypeMap,
} from "@mui/material/MenuItem";
import type { PaperProps } from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useTransition,
} from "react";
import PreventOpenLink from "@/components/common/preventOpenLink/preventOpenLink";

const AnimatedExpandMoreRoundedIcon = styled(ExpandMoreRoundedIcon, {
  shouldForwardProp: (prop) => prop !== "opened",
})<{ opened?: boolean }>(({ opened, theme }) => {
  return {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.sharp,
    }),
    transform: `rotate(${opened ? 180 : 0}deg)`,
  };
});

type MenuItemChange<Choice> = (choice: Choice | null) => void;

type InferChoicesType<T> = T extends readonly (infer U)[]
  ? U
  : // eslint-disable-next-line @typescript-eslint/no-explicit-any -- safe to use "any" for this inference
    T extends Record<any, unknown>
    ? T[keyof T]
    : T;

type ExtendedMenuItem<Choice> = ExtendButtonBase<
  MenuItemTypeMap<{ value: Choice }>
>;

export type DropdownMenuItem<Choices = unknown> = {
  component: React.ReactNode;
  disabled?: boolean;
  divider?: boolean;
  onClick?: () => void;
} & (Choices extends NonNullable<object>
  ? { value: InferChoicesType<Choices> }
  : { value?: never });

export type DropdownMenuItems<Choices = unknown> = DropdownMenuItem<Choices>[];

type DropdownMenuTextButtonProps = {
  buttonVariant?: "text";
  buttonText: string;
  leadingIcon?: React.ReactNode;
  icon?: never;
  ButtonProps?: Partial<ButtonProps>;
};

type DropdownMenuIconButtonProps = {
  buttonVariant?: "icon";
  buttonText?: never;
  leadingIcon?: never;
  icon?: React.ReactNode;
  ButtonProps?: Partial<IconButtonProps>;
};

export type DropdownMenuButtonProps =
  | DropdownMenuTextButtonProps
  | DropdownMenuIconButtonProps;

export type DropdownMenuProps<Choices = Array<unknown>> = {
  //* for type discrimination
  type: Choices;
  MenuProps?: Partial<
    Omit<MenuProps, "slots"> & { slots?: Omit<MenuProps["slots"], "paper"> }
  >;
  MenuPaperProps?: Partial<PaperProps>;
  disabled?: boolean;
  divider?: boolean;
  disableAutoClose?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  onMenuItemChange?: MenuItemChange<InferChoicesType<Choices>>;
} & DropdownMenuButtonProps &
  (
    | {
        children: (
          menuItemConstructor: ExtendedMenuItem<InferChoicesType<Choices>>,
          defaultItemProps: MenuItemProps,
          onMenuItemChange: MenuItemChange<InferChoicesType<Choices>>
        ) => React.ReactNode[];
        menuItems?: never;
      }
    | {
        children?: never;
        menuItems: DropdownMenuItems<Choices>;
      }
  );

export type DropdownMenuRef<Choice> = {
  openMenu: () => void;
  closeMenu: () => void;
  resetMenu: () => void;
  choice: React.MutableRefObject<InferChoicesType<Choice>>;
};

function DropdownMenuImpl<Choices>(
  {
    children,
    menuItems,
    buttonVariant = "text",
    onMenuClose,
    onMenuOpen,
    onMenuItemChange,
    disabled = false,
    disableAutoClose = false,
    divider,
    ...props
  }: DropdownMenuProps<Choices>,
  ref?: React.Ref<DropdownMenuRef<Choices>>
): React.ReactElement<React.PropsWithRef<DropdownMenuProps<Choices>>> {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const choice = useRef<InferChoicesType<Choices> | null>(null);
  const [, startTransition] = useTransition();

  const opened = Boolean(anchorEl) && !disabled;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      startTransition(() => {
        setAnchorEl(event.currentTarget);
        onMenuOpen?.();
      });
    },
    [setAnchorEl, onMenuOpen, startTransition]
  );

  const handleOpen = useCallback(() => {
    if (buttonRef.current && !anchorEl && !disabled) {
      buttonRef.current.click();
      onMenuOpen?.();
    }
  }, [onMenuOpen, anchorEl, disabled]);

  const handleClose = useCallback(() => {
    startTransition(() => {
      setAnchorEl((prev) => {
        if (prev) {
          onMenuClose?.();
        }
        return null;
      });
    });
  }, [setAnchorEl, onMenuClose, startTransition]);

  const handleMenuItemChange = useCallback(
    (currentChoice: InferChoicesType<Choices> | null) => {
      if (currentChoice !== choice.current) {
        choice.current = currentChoice;

        onMenuItemChange?.(choice.current);
      }
      handleClose();
    },
    [handleClose, onMenuItemChange]
  );

  const resetMenu = useCallback(
    () => handleMenuItemChange(null),
    [handleMenuItemChange]
  );

  useImperativeHandle(
    ref,
    () => ({
      openMenu: handleOpen,
      closeMenu: handleClose,
      resetMenu,
      choice,
    }),
    [handleClose, handleOpen, resetMenu]
  );

  const ExtendedMenuItem = useCallback<
    ExtendedMenuItem<InferChoicesType<Choices>>
  >(
    ({ value, ...props }) => (
      <MenuItem
        divider={divider}
        selected={choice.current === value}
        onClick={() => handleMenuItemChange(value)}
        {...props}
      />
    ),
    [handleMenuItemChange, divider]
  );

  const menuRenderItems = useMemo(
    () =>
      children != null
        ? children(ExtendedMenuItem, {}, handleMenuItemChange)
        : menuItems.map<React.ReactNode>(
            ({ value, component, divider, onClick, ...props }, index) => (
              <React.Fragment key={`${value ?? index}`}>
                <ExtendedMenuItem
                  value={value}
                  onClick={() => {
                    if (!disableAutoClose) {
                      handleClose();
                    }
                    onClick();
                  }}
                  {...props}
                >
                  {component}
                </ExtendedMenuItem>
                {divider && <Divider sx={{ mx: 0.5 }} />}
              </React.Fragment>
            )
          ),
    [
      ExtendedMenuItem,
      handleMenuItemChange,
      children,
      menuItems,
      handleClose,
      disableAutoClose,
    ]
  );

  return (
    <PreventOpenLink>
      {buttonVariant === "text" ? (
        <Button
          disabled={disabled}
          ref={buttonRef}
          aria-controls={opened ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={opened ? "true" : undefined}
          onContextMenu={handleClick}
          onClick={handleClick}
          startIcon={props.leadingIcon}
          variant="contained"
          endIcon={
            <AnimatedExpandMoreRoundedIcon height={24} opened={opened} />
          }
          {...(props.ButtonProps as ButtonProps)}
        >
          {props.buttonText}
        </Button>
      ) : (
        <IconButton
          disabled={disabled}
          ref={buttonRef}
          aria-controls={opened ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={opened ? "true" : undefined}
          onContextMenu={handleClick}
          onClick={handleClick}
          {...(props.ButtonProps as IconButtonProps)}
        >
          {props.icon ?? (
            <AnimatedExpandMoreRoundedIcon height={24} opened={opened} />
          )}
        </IconButton>
      )}
      <Menu
        slotProps={{
          paper: {
            sx: [
              { mt: 1, borderRadius: 4, boxShadow: shadows[8] },
              ...(Array.isArray(props.MenuPaperProps?.sx)
                ? props.MenuPaperProps.sx
                : [props.MenuPaperProps?.sx]),
            ],
            ...props?.MenuPaperProps,
          },
        }}
        anchorEl={anchorEl}
        open={opened}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        {...props.MenuProps}
      >
        {...menuRenderItems}
      </Menu>
    </PreventOpenLink>
  );
}

const DropdownMenu = forwardRef(DropdownMenuImpl) as <Choices>(
  props: DropdownMenuProps<Choices> &
    React.RefAttributes<DropdownMenuRef<Choices>>
) => React.ReactElement<DropdownMenuProps<Choices>>;

export default DropdownMenu;
