import { Box, Button } from "@mui/material";
import LoadingButton from "@/components/common/buttons/loadingButton";

interface FormActionsProps {
  onCancel: () => void;
  onSubmit: () => void;
  isCreateButtonEnabled: boolean;
  isLoading: boolean;
}

export function FormActions({
  onCancel,
  onSubmit,
  isCreateButtonEnabled,
  isLoading,
}: FormActionsProps) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
      }}
    >
      <Button
        sx={{
          height: {
            xs: "56px",
            md: "40px",
          },
        }}
        variant="outlined"
        fullWidth
        onClick={onCancel}
      >
        Cancel
      </Button>
      <LoadingButton
        sx={{
          height: {
            xs: "56px",
            md: "40px",
          },
        }}
        variant="contained"
        fullWidth
        onClick={onSubmit}
        disabled={!isCreateButtonEnabled}
        loading={isLoading}
      >
        Create meeting
      </LoadingButton>
    </Box>
  );
}
