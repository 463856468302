import {
  Collapse,
  IconButton,
  List,
  ListItem,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { Fragment, ReactNode } from "react";
import ToggleIcon from "@/components/common/icons/toggleIcon";
import { GREY } from "@/config/mui/colorPalette";
import { useExpandedState } from "@/hooks/common/useExpandedState";

type ControlledAccordionItem = {
  expanded?: boolean;
  onExpand?: (id: string) => void;
};

export type AccordionItem = ControlledAccordionItem & {
  id: string;
  parentNode: ReactNode;
  childNode: ReactNode;
};

type ListAccordionProps = {
  items: AccordionItem[];
  withParentDivider?: boolean;
  defaultExpanded?: boolean;
  isCollapsable?: boolean;
  customIcon?: {
    expanded: ReactNode;
    collapsed: ReactNode;
  };
};

function ListAccordion({
  items,
  withParentDivider,
  defaultExpanded,
  isCollapsable = true,
  customIcon,
}: ListAccordionProps) {
  const { itemExpanded, onItemExpand } = useExpandedState(
    items,
    defaultExpanded
  );

  return (
    <List component="nav" sx={{ py: 0 }}>
      {items.map(({ id, parentNode, childNode, expanded, onExpand }) => {
        const isExpanded = expanded !== undefined ? expanded : itemExpanded[id];

        const handleExpand = () => {
          if (onExpand) {
            onExpand(id);
            return;
          }

          onItemExpand(id);
        };

        return (
          <Fragment key={id}>
            <ListItem
              component="div"
              disablePadding
              secondaryAction={
                isCollapsable && (
                  <IconButton
                    edge="end"
                    aria-label="expand"
                    onClick={handleExpand}
                  >
                    {customIcon ? (
                      isExpanded ? (
                        customIcon.expanded
                      ) : (
                        customIcon.collapsed
                      )
                    ) : (
                      <ToggleIcon isToggled={isExpanded} />
                    )}
                  </IconButton>
                )
              }
              sx={{
                minHeight: "40px",
                alignItems: "center",
                pb: withParentDivider && isExpanded ? "1px" : "0",
              }}
              divider={withParentDivider && !isExpanded}
            >
              {parentNode}
            </ListItem>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ pb: 1 }}>{childNode}</Box>
              {withParentDivider && <Divider sx={{ mt: 0.5 }} />}
            </Collapse>
          </Fragment>
        );
      })}
    </List>
  );
}

export function DefaultParentNodeTitle({ children }: { children: ReactNode }) {
  return (
    <Typography variant="subtitleSmall" sx={{ color: GREY[70] }}>
      {children}
    </Typography>
  );
}

export default ListAccordion;
