import { useEffect } from "react";

// https://github.com/mui/material-ui/issues/5750 temporary solution to fix background scrolling, which is not disabled as it should be in iOS
export default function useMuiMenuDisableScrolling() {
  useEffect(() => {
    if (globalThis?.document) {
      const body = globalThis.document.body;

      const observer = new MutationObserver(() => {
        body.style.touchAction = body.style.overflow === "hidden" ? "none" : "";
      });

      observer.observe(body, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, []);
}
