import { BusinessHoursInput } from "@fullcalendar/core";
import { DateRange } from "@fullcalendar/core/internal";
import FullCalendar from "@fullcalendar/react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMinutes, isEqual, isValid } from "date-fns";
import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useMemo,
  useRef,
} from "react";
import { Clock } from "react-swm-icon-pack";
import { interactiveCalendarProps } from "@/components/common/calendar/interactiveCalendarProps";
import MoxieFullCalendar from "@/components/common/calendar/moxieFullCalendar";
import TimePicker from "@/components/common/datetimePicker/TimePicker";
import InfoCard from "@/components/common/infoCard/infoCard";
import { GREY } from "@/config/mui/colorPalette";
import useCalendarGoToDate from "@/hooks/calendar/useCalendarGoToDate";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";
import useDatesValidation from "@/hooks/misc/useDatesValidation";
import useResizeObserver from "@/hooks/misc/useResizeObserver";
import {
  DEFAULT_CALENDAR_HEADER_HEIGHT,
  DEFAULT_PAGE_PADDING_BOTTOM,
  getCalendarHeight,
} from "@/utils";
import { useTimeSelectorContext } from "./timeSelectorContext";

type Props = {
  events: Array<{
    title: string;
    display?: string;
    start: string;
    end: string;
  }>;
  businessHours?: BusinessHoursInput;
  currentDate?: Date;
  defaultDurationInMinutes?: number;
  onSetStartDate?: React.Dispatch<Date>;
  onSetEndDate?: React.Dispatch<Date>;
};

export type TimeSelectorRef = {
  readonly dateRange: Partial<Readonly<DateRange>>;
  cancel: VoidFunction;
  confirm: VoidFunction;
  setStart: React.Dispatch<Date>;
  setEnd: React.Dispatch<Date>;
};

const TimeSelector: React.ForwardRefRenderFunction<TimeSelectorRef, Props> = (
  {
    events,
    businessHours,
    currentDate = new Date(),
    defaultDurationInMinutes = 5,
    onSetStartDate,
    onSetEndDate,
  },
  ref
) => {
  const theme = useTheme();
  const { closeSelector, setEnd, setStart, dateRange } =
    useTimeSelectorContext();

  const [startDate, setStartDate] = useState<Date>(
    dateRange?.start ?? currentDate
  );
  const [endDate, setEndDate] = useState<Date>(
    dateRange?.end ?? addMinutes(startDate, defaultDurationInMinutes)
  );

  const handleCancel = useCallback(() => {
    setStartDate(currentDate);
    setEndDate(addMinutes(currentDate, defaultDurationInMinutes));
    closeSelector();
  }, [
    closeSelector,
    setStartDate,
    setEndDate,
    currentDate,
    defaultDurationInMinutes,
  ]);

  const handleConfirm = useCallback(() => {
    if (!startDate || !endDate) return;
    setStart(startDate);
    setEnd(endDate);
    closeSelector();
  }, [closeSelector, startDate, endDate, setStart, setEnd]);

  const internalDateRange = useMemo(
    () => ({ start: startDate, end: endDate }),
    [startDate, endDate]
  );

  const handleStartDateChange = useCallback(
    (date: Date) => {
      setStartDate(date);
      onSetStartDate?.(date);
    },
    [onSetStartDate, setStartDate]
  );

  const handleEndDateChange = useCallback(
    (date: Date) => {
      setEndDate(date);
      onSetEndDate?.(date);
    },
    [onSetEndDate, setEndDate]
  );

  useImperativeHandle(
    ref,
    () => ({
      dateRange: internalDateRange,
      cancel: handleCancel,
      confirm: handleConfirm,
      setStart: handleStartDateChange,
      setEnd: handleEndDateChange,
    }),
    [
      handleCancel,
      handleConfirm,
      internalDateRange,
      handleStartDateChange,
      handleEndDateChange,
    ]
  );

  const calendarRef = useRef<FullCalendar | null>(null);
  const infoBoxRef = useRef<HTMLDivElement | null>(null);
  const [infoBoxHeight, setInfoBoxHeight] = useState(0);
  const { medspaReadableTimezone } = useMedspaTimezone();
  const { goToDate } = useCalendarGoToDate(calendarRef);

  useResizeObserver(infoBoxRef, ({ height }) => setInfoBoxHeight(height + 40));

  useDatesValidation(
    startDate,
    endDate,
    handleEndDateChange,
    defaultDurationInMinutes
  );

  const handleCalendarGoToDate = (date: Date) => {
    goToDate(date);
    const updatedDate = calendarRef.current?.getApi().getDate();

    if (!updatedDate) return;

    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    handleStartDateChange(updatedDate);
    if (endDate) {
      const endHours = endDate.getHours();
      const endMinutes = endDate.getMinutes();
      const endUpdatedDate = new Date(updatedDate);
      endUpdatedDate.setHours(endHours);
      endUpdatedDate.setMinutes(endMinutes);
      handleEndDateChange(endUpdatedDate);
    }
  };

  return (
    <Box sx={{ overflow: "auto" }}>
      <InfoCard
        sx={{
          mb: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2}>
            <TimePicker
              slotProps={{
                textField: {
                  InputProps: {
                    startAdornment: (
                      <Clock
                        size={24}
                        color={GREY[60]}
                        style={{ marginRight: theme.spacing(1) }}
                      />
                    ),
                  },
                },
              }}
              value={startDate}
              label="Start time"
              onAccept={(value) => {
                const date = value as Date;

                if (isValid(date)) {
                  handleStartDateChange(date);
                }
              }}
            />
            <TimePicker
              slotProps={{
                textField: {
                  InputProps: {
                    startAdornment: (
                      <Clock
                        size={24}
                        color={GREY[60]}
                        style={{ marginRight: theme.spacing(1) }}
                      />
                    ),
                  },
                },
              }}
              value={endDate}
              label="End time"
              onAccept={(value) => {
                const date = value as Date;

                if (isValid(date) && !isEqual(date, endDate)) {
                  handleEndDateChange(date);
                }
              }}
              minTime={addMinutes(startDate, 1)}
            />
          </Stack>
        </LocalizationProvider>
      </InfoCard>
      <Typography my={1} variant="labelTiny">
        Please note: You operate in <strong>{medspaReadableTimezone}</strong>{" "}
        time.
      </Typography>
      <InfoCard sx={{ mt: 2 }}>
        <MoxieFullCalendar
          calendarRef={calendarRef}
          handleCalendarGoToDate={handleCalendarGoToDate}
          height={getCalendarHeight(
            68,
            DEFAULT_CALENDAR_HEADER_HEIGHT,
            DEFAULT_PAGE_PADDING_BOTTOM,
            infoBoxHeight
          )}
          initDate={startDate}
          initialView="timeGridDay"
          {...interactiveCalendarProps}
          editable={false}
          events={events}
          eventChange={(info) => {
            const { start, end } = info.event;
            if (start && end) {
              handleStartDateChange(start);
              handleEndDateChange(end);
            }
          }}
          businessHours={businessHours}
        />
      </InfoCard>
    </Box>
  );
};

export default forwardRef(TimeSelector);
