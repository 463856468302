import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useUser } from "@/auth/useUser";
import { useTimeSelectorContext } from "@/components/booking/timeSelector/timeSelectorContext";
import { useCreateMdMeetingDrawer } from "@/contexts/createMdMeetingDrawerContext";
import { useCreateMdMeetingMutation } from "@/graphql/mutations/createMdMeeting.graphql.types";
import { useMedspaMedicalDirectors } from "@/hooks/common/useMedspaMedicalDirectors";
import { useMedspaProviders } from "@/hooks/common/useMedspaProviders";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

type Attendee = { id: string; label: string };

type CreateMDMeetingForm = {
  meetingDetails?: string;
  providers: Attendee[];
  mds: Attendee[];
};

const schema = yup.object().shape({
  meetingDetails: yup.string(),
  providers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one provider must attend this meeting"),
  mds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1, "At least one MD must attend this meeting"),
});

export function useCreateMdMeetingForm() {
  const { userMedspa } = useUser();
  const logError = useErrorLogger();
  const { medspaProviders } = useMedspaProviders();
  const { isOpen, closeDrawer } = useCreateMdMeetingDrawer();
  const { medspaMedicalDirectors } = useMedspaMedicalDirectors();
  const { isOpen: isTimeSelectorOpen, dateRange } = useTimeSelectorContext();

  const [createMdMeetingMutation, { loading: isLoading }] =
    useCreateMdMeetingMutation({
      refetchQueries: ["MdMeetingsByMedspa"],
    });

  const [hasSetEndTime, setHasSetEndTime] = useState(false);

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateMDMeetingForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      meetingDetails: "",
      providers: [],
      mds: [],
    },
    mode: "onChange",
  });

  const mds = watch("mds");
  const providers = watch("providers");

  useEffect(() => {
    if (isOpen) {
      reset({
        meetingDetails: "",
        providers: [],
        mds: [],
      });
    }
  }, [isOpen, reset]);

  const providerOptions = useMemo(() => {
    return medspaProviders?.map((provider) => ({
      id: provider.id,
      label: provider.user.fullName,
    }));
  }, [medspaProviders]);

  const mdOptions = useMemo(() => {
    return medspaMedicalDirectors?.map((md) => ({
      id: md.id,
      label: md.user.fullName,
    }));
  }, [medspaMedicalDirectors]);

  const isCreateButtonEnabled = Boolean(
    isValid &&
      mds.length > 0 &&
      providers.length > 0 &&
      dateRange?.start &&
      dateRange?.end
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!dateRange?.start || !dateRange?.end || !userMedspa) return;

    const attendeesIds = [
      ...data.providers.map((provider) => parseInt(provider.id)),
      ...data.mds.map((md) => parseInt(md.id)),
    ];

    const toastId = toast.loading("Creating MD meeting...");
    try {
      await createMdMeetingMutation({
        variables: {
          bookedById: userMedspa.id,
          bookingFlow: "provider_compliance_hub",
          details: data.meetingDetails || "",
          endTime: dateRange.end.toISOString(),
          attendeesIds: attendeesIds.map(String),
          startTime: dateRange.start.toISOString(),
        },
      });

      toast.success("MD meeting created successfully", { id: toastId });

      closeDrawer();
    } catch (error) {
      logError(error.message);
      toast.error(
        hasDjangoMutationError(error)
          ? error.message
          : "Unable to create MD meeting. Please contact support team.",
        { id: toastId }
      );
    }
  });

  return {
    // Drawer state
    isOpen,
    closeDrawer,

    // Form control and validation
    control,
    errors,
    isCreateButtonEnabled,
    onSubmit,

    // Options for autocomplete fields
    providerOptions,
    mdOptions,

    // Time selector state
    isTimeSelectorOpen,
    hasSetEndTime,
    setHasSetEndTime,

    // Loading state
    isLoading,
  };
}
