import path from "path";
import { Configuration } from "rollbar";
import { isObj } from "@/utils";

// __dirname -> absolute path of the directory containing the currently executing file
// BASE_PATH -> absolute path to the root directory
const BASE_PATH = path.resolve(__dirname, "../..");

// noinspection RedundantIfStatementJS

export const rollbarConfig: Configuration = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NEXT_PUBLIC_ENV,
    source_map_enabled: true,
    code_version: process.env.CODE_VERSION,
    guess_uncaught_frames: true,
    server: {
      root: BASE_PATH,
    },
  },
  checkIgnore: (isUncaught, args, payload) => {
    if (
      hasExceptionMessage(payload?.body) &&
      payload.body.trace.exception.message ===
        "disconnected (status: 0, code: 0)"
    ) {
      return true;
    }
    if (
      hasExceptionMessage(payload?.body) &&
      payload.body.trace.exception.message ===
        "undefined is not an object (evaluating 'this._conversationsPromise.then')"
    ) {
      return true;
    }
    return false;
  },
  transform: (payload) => {
    const sessionLink = window.birdeatsbug?.session?.link;

    if (sessionLink) {
      payload.birdeatsbugSessionURL = sessionLink;
    }
  },
};

const hasExceptionMessage = (
  body: unknown
): body is { trace: { exception: { message: string } } } =>
  isObj(body) &&
  "trace" in body &&
  isObj(body.trace) &&
  "exception" in body.trace &&
  isObj(body.trace.exception) &&
  "message" in body.trace.exception;
