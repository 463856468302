import { DateRange } from "@fullcalendar/core/internal";
import React, { createContext, useContext, useState, useMemo } from "react";

type TimeSelectorContextType = {
  readonly dateRange?: DateRange;
  setStart: React.Dispatch<Date>;
  setEnd: React.Dispatch<Date>;
  readonly isOpen: boolean;
  openSelector: VoidFunction;
  closeSelector: VoidFunction;
};

/**
 * Start with undefined to ensure that the context is not used outside of the provider
 */
const TimeSelectorContext = createContext<TimeSelectorContextType | undefined>(
  undefined
);

export const useTimeSelectorContext = () => {
  const context = useContext(TimeSelectorContext);

  if (context === undefined) {
    throw new Error(
      "useTimeSelectorContext must be used within a TimeSelectorProvider"
    );
  }

  return context;
};

export const useTimeSelector = (initialDateRange?: Partial<DateRange>) => {
  const [start, setStart] = useState<Date | null>(
    initialDateRange?.start ?? null
  );
  const [end, setEnd] = useState<Date | null>(initialDateRange?.end ?? null);

  const dateRange = useMemo(() => {
    return start != null && end != null ? { start, end } : undefined;
  }, [start, end]);

  const [isOpen, setIsOpen] = useState(false);

  const openSelector = () => setIsOpen(true);
  const closeSelector = () => setIsOpen(false);

  return { dateRange, setStart, setEnd, isOpen, openSelector, closeSelector };
};

export const TimeSelectorProvider: React.FC<
  React.PropsWithChildren<Partial<DateRange>>
> = ({ children, start, end }) => {
  const timeSelector = useTimeSelector({ start, end });
  return (
    <TimeSelectorContext.Provider value={timeSelector}>
      {children}
    </TimeSelectorContext.Provider>
  );
};
