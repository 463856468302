import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SendChartToReviewMutationVariables = Types.Exact<{
  visitId: Types.Scalars["BigInt"]["input"];
  signature: Types.Scalars["String"]["input"];
  signatureAdditionalText?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type SendChartToReviewMutation = {
  __typename?: "mutation_root";
  signChart?: {
    __typename?: "SignChart";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const SendChartToReviewDocument = gql`
  mutation SendChartToReview(
    $visitId: BigInt!
    $signature: String!
    $signatureAdditionalText: String
  ) {
    signChart(
      visitId: $visitId
      signature: $signature
      signatureAdditionalText: $signatureAdditionalText
    ) {
      ok
      message
    }
  }
`;
export type SendChartToReviewMutationFn = Apollo.MutationFunction<
  SendChartToReviewMutation,
  SendChartToReviewMutationVariables
>;

/**
 * __useSendChartToReviewMutation__
 *
 * To run a mutation, you first call `useSendChartToReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChartToReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChartToReviewMutation, { data, loading, error }] = useSendChartToReviewMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      signature: // value for 'signature'
 *      signatureAdditionalText: // value for 'signatureAdditionalText'
 *   },
 * });
 */
export function useSendChartToReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChartToReviewMutation,
    SendChartToReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendChartToReviewMutation,
    SendChartToReviewMutationVariables
  >(SendChartToReviewDocument, options);
}
export type SendChartToReviewMutationHookResult = ReturnType<
  typeof useSendChartToReviewMutation
>;
export type SendChartToReviewMutationResult =
  Apollo.MutationResult<SendChartToReviewMutation>;
export type SendChartToReviewMutationOptions = Apollo.BaseMutationOptions<
  SendChartToReviewMutation,
  SendChartToReviewMutationVariables
>;
