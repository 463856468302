import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUser } from "@/auth/useUser";
import { Option } from "@/components/common/sorting/radioOptionsTypes";

export enum CalendarViews {
  LIST = "list",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

const CalendarViewsOptions: Option[] = [
  {
    id: CalendarViews.LIST,
    label: "List view",
  },
  {
    id: CalendarViews.DAY,
    label: "Daily calendar view",
  },
  {
    id: CalendarViews.WEEK,
    label: "Weekly calendar view",
  },
  {
    id: CalendarViews.MONTH,
    label: "Monthly calendar view",
  },
];

export const CALENDAR_VIEW_KEY = "CALENDAR_VIEW";

export default function useCalendarTypeView(defaultCalendarView: string) {
  const {
    push,
    query: { view },
  } = useRouter();

  const defaultView = defaultCalendarView as CalendarViews;

  const { medspa } = useUser();

  const [selectedCalendarView, setSelectedCalendarView] = useState<Option>(
    () => {
      const selectedOption = CalendarViewsOptions.find(
        (option) => option.id === defaultView
      );
      if (!selectedOption) {
        return CalendarViewsOptions[0];
      }
      return selectedOption;
    }
  );

  const onOptionChange = (newOption: Option) => {
    setSelectedCalendarView(newOption);
  };

  useEffect(() => {
    if (view !== selectedCalendarView.id) {
      Cookies.set(CALENDAR_VIEW_KEY, selectedCalendarView.id);

      // We went for query params because we want to to a shallow refresh and not a full page reload
      // Since we already have a dynamic route in this root ([slug]) we can't use a dynamic route for
      // calendar views.
      // https://nextjs.org/docs/pages/building-your-application/routing/linking-and-navigating#caveats
      push(`/${medspa}/visits?view=${selectedCalendarView.id}`, undefined, {
        shallow: true,
      });
    }
  }, [medspa, push, selectedCalendarView, view]);

  return {
    activeOptionId: selectedCalendarView.id,
    options: CalendarViewsOptions,
    onOptionChange,
  };
}
