import { Stack, Typography } from "@mui/material";

type Props = {
  medspaName: string;
  userRole: string;
};

const TopBarUserBanner = ({ medspaName, userRole }: Props) => {
  return (
    <Stack alignItems="flex-end" mr={1}>
      <Typography
        variant="subtitleSmall"
        sx={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          textAlign: "right",
        }}
      >
        {medspaName}
      </Typography>
      <Typography
        variant="paragraphSmall"
        sx={{
          mt: -0.5,
          textAlign: "right",
        }}
      >
        {userRole}
      </Typography>
    </Stack>
  );
};

export default TopBarUserBanner;
