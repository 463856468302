import { useRouter } from "next/router";
import { useUser } from "@/auth/useUser";
import useUserHasRoles from "@/hooks/user/useUserHasRoles";
import { MEDICAL_DIRECTOR } from "@/types";

export const useMedspaId = () => {
  const {
    query: { medspaId },
  } = useRouter();
  const { medspa } = useUser();

  const isMd = useUserHasRoles([MEDICAL_DIRECTOR]);

  // MD/GFE Reviewer can be assigned to many medspas,
  // so they can't rely on the user's medspa that they are currently logged it
  return isMd ? (medspaId as string) : medspa;
};
