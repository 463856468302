import { Card, CardContent, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import SectionHeader from "../sectionHeader/sectionHeader";

export default function InfoCard({
  title,
  children,
  sx,
  onClick,
}: {
  title?: string;
  children: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
  onClick?: () => void;
}) {
  return (
    <Card
      onClick={onClick}
      variant="outlined"
      sx={{
        overflow: "visible",
        mb: 1.5,
        ...sx,
      }}
    >
      <CardContent>
        {title && <SectionHeader title={title} />}
        {children}
      </CardContent>
    </Card>
  );
}
