import { Button, Collapse, ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "@/auth/useUser";
import {
  GREY,
  TEXT_PRIMARY_DARK,
  TEXT_SECONDARY,
  TEXT_TERTIARY,
  VIOLET,
  WHITE,
} from "@/config/mui/colorPalette";
import { useResponsive } from "@/hooks/common/useResponsive";
import useIsPWA from "@/hooks/misc/useIsPWA";
import useNavigationData, {
  NavDataType,
} from "@/hooks/navigation/useNavigationData";
import { getDefaultRoute } from "@/utils";
import { NewScreenIcon } from "../icons";
import { TOP_BAR_HEIGHT } from "./topBar";

export const NAV_WIDTH = "280px";

export default function VerticalNav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const { user, medspa } = useUser();
  const { navData } = useNavigationData(user?.role);
  const mdUp = useResponsive("up", "md");

  useEffect(() => {
    if (openNav) {
      // automatically close mobile menu when route changes (mobile only)
      onCloseNav();
    }
  }, [pathname]);

  const content = (
    <Box m={2}>
      {mdUp && (
        <Box
          px={2}
          py={1}
          component={Link}
          href={getDefaultRoute({ medspa: { id: medspa }, role: user?.role })}
        >
          <Image
            src="/assets/moxie-logo.svg"
            width={80}
            height={20}
            alt="Moxie logo"
          />
        </Box>
      )}
      <NavSectionVertical navData={navData} />
    </Box>
  );

  return (
    <Box
      sx={{
        flexShrink: { md: 0 },
        width: { md: 280 },
      }}
    >
      {mdUp ? (
        <Stack
          sx={{
            height: "100%",
            overflowY: "scroll",
            position: "fixed",
            width: NAV_WIDTH,
            backgroundColor: WHITE,
            borderRight: `1px solid ${GREY[30]}`,
          }}
        >
          {content}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              mt: TOP_BAR_HEIGHT,
              width: NAV_WIDTH,
              overscrollBehavior: "contain",
            },
          }}
        >
          {content}
        </Drawer>
      )}
    </Box>
  );
}

function NavSectionVertical({ navData }: { navData: NavDataType }) {
  const isPWA = useIsPWA();

  return (
    <Stack
      component="nav"
      mt={2}
      pb={6}
      sx={{
        ...(isPWA
          ? { minHeight: `calc(100vh - ${TOP_BAR_HEIGHT})`, mb: 2 }
          : {}),
      }}
    >
      {navData.map((group, index) => (
        <Group
          key={group.subheader || index}
          subheader={group.subheader}
          items={group.items}
        />
      ))}
    </Stack>
  );
}

function Group({
  subheader,
  items,
}: {
  subheader?: string;
  items?: NavDataType[number]["items"];
}) {
  const [open, setOpen] = useState(true);
  const path = usePathname();

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const navButtons = useMemo(() => {
    return items?.map((list) => {
      const active = list.getIsActive
        ? list.getIsActive(path)
        : path.includes(list.path);

      const NotificationComponent = list.notifications;

      return (
        <Button
          fullWidth
          key={list.title}
          href={list.path}
          onClick={list.onClick}
          startIcon={
            <list.icon size={24} color={active ? VIOLET[90] : GREY[60]} />
          }
          endIcon={
            list.isExternal && <NewScreenIcon size={16} color={GREY[60]} />
          }
          sx={{
            backgroundColor: active ? VIOLET[20] : WHITE,
            pl: 1.5,
            pr: 2,
            py: 1,
            fontSize: 14,
            fontWeight: active ? 600 : 400,
            color: active ? VIOLET[90] : TEXT_SECONDARY,
            justifyContent: "flex-start",
          }}
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Box>{list.title}</Box>
            {NotificationComponent && <NotificationComponent />}
          </Stack>
        </Button>
      );
    });
  }, [items, path]);

  return (
    <Stack>
      {subheader ? (
        <>
          <ListSubheader
            disableGutters
            disableSticky
            onClick={handleToggle}
            sx={{
              fontSize: 12,
              cursor: "pointer",
              color: TEXT_TERTIARY,
              "&:hover": {
                color: TEXT_PRIMARY_DARK,
              },
            }}
          >
            {subheader}
          </ListSubheader>

          <Collapse in={open}>
            <Stack spacing={0.5}>{navButtons}</Stack>
          </Collapse>
        </>
      ) : (
        <Stack spacing={0.5}>{navButtons}</Stack>
      )}
    </Stack>
  );
}
