import { useRef, useEffect, type EffectCallback } from "react";

export const useEffectOnce = (
  condition: boolean | null | undefined,
  effect: EffectCallback
): void => {
  const triggered = useRef(false);
  useEffect(() => {
    if (!condition || triggered.current) {
      return;
    }
    triggered.current = true;
    return effect();
  }, [condition, effect]);
};
