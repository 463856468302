import { create } from "zustand";
import { GfeStatus, ServiceMenuItemGfeStatus } from "@/types/gfe";
import {
  GfeStatusType,
  createGfeStatusFilterStore,
} from "../../reviewVisits/filters/gfeStatusFilterStore";

export const ALL_GFE_OPTIONS_OLD = [
  GfeStatus.INCOMPLETE,
  GfeStatus.CLIENT_COMPLETED,
  GfeStatus.WAITING_FOR_GFE,
  GfeStatus.GFE_APPROVED,
  GfeStatus.GFE_DECLINED,
];

// TODO: Adjust when removing the filter that supports old and new gfe flow
export const NEW_GFE_OPTIONS = [
  ServiceMenuItemGfeStatus.FORMS_INCOMPLETE,
  ServiceMenuItemGfeStatus.PENDING_REVIEWER,
  ServiceMenuItemGfeStatus.CONTRAINDICATED,
  ServiceMenuItemGfeStatus.NOT_INDICATED,
  ServiceMenuItemGfeStatus.INDICATED_WITH_EXCEPTIONS,
  ServiceMenuItemGfeStatus.INDICATED,
];

export const OLD_GFE_OPTIONS = [GfeStatus.GFE_APPROVED, GfeStatus.GFE_DECLINED];

const ALL_GFE_OPTIONS: GfeStatusType[] = [
  ...NEW_GFE_OPTIONS,
  ...OLD_GFE_OPTIONS,
];

export const useVisitGfeStatusFilterStoreOld = create(
  createGfeStatusFilterStore(ALL_GFE_OPTIONS_OLD)
);

export const useVisitGfeStatusFilterStore = create(
  createGfeStatusFilterStore(ALL_GFE_OPTIONS)
);
