import { ClerkProvider } from "@clerk/nextjs";
import { CacheProvider, EmotionCache, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { Provider } from "@rollbar/react";
import NextAdapterPages from "next-query-params/pages";
import type { AppProps } from "next/app";
import App from "next/app";
import dynamic from "next/dynamic";
import { QueryParamProvider } from "use-query-params";
import { UserAuthContextProvider } from "@/auth/userAuthContext";
import DatadogInit from "@/components/common/datadog/datadogInit";
import ErrorBoundary from "@/components/common/errors/errorBoundary";
import { PWAPullToRefresh } from "@/components/common/navigation/pwaPullToRefresh";
import { AnalyticsProvider } from "@/components/common/segment/AnalyticsContext";
import MainToaster from "@/components/common/toasts/mainToaster";
import CreateMdMeetingDrawer from "@/components/complianceHub/createMdMeetingDrawer";
import ConfirmProvider from "@/components/providers/confirmProvider";
import clerkProviderProps from "@/config/clerk";
import { rollbarConfig } from "@/config/logging/rollbar";
import createEmotionCache from "@/config/mui/createEmotionCache";
import theme from "@/config/mui/theme";
import ClientAccessTokenProvider from "@/contexts/clientAccessTokenContext";
import { CreateMdMeetingDrawerProvider } from "@/contexts/createMdMeetingDrawerContext";
import { MessagesContextProvider } from "@/contexts/messagesContext";
import useMuiMenuDisableScrolling from "@/hooks/misc/useMuiMenuDisableScrolling";
import { ApolloProviderWrapper } from "@/lib/apollo";
import Maintenance from "@/views/maintenance";
import "../styles/globals.css";

const BirdEatsBugWebSDK = dynamic(
  () => import("@/components/common/birdEatsBugWebSDK/birdEatsBugWebSDK"),
  {
    ssr: false,
  }
);

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const localization = {
  userButton: {
    action__addAccount: "Switch account",
  },
};

function MoxieApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const isMaintenanceActive =
    process.env.NEXT_PUBLIC_IS_MAINTENANCE_MODE_ENABLED === "True";

  // TODO: bring back ability to disable navigation on a per-page basis
  // const showNavigation = !!Component?.showNavigation;

  useMuiMenuDisableScrolling();

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <CacheProvider value={emotionCache}>
          <ClerkProvider
            localization={localization}
            {...pageProps}
            {...clerkProviderProps}
          >
            <ApolloProviderWrapper pageProps={pageProps}>
              <ThemeProvider theme={theme}>
                <UserAuthContextProvider>
                  <AnalyticsProvider
                    disablePageTracking={Component?.disablePageTracking}
                    writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
                  >
                    <ConfirmProvider>
                      <QueryParamProvider adapter={NextAdapterPages}>
                        <CssBaseline />
                        <MessagesContextProvider>
                          <ErrorBoundary>
                            <PWAPullToRefresh>
                              <ClientAccessTokenProvider>
                                <CreateMdMeetingDrawerProvider>
                                  {!isMaintenanceActive ? (
                                    <>
                                      <DatadogInit />
                                      <BirdEatsBugWebSDK />
                                      <Component {...pageProps} />
                                      <CreateMdMeetingDrawer />
                                      <MainToaster />
                                    </>
                                  ) : (
                                    <Maintenance />
                                  )}
                                </CreateMdMeetingDrawerProvider>
                              </ClientAccessTokenProvider>
                            </PWAPullToRefresh>
                          </ErrorBoundary>
                        </MessagesContextProvider>
                      </QueryParamProvider>
                    </ConfirmProvider>
                  </AnalyticsProvider>
                </UserAuthContextProvider>
              </ThemeProvider>
            </ApolloProviderWrapper>
          </ClerkProvider>
        </CacheProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default MoxieApp;

MoxieApp.getInitialProps = async (appContext) => {
  // This is a workaround to fully disable Automatic Static Optimization on ALL PAGES
  // Learn more about Automatic Static Optimization here: https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
  // We don't really need ASO for Moxie, as most of our pages are dynamic and behind a login wall
  // We may want ASO for some pages in the future, for example improving the load times for the booking/online store pages
  const appProps = await App.getInitialProps(appContext);
  return appProps;
};
