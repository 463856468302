import { NotificationsDot } from "@/components/common/navigation/notificationsDot";
import { usePendingAdverseReactionsCountSubscription } from "@/graphql/subscriptions/pendingAdverseReactionsCount.graphql.types";
import { useUserMedspaIds } from "@/hooks/common/useUserMedspa";

export default function PendingAdverseReactionsNotificationDot() {
  const medspaIds = useUserMedspaIds();

  const { data } = usePendingAdverseReactionsCountSubscription({
    variables: {
      medspaIds: medspaIds,
    },
    skip: !medspaIds,
  });

  const pendingAdverseReactionsCount =
    data?.adverseReactionAggregate.aggregate.count;

  return (
    pendingAdverseReactionsCount > 0 && (
      <NotificationsDot count={pendingAdverseReactionsCount} />
    )
  );
}
