import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

export default function IconCircleContainer({
  color,
  size,
  icon,
  sx,
}: {
  color: string;
  size: string;
  icon: ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        minWidth: size,
        minHeight: size,
        width: size,
        height: size,
        bgcolor: color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {icon}
    </Box>
  );
}
