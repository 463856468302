import { Box, Tooltip } from "@mui/material";
import { CrownIcon } from "@/components/common/icons";

type Props = {
  tooltipText: string;
  color: string;
  iconSpacing?: boolean;
};

export default function TooltipCrownIcon({ tooltipText, color }: Props) {
  return (
    <Tooltip title={tooltipText} arrow placement="right">
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
        }}
      >
        <CrownIcon strokeWidth={1.5} set="duotone" color={color} size="20" />
      </Box>
    </Tooltip>
  );
}
