import { useTheme } from "@mui/material";
import { ChevronSmallDownIcon, Icon } from "@/components/common/icons/index";

type ToggleIconProps = Icon & { isToggled: boolean };

function ToggleIcon({ isToggled, ...props }: ToggleIconProps) {
  const theme = useTheme();

  return (
    <ChevronSmallDownIcon
      color={theme.palette.primary.main}
      {...props}
      style={{
        ...props.style,
        transform: `rotateX(${isToggled ? "180deg" : "0"})`,
      }}
    />
  );
}

export default ToggleIcon;
