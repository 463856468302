import { differenceInHours, isSameDay, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { DatePeriods } from "@/components/serviceFlow/charts/chartsDateFilter";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { ReviewStatus } from "@/types";
import { getLastNDays } from "@/utils/date";

export const getVisitRangeDates = (
  startTime: string,
  endTime: string,
  timezone: string
): string => {
  const dateString = formatInTimeZone(
    startTime,
    timezone,
    DATE_FORMATS.DATE_PICKER
  );
  const timeStartString = formatInTimeZone(
    startTime,
    timezone,
    TIME_FORMATS.TILE
  );
  const timeEndString = formatInTimeZone(endTime, timezone, TIME_FORMATS.TILE);
  return `${dateString} • ${timeStartString} - ${timeEndString}`;
};

export const getLastChartLogDate = (
  timeString?: string,
  timezone?: string
): string => {
  if (!timeString || !timezone) {
    return "";
  }

  const dateString = formatInTimeZone(
    timeString,
    timezone,
    DATE_FORMATS.DATE_PICKER
  );
  const timeStartString = formatInTimeZone(
    timeString,
    timezone,
    TIME_FORMATS.TILE
  );

  return `${dateString} • ${timeStartString}`;
};

export const getTimeRange = (
  period: DatePeriods
): {
  startDate: Date;
  endDate: Date;
} => {
  switch (period) {
    case DatePeriods.LAST_3_DAYS:
      return getLastNDays(3);
    case DatePeriods.LAST_7_DAYS:
      return getLastNDays(7);
    case DatePeriods.LAST_30_DAYS:
      return getLastNDays(30);
    default:
      throw new Error(`Invalid DatePeriod: ${period}`);
  }
};

export const findMatchingDatePeriod = (
  startDate,
  endDate,
  datePeriodRanges
) => {
  const matchingPeriodKey = Object.keys(datePeriodRanges).find((periodKey) => {
    const { startDate: periodStartDate, endDate: periodEndDate } =
      datePeriodRanges[periodKey];
    return (
      isSameDay(startDate, periodStartDate) && isSameDay(endDate, periodEndDate)
    );
  });

  return matchingPeriodKey ? (matchingPeriodKey as DatePeriods) : null;
};

export const getChartLog = (chartStatus: ReviewStatus, date: string) => {
  if (!date) return "";

  switch (chartStatus) {
    case ReviewStatus.COMPLETE:
      return `Chart completed ${date}`;
    case ReviewStatus.REVIEWED:
      return `Chart reviewed ${date}`;
    case ReviewStatus.NOT_REQUIRED:
      return `Chart marked as not required ${date}`;
    default:
      return "";
  }
};

export const calculateOverdueTime = (overdueDate: string) => {
  const hoursOverdue = differenceInHours(new Date(), parseISO(overdueDate));

  if (hoursOverdue < 1) {
    return "less than one hour";
  }
  if (hoursOverdue < 24) {
    return `${hoursOverdue} hour${hoursOverdue > 1 ? "s" : ""}`;
  }

  const daysOverdue = Math.floor(hoursOverdue / 24);
  return `${daysOverdue} day${daysOverdue > 1 ? "s" : ""}`;
};
