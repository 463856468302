import { create } from "zustand";
import { GfeForm } from "@/utils/visit";

export enum GfeStrategy {
  APPROVE = "approve",
  DECLINE = "decline",
}

type GfeStrategyStore = {
  strategy: GfeStrategy | null;
  formsToSubmit: GfeForm[];
  reset: () => void;
  setStrategy: (strategy: GfeStrategy) => void;
  setFormsToSubmit: (forms: GfeForm[]) => void;
};

const DEFAULT_STATE = {
  strategy: null,
  formsToSubmit: [],
};

export const useGfeStrategyStore = create<GfeStrategyStore>()((set) => ({
  strategy: null,
  formsToSubmit: [],
  reset: () => set(DEFAULT_STATE),
  setStrategy: (strategy) => set({ strategy }),
  setFormsToSubmit: (forms) => set({ formsToSubmit: forms }),
}));

export const getGfeStrategy = (state: GfeStrategyStore) => state.strategy;
export const getGfeFormsToSubmit = (state: GfeStrategyStore) =>
  state.formsToSubmit;
export const getGfeResetStrategyState = (state: GfeStrategyStore) =>
  state.reset;
export const getGfeSetStrategy = (state: GfeStrategyStore) => state.setStrategy;
export const getSetGfeFormsToSubmit = (state: GfeStrategyStore) =>
  state.setFormsToSubmit;
