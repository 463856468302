import { datadogRum } from "@datadog/browser-rum";
import { PUBLIC_ENVIRONMENT } from "@/config";

// We may move these to environment variables in the future
// Currently, these are hardcoded because they do not change from env to env
const DATADOG_APP_ID = "932b2349-aca4-4ac0-bd7b-e8a414fd6e3a";
const DATADOG_CLIENT_TOKEN = "pube60148a6e2361a43ed592725c35166b6";

datadogRum.init({
  applicationId: DATADOG_APP_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: "datadoghq.com",
  service: "moxie-fe",
  env: PUBLIC_ENVIRONMENT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0, // Disable session replay since we're using livesession, may change in the future
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  version: "1.0.0",
  defaultPrivacyLevel: "mask-user-input",
  allowedTracingUrls: [
    {
      match: process.env.NEXT_PUBLIC_GRAPHQL_URI,
      propagatorTypes: ["tracecontext"],
    },
  ],
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
