import { GetToken } from "@clerk/types";
import { CLERK_JWT_HASURA_TEMPLATE } from "@/types";

const defaultOptions = {
  withAuth: true,
};

const getAuthorizationHeader = async (getToken: GetToken) => {
  let accessToken = "";
  if (getToken) {
    accessToken = await getToken(CLERK_JWT_HASURA_TEMPLATE);
  }

  return {
    Authorization: `Bearer ${accessToken || ""}`,
  };
};

export const getClientAuthorizationHeader = (clientAccessToken: string) => ({
  Authorization: `Token ${clientAccessToken}`,
});

type Options = {
  withAuth?: boolean;
  getToken?: GetToken;
  headers?: { [key: string]: string };
  [key: string]: any;
};

type Body = { [key: string]: any } | FormData;

export const postRequest = async <T>(
  url: string,
  body: Body | FormData,
  options: Options = defaultOptions
): Promise<T> => {
  const { withAuth, getToken, headers = {}, ...other } = options;
  const isForm = body instanceof FormData;

  const requestHeaders = {
    ...(withAuth ? await getAuthorizationHeader(getToken) : {}),
    ...headers,
  };

  if (!isForm) {
    requestHeaders["Content-Type"] = "application/json";
  }

  const r = await fetch(url, {
    method: "POST",
    headers: requestHeaders,
    ...other,
    body: getParsedBody(body),
  });

  const payload = await getJson(r);
  if (r.ok) return payload;

  handleErrorResponse(payload, r);
};

export const getRequest = async <T>(
  url: string,
  options: Options = defaultOptions
): Promise<T> => {
  const { withAuth, getToken, headers = {}, ...other } = options;
  const r = await fetch(url, {
    headers: {
      ...(withAuth ? await getAuthorizationHeader(getToken) : {}),
      ...headers,
    },
    ...other,
  });

  const payload = await getJson(r);
  if (r.ok) return payload.data ? payload.data : payload;

  handleErrorResponse(payload, r);
};

export const deleteRequest = async <T>(
  url: string,
  options: Options = defaultOptions
): Promise<T> => {
  const { withAuth, getToken, headers = {}, ...other } = options;
  const r = await fetch(url, {
    method: "DELETE",
    headers: {
      ...(withAuth ? await getAuthorizationHeader(getToken) : {}),
      ...headers,
    },
    ...other,
  });

  const payload = await getJson(r);
  if (r.ok) return payload;

  handleErrorResponse(payload, r);
};

export const patchRequest = async <T>(
  url: string,
  body: Body,
  options: Options = defaultOptions
): Promise<T> => {
  const { withAuth, getToken, headers = {}, ...other } = options;
  const isForm = body instanceof FormData;

  const requestHeaders = {
    ...(withAuth ? await getAuthorizationHeader(getToken) : {}),
    ...headers,
  };

  if (!isForm) {
    requestHeaders["Content-Type"] = "application/json";
  }

  const r = await fetch(url, {
    method: "PATCH",
    headers: requestHeaders,
    ...other,
    body: getParsedBody(body),
  });

  const payload = await getJson(r);
  if (r.ok) return payload;

  handleErrorResponse(payload, r);
};

export const putRequest = async <T>(
  url: string,
  body: Body,
  options: Options = defaultOptions
): Promise<T> => {
  const { withAuth, getToken, headers = {}, ...other } = options;
  const r = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...(withAuth ? await getAuthorizationHeader(getToken) : {}),
      ...headers,
    },
    ...other,
    body: body instanceof File ? body : JSON.stringify(body),
  });

  const payload = await getJson(r);
  if (r.ok) return payload;

  handleErrorResponse(payload, r);
};

const getJson = async (r: Response) => {
  try {
    return await r.json();
  } catch {
    return;
  }
};

const getParsedBody = (body: Body | FormData): BodyInit =>
  body instanceof FormData ? body : JSON.stringify(body);

const isNotEmptyObject = (obj: unknown) => Object.keys(obj).length > 0;

const handleErrorResponse = (
  payload: { error: { message?: string } },
  r: Response
) => {
  if (payload?.error?.message) throw Error(payload.error.message);

  if (isNotEmptyObject(payload?.error))
    throw Error(JSON.stringify(payload.error));

  if (isNotEmptyObject(payload)) throw Error(JSON.stringify(payload));

  throw Error(r.status + " " + r.statusText);
};
