import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { differenceInMinutes, format } from "date-fns";
import React, { forwardRef } from "react";
import { CalendarClock } from "react-swm-icon-pack";
import { DATE_FORMATS, TIME_FORMATS } from "@/config";
import { GREY, VIOLET } from "@/config/mui/colorPalette";
import { formattedTime } from "@/utils/date";
import { useTimeSelectorContext } from "./timeSelectorContext";

type Props = {
  onClick?: VoidFunction;
  durationText?: string;
};

const TimeSelectorButton: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = ({ onClick, durationText = "" }, ref) => {
  const { openSelector, dateRange } = useTimeSelectorContext();
  const handleClick = () => {
    openSelector();
    onClick?.();
  };
  const dateTimeText =
    dateRange != null
      ? `${format(dateRange.start, DATE_FORMATS.DATE_PICKER)}, ${format(dateRange.start, TIME_FORMATS.TILE)} - ${format(dateRange.end, TIME_FORMATS.TILE)}`
      : "Date & time not selected yet";
  const duration =
    dateRange != null ? differenceInMinutes(dateRange.end, dateRange.start) : 0;
  const formattedDuration =
    duration > 0 ? `${formattedTime(duration)}${durationText}` : undefined;
  return (
    <ListItemButton
      ref={ref}
      onClick={handleClick}
      sx={{
        backgroundColor: GREY[10],
        borderRadius: 2,
        gap: 2,
      }}
    >
      <CalendarClock color={GREY[60]} size={20} />
      <ListItemText
        primary={dateTimeText}
        primaryTypographyProps={{
          variant: "labelSmall",
          color: "text.secondary",
        }}
        secondary={formattedDuration}
        secondaryTypographyProps={{
          variant: "labelTiny",
          color: "text.disabled",
        }}
      />
      <Typography variant="labelSmall" color={VIOLET[90]}>
        {dateRange != null ? "Edit" : "Select"}
      </Typography>
    </ListItemButton>
  );
};

export default forwardRef(TimeSelectorButton);
