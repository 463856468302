import { debounce, useScrollTrigger } from "@mui/material";
import { useEffect, useState } from "react";

export default function useDebouncedScrollTrigger() {
  const [disableHysteresis, setDisableHysteresis] = useState(false);
  const trigger = useScrollTrigger({ disableHysteresis });

  useEffect(() => {
    const scrollEventFunction = debounce(() => {
      setDisableHysteresis(
        window.innerHeight + window.scrollY + 1 >= document.body.scrollHeight
      );
    }, 50);

    document.addEventListener("scroll", scrollEventFunction);

    return () => document.removeEventListener("scroll", scrollEventFunction);
  }, []);

  return trigger;
}
