import { useRouter } from "next/router";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import Dropdown from "@/components/dropdown/dropdown";
import { useConfirm } from "@/components/providers/confirmProvider";
import { useDeleteAvailabilityEventMutation } from "@/graphql/mutations/deleteAvailabilityEvent.graphql.types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export default function TimeBlockDropdown({ id }: { id: string }) {
  const { push } = useRouter();
  const { medspa } = useUser();
  const { getConfirm } = useConfirm();
  const logError = useErrorLogger();

  const [deleteAvailabilityEvent] = useDeleteAvailabilityEventMutation();

  const deleteTimeBlock = async () => {
    const ok = await getConfirm({
      title: "Delete time block",
      description: "Are you sure you want to delete this time block?",
      confirmButtonText: "Delete",
      discardButtonText: "Discard",
    });
    if (!ok) return;

    const toastId = toast.loading("Deleting the time block...");

    try {
      await deleteAvailabilityEvent({
        variables: { eventId: id },
        update(cache) {
          cache.evict({ id: `availabilityEvent:${id}` });
        },
      });

      toast.success("The time block has been deleted successfully!", {
        id: toastId,
      });
      push(`/${medspa}/visits`);
    } catch (e) {
      const errorMessage = "Failed to delete the time block";
      toast.error(hasDjangoMutationError(e) ? e.message : errorMessage, {
        id: toastId,
      });
      logError(errorMessage, e);
    }
  };

  return (
    <Dropdown
      sx={{ pt: 2 }}
      onDelete={deleteTimeBlock}
      editLink={`/${medspa}/time-blocks/${id}/edit`}
    />
  );
}
