import { StateCreator, StoreApi, UseBoundStore } from "zustand";
import {
  FilterSliceState,
  createFilterSlice,
} from "@/store/views/serviceFlow/reviewVisits/filters/filterSlice";
import { GfeStatus, ServiceMenuItemGfeStatus } from "@/types/gfe";

// TODO: Adjust when removing the filter that supports old and new gfe flow
export type GfeStatusType = GfeStatus | ServiceMenuItemGfeStatus;

export type GfeStatusFilterState = GfeStatusType[];

const INITIAL_FILTER = [GfeStatus.GFE_APPROVED, GfeStatus.GFE_DECLINED];

type GfeStatusFilterStore = FilterSliceState<GfeStatusFilterState> & {
  toggleGfeStatus: (id: GfeStatusType, checked: boolean) => void;
  selectAllGfeStatuses: (statuses: GfeStatusType[]) => void;
};

export const createGfeStatusFilterStore =
  (
    initialFilter: GfeStatusFilterState = INITIAL_FILTER
  ): StateCreator<GfeStatusFilterStore> =>
  (...args) => {
    const [set] = args;

    return {
      ...createFilterSlice<GfeStatusFilterState>(initialFilter)(...args),
      toggleGfeStatus: (id, checked) => {
        set((prevState) => {
          const { currentFilter } = prevState;

          return {
            currentFilter: checked
              ? [...currentFilter, id]
              : currentFilter.filter((existingId) => existingId !== id),
          };
        });
      },
      selectAllGfeStatuses: (statuses: GfeStatusType[]) => {
        set(() => ({ currentFilter: statuses }));
      },
      clearFilter: () => {
        set(() => ({ currentFilter: [] }));
      },
    };
  };

export type UseGfeStatusFilterStore = UseBoundStore<
  StoreApi<GfeStatusFilterStore>
>;

export const getAppliedGfeStatusFilter = (state: GfeStatusFilterStore) =>
  state.appliedFilter;
