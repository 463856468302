import { Chip } from "@mui/material";
import { BLUE, MINT, SALMON, VIOLET } from "@/config/mui/colorPalette";
import { VisitStatus } from "@/types";

export function EventTileOutlinedChip({
  label,
  color,
  borderColor,
}: {
  label: string;
  color: string;
  borderColor?: string;
}) {
  return (
    <Chip
      size="small"
      label={label}
      sx={{
        border: `2px solid ${borderColor || color}`,
        color,
        backgroundColor: "transparent",
      }}
    />
  );
}

export const getColorForStatus = (status: VisitStatus) =>
  ({
    [VisitStatus.SCHEDULED]: BLUE[80],
    [VisitStatus.CONFIRMED]: VIOLET[80],
    [VisitStatus.COMPLETED]: MINT[80],
    [VisitStatus.CANCELLED]: SALMON[80],
    [VisitStatus.CANCELLED_LATE]: SALMON[80],
    [VisitStatus.NO_SHOW]: SALMON[80],
  })[status];
