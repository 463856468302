import { isEmpty, isEqual } from "lodash";
import { StateCreator } from "zustand";

export type FilterSliceState<S> = {
  defaultFilter: S;
  currentFilter: S;
  appliedFilter: S;
  initFilter: (state: S) => void;
  getIsDirty: () => boolean;
  getIsEmpty: () => boolean;
  applyFilter: () => void;
  resetFilter: () => void;
  revertFilter: () => void;
  clearFilter?: () => void;
};

export const createFilterSlice =
  <S>(initialState: S): StateCreator<FilterSliceState<S>> =>
  (set, get) => ({
    defaultFilter: initialState,
    currentFilter: initialState,
    appliedFilter: initialState,
    initFilter: (state: S) => {
      set({
        defaultFilter: state,
        currentFilter: state,
        appliedFilter: state,
      });
    },
    getIsDirty: () => {
      const { currentFilter, defaultFilter } = get();

      return !isEqual(currentFilter, defaultFilter);
    },
    getIsEmpty: () => {
      const { currentFilter } = get();

      return isEmpty(currentFilter);
    },
    applyFilter: () =>
      set((prevState) => ({ appliedFilter: prevState.currentFilter })),
    revertFilter: () => {
      set((prevState) => ({ currentFilter: prevState.appliedFilter }));
    },
    resetFilter: () => {
      set((prevState) => ({ currentFilter: prevState.defaultFilter }));
    },
  });
