import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SumUnreadHighlevelMessagesQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessages?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type SumUnreadHighlevelMessagesForClientQueryVariables = Types.Exact<{
  medspaId: Types.Scalars["Int"]["input"];
  clientId: Types.Scalars["Int"]["input"];
}>;

export type SumUnreadHighlevelMessagesForClientQuery = {
  __typename?: "query_root";
  sumUnreadHighlevelMessagesByClient?: {
    __typename?: "UnreadHighlevelMessagesCountType";
    count?: number | null;
  } | null;
};

export type HighlevelMessagesByConversationIdQueryVariables = Types.Exact<{
  conversationId: Types.Scalars["String"]["input"];
}>;

export type HighlevelMessagesByConversationIdQuery = {
  __typename?: "query_root";
  highlevelMessagesByConversationId?: {
    __typename?: "MessagesType";
    lastMessageId: string;
    nextPage: boolean;
    messages: Array<{
      __typename?: "MessageType";
      id: string;
      userId?: string | null;
      body?: string | null;
      locationId: string;
      contactId: string;
      conversationId: string;
      dateAdded: string;
      direction: Types.MessageDirectionType;
      status?: Types.MessageStatusType | null;
      attachments?: Array<string | null> | null;
      meta?: string | null;
      source?: string | null;
    } | null>;
  } | null;
};

export const SumUnreadHighlevelMessagesDocument = gql`
  query SumUnreadHighlevelMessages($medspaId: Int!) {
    sumUnreadHighlevelMessages(medspaId: $medspaId) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export function useSumUnreadHighlevelMessagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesQuery,
    SumUnreadHighlevelMessagesQueryVariables
  >(SumUnreadHighlevelMessagesDocument, options);
}
export type SumUnreadHighlevelMessagesQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesQuery
>;
export type SumUnreadHighlevelMessagesLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesLazyQuery
>;
export type SumUnreadHighlevelMessagesSuspenseQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesSuspenseQuery
>;
export type SumUnreadHighlevelMessagesQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesQuery,
  SumUnreadHighlevelMessagesQueryVariables
>;
export const SumUnreadHighlevelMessagesForClientDocument = gql`
  query SumUnreadHighlevelMessagesForClient($medspaId: Int!, $clientId: Int!) {
    sumUnreadHighlevelMessagesByClient(
      medspaId: $medspaId
      clientId: $clientId
    ) {
      count
    }
  }
`;

/**
 * __useSumUnreadHighlevelMessagesForClientQuery__
 *
 * To run a query within a React component, call `useSumUnreadHighlevelMessagesForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSumUnreadHighlevelMessagesForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSumUnreadHighlevelMessagesForClientQuery({
 *   variables: {
 *      medspaId: // value for 'medspaId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSumUnreadHighlevelMessagesForClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export function useSumUnreadHighlevelMessagesForClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    SumUnreadHighlevelMessagesForClientQuery,
    SumUnreadHighlevelMessagesForClientQueryVariables
  >(SumUnreadHighlevelMessagesForClientDocument, options);
}
export type SumUnreadHighlevelMessagesForClientQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientQuery
>;
export type SumUnreadHighlevelMessagesForClientLazyQueryHookResult = ReturnType<
  typeof useSumUnreadHighlevelMessagesForClientLazyQuery
>;
export type SumUnreadHighlevelMessagesForClientSuspenseQueryHookResult =
  ReturnType<typeof useSumUnreadHighlevelMessagesForClientSuspenseQuery>;
export type SumUnreadHighlevelMessagesForClientQueryResult = Apollo.QueryResult<
  SumUnreadHighlevelMessagesForClientQuery,
  SumUnreadHighlevelMessagesForClientQueryVariables
>;
export const HighlevelMessagesByConversationIdDocument = gql`
  query HighlevelMessagesByConversationId($conversationId: String!) {
    highlevelMessagesByConversationId(conversationId: $conversationId) {
      messages {
        id
        userId
        body
        locationId
        contactId
        conversationId
        dateAdded
        direction
        status
        attachments
        meta
        source
      }
      lastMessageId
      nextPage
    }
  }
`;

/**
 * __useHighlevelMessagesByConversationIdQuery__
 *
 * To run a query within a React component, call `useHighlevelMessagesByConversationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlevelMessagesByConversationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlevelMessagesByConversationIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useHighlevelMessagesByConversationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export function useHighlevelMessagesByConversationIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    HighlevelMessagesByConversationIdQuery,
    HighlevelMessagesByConversationIdQueryVariables
  >(HighlevelMessagesByConversationIdDocument, options);
}
export type HighlevelMessagesByConversationIdQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdQuery
>;
export type HighlevelMessagesByConversationIdLazyQueryHookResult = ReturnType<
  typeof useHighlevelMessagesByConversationIdLazyQuery
>;
export type HighlevelMessagesByConversationIdSuspenseQueryHookResult =
  ReturnType<typeof useHighlevelMessagesByConversationIdSuspenseQuery>;
export type HighlevelMessagesByConversationIdQueryResult = Apollo.QueryResult<
  HighlevelMessagesByConversationIdQuery,
  HighlevelMessagesByConversationIdQueryVariables
>;
