import { MobileDatePicker } from "@mui/x-date-pickers";
import { forwardRef } from "react";

type DatePickerProps = React.ComponentProps<typeof MobileDatePicker>;

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
  function DatePickerA(props, ref) {
    return (
      <MobileDatePicker
        {...props}
        ref={ref}
        slots={{ ...props.slots, toolbar: null }}
      />
    );
  }
);

export default DatePicker;
