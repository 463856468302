import { Box, Divider, IconButton, Stack } from "@mui/material";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { AuthUser, hasRole, humanize } from "@/auth/useUser";
import { useUser } from "@/auth/useUser";
import { GREY } from "@/config/mui/colorPalette";
import useMoxieMediaQuery from "@/hooks/misc/useMoxieMediaQuery";
import { PROVIDER, Role, Roles } from "@/types";
import { getDefaultRoute } from "@/utils";
import { MessageIcon, WarningCircleIcon } from "../icons";
import SwitchAccountIconSvg from "../icons/switchAccountIconSvg";
import RadioModal from "../sorting/radioModal";
import MessagesNotificationsDot from "./messagesNotificationsDot";
import TopBarMobileMenu from "./topBarMobileMenu";
import TopBarUserBanner from "./topBarUserBanner";

function TopBarActions({ user }: { user: AuthUser }) {
  const [accountSwitchModalOpen, setAccountSwitchModalOpen] = useState(false);
  const router = useRouter();
  const { medspa, userMedspa, additionalUserDetails, onMedspaChange } =
    useUser();
  const isMobile = useMoxieMediaQuery("ipad-ver", "down");

  // The user can only switch between medspas where it has a role of FRONT_DESK or MEDICAL_DIRECTOR
  const switchableMedspas = useMemo(() => {
    return (
      additionalUserDetails?.userMedspa.filter((userRole) => {
        const role = userRole.role as Role;
        return role === Roles.FRONT_DESK || role === Roles.MEDICAL_DIRECTOR;
      }) ?? []
    );
  }, [additionalUserDetails]);

  const canSwitchRoles =
    userMedspa?.role === Roles.FRONT_DESK ||
    userMedspa?.role === Roles.MEDICAL_DIRECTOR;

  const enableMedspaSwitch = canSwitchRoles && switchableMedspas.length > 1;

  const userRole =
    user.role === Roles.MEDICAL_DIRECTOR && userMedspa?.isGfeReviewer
      ? Roles.GFE_REVIEWER
      : user.role;

  const isProvider = hasRole(user, [PROVIDER]);

  // Providers can only belong to one medspa, so it's not necessary to show the user banner
  const showTopBarUserBanner = !isProvider;

  return (
    <Stack direction="row" alignItems="center" p={0} m={0} mr={1}>
      {showTopBarUserBanner && (
        <>
          <TopBarUserBanner
            medspaName={userMedspa?.medspa.name ?? ""}
            userRole={humanize(userRole)}
          />
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
        </>
      )}

      {!isMobile && enableMedspaSwitch && (
        <IconButton
          onClick={() => {
            setAccountSwitchModalOpen(true);
          }}
        >
          <SwitchAccountIconSvg />
        </IconButton>
      )}

      {isMobile && !isProvider && (
        <TopBarMobileMenu
          medspa={medspa}
          enableMedspaSwitch={enableMedspaSwitch}
          enableMessages={hasRole(user, [PROVIDER])}
          onClickSwitchAccount={setAccountSwitchModalOpen}
        />
      )}

      {(!isMobile || isProvider) && (
        <>
          <IconButton
            onClick={() => {
              window.birdeatsbug.trigger();
            }}
          >
            <WarningCircleIcon color={GREY[70]} size="24" />
          </IconButton>
          {hasRole(user, [PROVIDER]) && (
            <IconButton href={`/${medspa}/messages`}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <MessageIcon color={GREY[70]} />
                <MessagesNotificationsDot />
              </Box>
            </IconButton>
          )}
        </>
      )}
      {enableMedspaSwitch && (
        <RadioModal
          buttonLabel="Select Medspa"
          options={switchableMedspas.map((userMedspa) => ({
            ...userMedspa,
            id: userMedspa.medspa.id,
            label: userMedspa.medspa.name,
            image: userMedspa.medspa.logo,
          }))}
          activeOptionId={medspa}
          showLogos
          onOptionChange={async (selectedOption) => {
            const newMedspaID = selectedOption.id;

            if (medspa !== newMedspaID) {
              onMedspaChange(newMedspaID);

              router.push(
                getDefaultRoute(
                  selectedOption as unknown as (typeof switchableMedspas)[number]
                )
              );
            }
          }}
          onClose={() => setAccountSwitchModalOpen(false)}
          open={accountSwitchModalOpen}
          title="Switch Accounts"
        />
      )}
    </Stack>
  );
}

export default TopBarActions;
