import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type JoinConversationMutationVariables = Types.Exact<{
  conversationSid: Types.Scalars["String"]["input"];
  medspaId: Types.Scalars["Int"]["input"];
}>;

export type JoinConversationMutation = {
  __typename?: "mutation_root";
  joinTwilioConversation?: {
    __typename?: "JoinTwilioConversation";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const JoinConversationDocument = gql`
  mutation JoinConversation($conversationSid: String!, $medspaId: Int!) {
    joinTwilioConversation(
      conversationSid: $conversationSid
      medspaId: $medspaId
    ) {
      message
      ok
    }
  }
`;
export type JoinConversationMutationFn = Apollo.MutationFunction<
  JoinConversationMutation,
  JoinConversationMutationVariables
>;

/**
 * __useJoinConversationMutation__
 *
 * To run a mutation, you first call `useJoinConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinConversationMutation, { data, loading, error }] = useJoinConversationMutation({
 *   variables: {
 *      conversationSid: // value for 'conversationSid'
 *      medspaId: // value for 'medspaId'
 *   },
 * });
 */
export function useJoinConversationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinConversationMutation,
    JoinConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinConversationMutation,
    JoinConversationMutationVariables
  >(JoinConversationDocument, options);
}
export type JoinConversationMutationHookResult = ReturnType<
  typeof useJoinConversationMutation
>;
export type JoinConversationMutationResult =
  Apollo.MutationResult<JoinConversationMutation>;
export type JoinConversationMutationOptions = Apollo.BaseMutationOptions<
  JoinConversationMutation,
  JoinConversationMutationVariables
>;
