import Box, { type BoxProps } from "@mui/material/Box";
import React from "react";

const DrawerContent: React.FC<React.PropsWithChildren<Partial<BoxProps>>> = ({
  children,
  ...props
}) => (
  <Box flex={1} overflow="auto" p={(theme) => theme.spacing(2)} {...props}>
    {children}
  </Box>
);

export default DrawerContent;
