// COLOR PALETTE
export const VIOLET = {
  100: "#4F0751",
  90: "#8A1D96",
  80: "#B377C5",
  60: "#DAB3E5",
  40: "#EBD7F5",
  20: "#FBF5FF",
};

export const GREEN = {
  100: "#1A2D2B",
  90: "#204C47",
  80: "#94B7B3",
  60: "#C2D6D3",
  40: "#E1EBEA",
  20: "#F9FBFB",
};

export const BLUE = {
  100: "#0F134E",
  80: "#797FD2",
  60: "#ADB1EB",
  40: "#D5D7F7",
  20: "#ECEDFE",
};

export const SALMON = {
  100: "#4E150F",
  80: "#E47367",
  60: "#F5ABA3",
  40: "#FAD6D2",
  20: "#FFF6F5",
};

export const YELLOW = {
  100: "#675707",
  80: "#D7C155",
  60: "#E9D87F",
  40: "#FFF1AC",
  20: "#FFF9DB",
};

export const MINT = {
  100: "#124937",
  80: "#54CBA3",
  60: "#94E8CB",
  40: "#D1FAEC",
  20: "#F2FFFB",
};

export const GREY = {
  80: "#3A3A41",
  70: "#60606C",
  60: "#888896",
  50: "#AEAEB7",
  40: "#C8C8D0",
  30: "#E3E3E8",
  20: "#EEEEF1",
  10: "#F9F9FA",
};
export const BLACK = "#131316";
export const WHITE = "#FFFFFF";
// Moxie primary color
export const PRIMARY = VIOLET[90];
// Text only colors
export const TEXT_PRIMARY_DARK = BLACK;
export const TEXT_PRIMARY_WHITE = WHITE;
export const TEXT_SECONDARY = GREY[70];
export const TEXT_TERTIARY = GREY[60];
// Functional colors
export const SUCCESS = "#3DB866";
export const ERROR = "#CF1726";
export const WARNING = "#FFAA00";
export const INFO = "#308CE8";

export const SUCCESS_LIGHT = "#D9F2E1";
export const ERROR_LIGHT = "#FCE8E9";
export const WARNING_LIGHT = "#FFEECC";
export const INFO_LIGHT = "#D1E5FA";

// Extra colors - for extra provider users only (if more than 5 providers in calendar)
export const PINK = {
  100: "#A32952",
  80: "#D14775",
  40: "#F5D6E0",
  20: "#FAEBF0",
};

export const PURPLE = {
  100: "#3A0A5D",
  80: "#8659A6",
  40: "#CFBDDB",
  20: "#F3EEF6",
};

export const OCEAN_BLUE = {
  100: "#1F457A",
  80: "#4781D1",
  40: "#ADC7EB",
  20: "#E9F0F9",
};

export const BLOODY = {
  100: "#8A3028",
  80: "#C64439",
  40: "#E8B5B0",
  20: "#F9ECEB",
};

export const CYAN = {
  100: "#1B6E7E",
  80: "#42BED7",
  40: "#ABE2ED",
  20: "#EAF8FB",
};

export const TEAL = {
  100: "#1F4741",
  80: "#3D8F81",
  40: "#B8E0DA",
  20: "#EDF7F6",
};

export const AMBER = {
  100: "#BD570F",
  80: "#EE7C2B",
  40: "#F5B689",
  20: "#FDF0E7",
};

export const GOLD = {
  100: "#B88114",
  80: "#E6A119",
  40: "#F5DAA3",
  20: "#FCF6E8",
};

export const DEEP_ORANGE = {
  100: "#933D1F",
  80: "#D2562C",
  40: "#EDBBAB",
  20: "#FBEEEA",
};

export const BROWN = {
  100: "#3D3029",
  80: "#6B5348",
  40: "#CCBBB2",
  20: "#F5F1F0",
};

export const DARK_PURPLE = {
  100: "#3C2B70",
  80: "#7259C0",
  40: "#C1B5E3",
  20: "#EFEDF8",
};

export const LIGHT_BLUE = {
  100: "#1F367A",
  80: "#476AD1",
  40: "#ADBDEB",
  20: "#E9EDF9",
};

export const INDIGO = {
  100: "#2A2A6D",
  80: "#5959C0",
  40: "#B5B5E3",
  20: "#EDEDF8",
};
