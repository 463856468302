import { useCallback, useState, useEffect, useMemo } from "react";
import PillButton from "@/components/common/buttons/pillButton";
import DateRangePicker from "@/components/common/datetimePicker/dateRangePicker";
import { CalendarIcon } from "@/components/common/icons";
import ToggleIcon from "@/components/common/icons/toggleIcon";
import RadioList, { RadioItem } from "@/components/common/list/radioList";
import FilterDialog from "@/components/common/modals/filterDialog";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import { getTimeRange, findMatchingDatePeriod } from "@/utils/charts";

export enum DatePeriods {
  LAST_3_DAYS = "last_3_days",
  LAST_7_DAYS = "last_7_days",
  LAST_30_DAYS = "last_30_days",
}

const PERIOD_OPTIONS = [
  DatePeriods.LAST_3_DAYS,
  DatePeriods.LAST_7_DAYS,
  DatePeriods.LAST_30_DAYS,
];

const DATE_PERIOD_LABELS = {
  [DatePeriods.LAST_3_DAYS]: "Last 3 days",
  [DatePeriods.LAST_7_DAYS]: "Last 7 days",
  [DatePeriods.LAST_30_DAYS]: "Last 30 days",
};

const getTitle = (selectedPeriod, startDate: Date, endDate: Date) => {
  if (selectedPeriod) {
    return DATE_PERIOD_LABELS[selectedPeriod];
  }
  const start = startDate.toLocaleDateString();
  const end = endDate.toLocaleDateString();
  return `${start} - ${end}`;
};

type Props = {
  startDate: Date;
  endDate: Date;
  setStartAndEndDate: (dateRange: { startDate: Date; endDate: Date }) => void;
};

export default function ChartsDateFilter({
  startDate,
  endDate,
  setStartAndEndDate,
}: Props) {
  const [isDatesFilterOpen, setIsDatesFilterOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriods | null>(
    null
  );
  const [datePeriodRanges, setDatePeriodRanges] = useState({});

  useEffectOnce(true, () => {
    setDatePeriodRanges({
      [DatePeriods.LAST_3_DAYS]: getTimeRange(DatePeriods.LAST_3_DAYS),
      [DatePeriods.LAST_7_DAYS]: getTimeRange(DatePeriods.LAST_7_DAYS),
      [DatePeriods.LAST_30_DAYS]: getTimeRange(DatePeriods.LAST_30_DAYS),
    });
  });

  useEffect(() => {
    const matchingPeriod = findMatchingDatePeriod(
      startDate,
      endDate,
      datePeriodRanges
    );
    setSelectedPeriod(matchingPeriod);
  }, [startDate, endDate, datePeriodRanges]);

  const toggleDatesFilterDialog = () =>
    setIsDatesFilterOpen((isOpen) => !isOpen);

  const handleRadioChange = useCallback(
    (period: DatePeriods) => {
      const { startDate, endDate } = datePeriodRanges[period];
      setStartAndEndDate({ startDate, endDate });
      setSelectedPeriod(period);
    },
    [datePeriodRanges, setStartAndEndDate]
  );

  const onRadioListItemChange = useCallback(
    (period) => handleRadioChange(period as DatePeriods),
    [handleRadioChange]
  );

  const radioListItems: RadioItem[] = useMemo(() => {
    return PERIOD_OPTIONS.map((period) => ({
      id: period,
      label: DATE_PERIOD_LABELS[period],
      onChange: onRadioListItemChange,
      isChecked: period === selectedPeriod,
    }));
  }, [onRadioListItemChange, selectedPeriod]);

  return (
    <>
      <PillButton
        onClick={toggleDatesFilterDialog}
        isActive={selectedPeriod !== DatePeriods.LAST_7_DAYS}
        iconLeft={(props) => <CalendarIcon {...props} />}
        iconRight={(props) => (
          <ToggleIcon {...props} isToggled={isDatesFilterOpen} />
        )}
      >
        {getTitle(selectedPeriod, startDate, endDate)}
      </PillButton>
      <FilterDialog
        isEmpty={selectedPeriod === DatePeriods.LAST_7_DAYS} // TODO: Adjust the logic
        open={isDatesFilterOpen}
        onSubmit={toggleDatesFilterDialog}
        onClose={toggleDatesFilterDialog}
        title="Filter by appt date"
        content={
          <>
            <DateRangePicker
              selectedRange={{ startDate, endDate }}
              onRangeChange={setStartAndEndDate}
            />
            <RadioList items={radioListItems} />
          </>
        }
      />
    </>
  );
}
