import { useStripe } from "@stripe/react-stripe-js";
import { useRouter } from "next/router";
import { useUser } from "@/auth/useUser";
import { useChargeNoShowFeeMutation } from "@/graphql/mutations/chargeNoShowFee.graphql.types";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useChargeNoShowFee(visitId: string) {
  const logError = useErrorLogger();
  const { push } = useRouter();
  const { medspa } = useUser();
  const stripe = useStripe();
  const [chargeNoShowFeeMutation] = useChargeNoShowFeeMutation();

  const chargeFee = async (sendCommunication: boolean) => {
    const { data } = await chargeNoShowFeeMutation({
      variables: {
        visitId,
        sendCommunication,
      },
    });

    const { paymentIntent, payment, invoice, selectedCardId } =
      data.chargeNoShowFee;

    stripe
      .confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: selectedCardId,
      })
      .catch(logError);

    push(`/${medspa}/invoices/${invoice.id}/payments/${payment.id}`);
  };

  return chargeFee;
}
