import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type MedspaTimezoneQueryVariables = Types.Exact<{
  id: Types.Scalars["bigint"]["input"];
}>;

export type MedspaTimezoneQuery = {
  __typename?: "query_root";
  medspaByPk?: { __typename?: "medspa"; id: string; timezone: string } | null;
};

export const MedspaTimezoneDocument = gql`
  query MedspaTimezone($id: bigint!) {
    medspaByPk(id: $id) {
      id
      timezone
    }
  }
`;

/**
 * __useMedspaTimezoneQuery__
 *
 * To run a query within a React component, call `useMedspaTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedspaTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedspaTimezoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedspaTimezoneQuery(
  baseOptions: Apollo.QueryHookOptions<
    MedspaTimezoneQuery,
    MedspaTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MedspaTimezoneQuery, MedspaTimezoneQueryVariables>(
    MedspaTimezoneDocument,
    options
  );
}
export function useMedspaTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MedspaTimezoneQuery,
    MedspaTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MedspaTimezoneQuery, MedspaTimezoneQueryVariables>(
    MedspaTimezoneDocument,
    options
  );
}
export function useMedspaTimezoneSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MedspaTimezoneQuery,
    MedspaTimezoneQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MedspaTimezoneQuery,
    MedspaTimezoneQueryVariables
  >(MedspaTimezoneDocument, options);
}
export type MedspaTimezoneQueryHookResult = ReturnType<
  typeof useMedspaTimezoneQuery
>;
export type MedspaTimezoneLazyQueryHookResult = ReturnType<
  typeof useMedspaTimezoneLazyQuery
>;
export type MedspaTimezoneSuspenseQueryHookResult = ReturnType<
  typeof useMedspaTimezoneSuspenseQuery
>;
export type MedspaTimezoneQueryResult = Apollo.QueryResult<
  MedspaTimezoneQuery,
  MedspaTimezoneQueryVariables
>;
