import toast from "react-hot-toast";
import { DropdownMenuItem } from "@/components/common/dropdownMenu/dropdownMenu";
import { useSendVisitToReviewMutation } from "@/graphql/mutations/sendVisitToReview.graphql.types";
import { PROMISE_MESSAGES } from "@/hooks/serviceFlow/visitDropdown/useSendChartToReviewItem";
import useErrorLogger from "@/utils/useErrorLogger";

export const useSendVisitToMdAction = (visitId: string) => {
  const logError = useErrorLogger();
  const [sendVisitToMd] = useSendVisitToReviewMutation();

  return async () => {
    try {
      await toast.promise(
        sendVisitToMd({
          variables: {
            visitId,
          },
        }),
        PROMISE_MESSAGES
      );
    } catch (e) {
      logError(e);
    }
  };
};

export const useReviewSendChartsToMdItem = (
  visitId: string
): DropdownMenuItem => {
  const handleSend = useSendVisitToMdAction(visitId);

  return {
    component: `Send charts to MD`,
    onClick: handleSend,
  };
};
