import {
  useCreateGfeReviewSubmissionMutation,
  useSetGfeReviewLastStartedMutation,
} from "@/graphql/mutations/gfeReview/gfeReviewSubmissionTimestamps.graphql.types";
import { useLastPendingGfeSubmissionLazyQuery } from "@/graphql/queries/gfeReview/lastPendingGfeSubmission.graphql.types";
import { useUserMedspaId } from "@/hooks/common/useUserMedspa";

export const useGfeReviewTimestamps = (gfeRequestId: string) => {
  const userMedspaId = useUserMedspaId();

  const [setGfeReviewLastStarted] = useSetGfeReviewLastStartedMutation();
  const [createGfeSubmission] = useCreateGfeReviewSubmissionMutation();

  const [getLastPendingSubmission] = useLastPendingGfeSubmissionLazyQuery({
    variables: { gfeRequestId, requestedById: userMedspaId },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const existingSubmission = data.gfeReviewSubmission[0];

      if (existingSubmission) {
        setGfeReviewLastStarted({ variables: { id: existingSubmission.id } });
      } else {
        createGfeSubmission({
          variables: {
            reviewedById: userMedspaId,
            gfeRequestId,
          },
        });
      }
    },
  });

  const startGfeReviewProcess = () => {
    getLastPendingSubmission();
  };

  return {
    startGfeReviewProcess,
  };
};
