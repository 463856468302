import React, { createContext, useContext, useState, ReactNode } from "react";

interface CreateMdMeetingDrawerContextType {
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const CreateMdMeetingDrawerContext = createContext<
  CreateMdMeetingDrawerContextType | undefined
>(undefined);

export const useCreateMdMeetingDrawer = () => {
  const context = useContext(CreateMdMeetingDrawerContext);
  if (!context) {
    throw new Error(
      "useCreateMdMeetingDrawer must be used within a CreateMdMeetingDrawerProvider"
    );
  }
  return context;
};

interface CreateMdMeetingDrawerProviderProps {
  children: ReactNode;
}

export const CreateMdMeetingDrawerProvider: React.FC<
  CreateMdMeetingDrawerProviderProps
> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);

  return (
    <CreateMdMeetingDrawerContext.Provider
      value={{ isOpen, openDrawer, closeDrawer }}
    >
      {children}
    </CreateMdMeetingDrawerContext.Provider>
  );
};
