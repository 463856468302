import { useRef } from "react";
import toast from "react-hot-toast";
import { useUser } from "@/auth/useUser";
import { useJoinConversationMutation } from "@/graphql/mutations/joinConversation.graphql.types";
import { useLeaveConversationMutation } from "@/graphql/mutations/leaveConversation.graphql.types";
import useErrorLogger from "@/utils/useErrorLogger";

export default function useJoinConversation() {
  const logError = useErrorLogger();
  const { medspa } = useUser();
  const joiningConversations = useRef(new Set<string>()).current;
  const markAsJoining = (sid: string) => joiningConversations.add(sid);
  const markAsJoined = (sid: string) => joiningConversations.delete(sid);
  const [joinConversationMutation] = useJoinConversationMutation();
  const [leaveConversationMutation] = useLeaveConversationMutation();

  const joinConversation = async (sid: string) => {
    if (joiningConversations.has(sid)) return;

    try {
      markAsJoining(sid);
      await toast.promise(
        joinConversationMutation({
          variables: {
            conversationSid: sid,
            medspaId: parseInt(medspa),
          },
        }),
        {
          loading: "Loading conversation...",
          success: "Successfully loaded conversation!",
          error: "Failed to load conversation.",
        }
      );
    } catch (error) {
      logError(error);
    } finally {
      markAsJoined(sid);
    }
  };

  const leaveConversation = async (sid: string) => {
    try {
      await toast.promise(
        leaveConversationMutation({
          variables: {
            conversationSid: sid,
            medspaId: parseInt(medspa),
          },
        }),
        {
          loading: "Leaving conversation...",
          success: "You left the conversation!",
          error: "Failed to leave conversation.",
        }
      );
    } catch (error) {
      logError(error);
    }
  };
  return { joinConversation, leaveConversation };
}
