import PullToRefresh from "react-simple-pull-to-refresh";

export const PWAPullToRefresh = ({ children }) => {
  const handleRefresh = async () => {
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  // Only enable this if app is standalone mode (pwa)
  let standalone = false;
  if (typeof window !== "undefined") {
    standalone = window.matchMedia("(display-mode: standalone)").matches;
  }

  return (
    <PullToRefresh
      isPullable={standalone}
      onRefresh={handleRefresh}
      maxPullDownDistance={180}
      pullDownThreshold={179}
      resistance={2.7}
      pullingContent=""
    >
      {children}
    </PullToRefresh>
  );
};
