import { useRouter } from "next/router";

export const useIsOnlineStoreOTPPage = () => {
  const { pathname } = useRouter();
  return (
    pathname.includes("/packages/[medspaSlug]/[tab]/[membershipId]/confirm") ||
    pathname.includes("/packages/[medspaSlug]/sign-in")
  );
};

export default useIsOnlineStoreOTPPage;
