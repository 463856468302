import { differenceInMinutes } from "date-fns";
import { useState } from "react";
import useMedspaTimezone from "@/hooks/common/useMedspaTimezone";

// WARNING
// Please be extra careful if you edit this hook. Using setState outside hook/function with bare if statement
// might cause infinite loop if done incorrectly. This solution was the only one that worked for this specific case hence this usage.
export default function useSyncCalendarTimeWithTimezone() {
  const { getMedspaCurrentTime, medspaReadableTimezone } = useMedspaTimezone();
  const [now, setNow] = useState(getMedspaCurrentTime());
  const [nowSynced, setNowSynced] = useState(false);

  // Once in every ~20 page refreshes, medspaTimezone isn't loaded at the time this component is initialized,
  // causing `getMedspaCurrentTime()` to return incorrect time. To make sure that nowIndicator is correct 100% of the time
  // we need to sync `now` with `getMedspaCurrentTime()` result once they are different
  if (
    !nowSynced &&
    Math.abs(differenceInMinutes(now, getMedspaCurrentTime())) > 1
  ) {
    setNow(getMedspaCurrentTime());
    setNowSynced(true);
  }

  return {
    medspaReadableTimezone,
    now,
  };
}
