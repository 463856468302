import { useRollbar } from "@rollbar/react";
import { useCallback } from "react";
import { LogArgument } from "rollbar";

export default function useErrorLogger() {
  const rollbar = useRollbar();

  return useCallback(
    (...args: LogArgument[]) => {
      rollbar.error(...args);
      console.error(...args);
    },
    [rollbar]
  );
}
