import { hasRole, useUser } from "@/auth/useUser";
import { useOverdueChartsPerProviderSubscription } from "@/graphql/subscriptions/visit/overdueChartsPerProvider.graphql.types";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { PROVIDER } from "@/types";
import { NotificationsDot } from "./notificationsDot";

export const OverdueChartsNotificationsDot = () => {
  const { chartsOverdueV1Enabled, featureFlagsLoading } = useFeatureFlags();
  const { user } = useUser();
  const isProvider = hasRole(user, [PROVIDER]);

  const shouldSkip =
    !isProvider || !chartsOverdueV1Enabled || featureFlagsLoading;

  const { data } = useOverdueChartsPerProviderSubscription({
    variables: {
      id: user?.id,
    },
    skip: shouldSkip,
  });

  if (!data) return null;

  const overdueChartsCount = data.visitAggregate.aggregate.count;

  if (overdueChartsCount === 0) return null;

  return <NotificationsDot count={overdueChartsCount} />;
};
