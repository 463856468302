import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type SendVisitToReviewMutationVariables = Types.Exact<{
  visitId: Types.Scalars["String"]["input"];
}>;

export type SendVisitToReviewMutation = {
  __typename?: "mutation_root";
  sendVisitToReview?: {
    __typename?: "SendVisitToReview";
    ok?: boolean | null;
    message?: string | null;
  } | null;
};

export const SendVisitToReviewDocument = gql`
  mutation SendVisitToReview($visitId: String!) {
    sendVisitToReview(visitId: $visitId) {
      ok
      message
    }
  }
`;
export type SendVisitToReviewMutationFn = Apollo.MutationFunction<
  SendVisitToReviewMutation,
  SendVisitToReviewMutationVariables
>;

/**
 * __useSendVisitToReviewMutation__
 *
 * To run a mutation, you first call `useSendVisitToReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVisitToReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVisitToReviewMutation, { data, loading, error }] = useSendVisitToReviewMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *   },
 * });
 */
export function useSendVisitToReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendVisitToReviewMutation,
    SendVisitToReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendVisitToReviewMutation,
    SendVisitToReviewMutationVariables
  >(SendVisitToReviewDocument, options);
}
export type SendVisitToReviewMutationHookResult = ReturnType<
  typeof useSendVisitToReviewMutation
>;
export type SendVisitToReviewMutationResult =
  Apollo.MutationResult<SendVisitToReviewMutation>;
export type SendVisitToReviewMutationOptions = Apollo.BaseMutationOptions<
  SendVisitToReviewMutation,
  SendVisitToReviewMutationVariables
>;
