import { useAuth } from "@clerk/nextjs";
import toast from "react-hot-toast";
import { useSendChartToReviewMutation } from "@/graphql/mutations/visit/sendChartToReview.graphql.types";
import { useVisitChartBaseDataLazyQuery } from "@/graphql/queries/visit/visitChartSignatureDetails.graphql.types";
import useAction from "@/hooks/misc/useAction";
import { ReviewStatus } from "@/types";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import {
  getPresignedSignatureUploadUrl,
  uploadFileToPresignedUrl,
} from "@/utils/photos";
import useErrorLogger from "@/utils/useErrorLogger";

export const useSignAndSendChartToReview = (
  visitId: string,
  onClose: () => void
) => {
  const logError = useErrorLogger();
  const { getToken } = useAuth();

  const [sendChartToReview] = useSendChartToReviewMutation();

  const [refetchBaseData] = useVisitChartBaseDataLazyQuery({
    variables: {
      visitId,
    },
    fetchPolicy: "cache-and-network",
  });

  const uploadSignature = async (file: File) => {
    const { upload_url, blob_name } = await getPresignedSignatureUploadUrl(
      file.name,
      "chart_signatures",
      getToken
    );
    await uploadFileToPresignedUrl(upload_url, file);
    return blob_name;
  };

  const { handleAction: sendChartToMd, isLoading } = useAction(
    async (signature: File) => {
      const toastId = toast.loading("Sending charts to MD...");

      try {
        const signatureBlob = await uploadSignature(signature);

        await sendChartToReview({
          variables: {
            visitId,
            signature: signatureBlob,
          },
          update(cache) {
            cache.modify({
              id: `visit:${visitId}`,
              fields: {
                reviewStatus() {
                  return ReviewStatus.COMPLETE;
                },
                reviewStatusChangeLogs() {
                  return [
                    {
                      id: "temp-id",
                      created: new Date().toISOString(),
                    },
                  ];
                },
              },
            });
          },
        });
        await refetchBaseData();

        toast.success("Charts were sent to MD!", {
          id: toastId,
        });
        onClose();
      } catch (error) {
        logError(error);
        toast.error(
          hasDjangoMutationError(error)
            ? error.message
            : "Couldn't sent charts to MD, please try again later.",
          { id: toastId }
        );
      }
    }
  );

  return { sendChartToMd, isLoading };
};
