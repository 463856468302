import { List, ListProps } from "@mui/material";
import { ReactNode } from "react";

type GenericListProps = ListProps & {
  items: ReactNode;
};

function GenericList({ items, ...listProps }: GenericListProps) {
  return (
    <List component="div" {...listProps}>
      {items}
    </List>
  );
}

export default GenericList;
