import { Box, SxProps, Theme } from "@mui/material";
import { isEmpty } from "lodash";
import { ReactNode } from "react";
import GenericList from "@/components/common/list/genericList";
import ListItemControl, {
  ListItemCheckboxButton,
} from "@/components/common/list/listItemControl";

export type CheckboxItem<T extends string = string> = {
  id: T;
  label: ReactNode;
  secondaryLabel?: ReactNode;
  secondaryAction?: ReactNode;
  onChange: (id: T, value: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
};

type CheckboxListProps<T extends string> = {
  items: CheckboxItem<T>[];
  emptyListText?: string;
  withDivider?: boolean;
  sx?: SxProps<Theme>;
};

function CheckboxList<T extends string>({
  items,
  emptyListText = "",
  withDivider = true,
  sx,
}: CheckboxListProps<T>) {
  if (isEmpty(items)) return <Box textAlign="center">{emptyListText}</Box>;

  return (
    <GenericList
      items={items.map(
        ({
          id,
          label,
          secondaryLabel,
          secondaryAction,
          isChecked,
          isDisabled,
          onChange,
        }) => {
          const labelId = `item-checkbox-${id}`;

          return (
            <ListItemControl
              key={id}
              withDivider={withDivider}
              secondaryAction={secondaryAction}
              control={
                <ListItemCheckboxButton
                  label={label}
                  labelId={labelId}
                  secondaryLabel={secondaryLabel}
                  onClick={() => onChange(id, !isChecked)}
                  checked={isChecked}
                  disabled={isDisabled}
                />
              }
            />
          );
        }
      )}
      sx={{ p: 0, ...sx }}
    />
  );
}

export default CheckboxList;
