import {
  CalendarOptions,
  EventInput,
  EventSourceInput,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useMemo,
  useRef,
} from "react";
import { CustomEventTile } from "@/components/serviceFlow/events/customEventTile";
import useCalendarInitialScroll from "@/hooks/calendar/useCalendarInitialScroll";
import useCalendarNowIndicator from "@/hooks/calendar/useCalendarNowIndicator";
import useCalendarNavigation from "@/hooks/calendar/useCalendarSwipe";
import { byDateKeyAsc, getCalendarHeight } from "@/utils";
import CalendarHeader, { type CalendarHeaderProps } from "./calendarDateHeader";

type Props = {
  handleCalendarGoToDate: (date: Date) => void;
  calendarRef: MutableRefObject<FullCalendar | null>;
  initDate: Date;
  saveCurrentDate?: (date: Date) => void;
  updateHeaderLabel?: boolean;
  setUpdateHeaderLabel?: Dispatch<SetStateAction<boolean>>;
  headerProps?: Partial<CalendarHeaderProps>;
} & Omit<CalendarOptions, "initialDate">;

export default function MoxieFullCalendar({
  handleCalendarGoToDate,
  calendarRef,
  height,
  initDate,
  events,
  saveCurrentDate,
  updateHeaderLabel,
  setUpdateHeaderLabel,
  headerProps,
  ...calendarProps
}: Props) {
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const viewType =
    calendarProps.initialView || calendarRef.current?.getApi().view.type;
  const isDayView =
    viewType === "resourceTimeGridDay" || viewType === "timeGridDay";

  const { goToNextDay, goToPrevDay, swipeHandlers } = useCalendarNavigation(
    handleCalendarGoToDate,
    isDayView,
    calendarRef
  );
  useCalendarInitialScroll(calendarRef);
  const { medspaReadableTimezone, now } =
    useCalendarNowIndicator(calendarWrapperRef);

  const sortedEvents = useMemo(() => {
    if (!areEventsSortableByStartDate(events)) return events;

    return [...events].sort(byDateKeyAsc("start"));
  }, [events]);

  return (
    <>
      <CalendarHeader
        onNext={goToNextDay}
        onPrev={goToPrevDay}
        onDateChange={handleCalendarGoToDate}
        currentDate={initDate}
        saveCurrentDate={saveCurrentDate}
        calendarRef={calendarRef}
        updateHeaderLabel={updateHeaderLabel}
        setUpdateHeaderLabel={setUpdateHeaderLabel}
        isDayView={isDayView}
        {...headerProps}
      />
      <div
        {...swipeHandlers}
        ref={calendarWrapperRef}
        className="calendar-container"
      >
        <FullCalendar
          key={medspaReadableTimezone} // force re-render when timezone changes so nowIndicator is in correct state
          ref={calendarRef}
          allDaySlot={false}
          slotDuration="00:15:00"
          slotLabelInterval="01:00"
          expandRows
          nowIndicator
          now={now}
          dayHeaders={false}
          headerToolbar={false}
          height={height || getCalendarHeight()}
          initialView="timeGridDay"
          scrollTimeReset={false}
          eventContent={CustomEventTile}
          initialDate={initDate}
          events={sortedEvents}
          {...calendarProps}
        />
      </div>
    </>
  );
}

const areEventsSortableByStartDate = (
  events: EventSourceInput
): events is (EventInput & { start: string })[] =>
  Array.isArray(events) &&
  events.length > 0 &&
  typeof events[0].start === "string";
